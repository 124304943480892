/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
//import { useParams } from "react-router";
/* import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  IonSelect,
  IonSelectOption,
  IonSlides,
  IonSlide,
  IonThumbnail,
  IonIcon,
} from "@ionic/react"; */

/* Theme variables */
import "./FooterMain.css";
/* Others */
import { history } from "../helpers/history";
//import { HashLink } from "react-router-hash-link";
//import { Plugins, Capacitor } from "@capacitor/core";
//import { AppAvailability } from "@ionic-native/app-availability";
/* import {
  InAppBrowser,
  InAppBrowserObject,
  InAppBrowserOptions,
} from "@ionic-native/in-app-browser"; */

const FooterMain: React.FC = () => {
  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */
  /* const [countcheck, setCountcheck] = useState(0); */

  //var BrowserApp: InAppBrowserObject;

  const [hover1, setHover1] = useState<boolean>(false);
  const [hover2, setHover2] = useState<boolean>(false);
  const [hover3, setHover3] = useState<boolean>(false);
  const [hover4, setHover4] = useState<boolean>(false);
  const [hover5, setHover5] = useState<boolean>(false);
  const [aactive1, setAactive1] = useState<boolean>(false);
  const [aactive2, setAactive2] = useState<boolean>(false);
  const [aactive3, setAactive3] = useState<boolean>(false);
  const [aactive4, setAactive4] = useState<boolean>(false);
  const [aactive5, setAactive5] = useState<boolean>(false);

  const [hoveremail, setHoveremail] = useState<boolean>(false);
  const [hovertel, setHovertel] = useState<boolean>(false);

  /*   useEffect(() => {
    if (history.location.pathname === "/home") {
      localStorage.setItem("mselected", "1");
      localStorage.setItem("msubselected", "0");
    } else if (history.location.pathname === "/team") {
      localStorage.setItem("mselected", "2");
      localStorage.setItem("msubselected", "1");
    } else if (history.location.pathname === "/gallery/arch") {
      localStorage.setItem("mselected", "3");
      localStorage.setItem("msubselected", "4");
    } else if (history.location.pathname === "/healthcareprofessionalresources") {
      localStorage.setItem("mselected", "4");
      localStorage.setItem("msubselected", "0");
    } else if (history.location.pathname === "/valuedcareproviders") {
      localStorage.setItem("mselected", "5");
      localStorage.setItem("msubselected", "0");
    }
  }, [history.location.pathname]); */

  useEffect(() => {
    const platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);

    const isnative: any = localStorage.getItem("isnative")
      ? localStorage.getItem("isnative")
      : "";

    setIsnativeusedglobal(isnative);

    window.addEventListener("message", (event) => {
      if (event.data && event.data.sender === "socmed") {
        localStorage.setItem("socmed", event.data.message);
        window.location.reload();
      } else if (event.data && event.data.sender === "links") {
        localStorage.setItem("links", event.data.message);
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    const currentMenu = localStorage.getItem("mselected");

    if (currentMenu === "1") {
      setAactive1(true);
      setAactive2(false);
      setAactive3(false);
      setAactive4(false);
      setAactive5(false);
    } else if (currentMenu === "2") {
      setAactive1(false);
      setAactive2(true);
      setAactive3(false);
      setAactive4(false);
      setAactive5(false);
    } else if (currentMenu === "3") {
      setAactive1(false);
      setAactive2(false);
      setAactive3(true);
      setAactive4(false);
      setAactive5(false);
    } else if (currentMenu === "4") {
      setAactive1(false);
      setAactive2(false);
      setAactive3(false);
      setAactive4(true);
      setAactive5(false);
    } else if (currentMenu === "5") {
      setAactive1(false);
      setAactive2(false);
      setAactive3(false);
      setAactive4(false);
      setAactive5(true);
    } else {
      setAactive1(false);
      setAactive2(false);
      setAactive3(false);
      setAactive4(false);
      setAactive5(false);
    }
  }, [localStorage.getItem("mselected")]);

  function togglehover1() {
    setHover1(!hover1);
  }
  function togglehover2() {
    setHover2(!hover2);
  }
  function togglehover3() {
    setHover3(!hover3);
  }
  function togglehover4() {
    setHover4(!hover4);
  }
  function togglehover5() {
    setHover5(!hover5);
  }
  function togglehoveremail() {
    setHoveremail(!hoveremail);
  }
  function togglehovertel() {
    setHovertel(!hovertel);
  }

  function toggleactive1() {
    localStorage.setItem("mselected", "1");
    localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "1");
    setAactive1(true);
    setAactive2(false);
    setAactive3(false);
    setAactive4(false);
    setAactive5(false);
    history.push("/home");
  }
  function toggleactive2() {
    localStorage.setItem("mselected", "2");
    localStorage.setItem("msubselected", "1");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(true);
    setAactive3(false);
    setAactive4(false);
    setAactive5(false);
    history.push("/team");
  }
  function toggleactive3() {
    localStorage.setItem("mselected", "3");
    localStorage.setItem("msubselected", "4");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(false);
    setAactive3(true);
    setAactive4(false);
    setAactive5(false);
    history.push("/gallery/arch");
  }
  function toggleactive4() {
    localStorage.setItem("mselected", "4");
    localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(false);
    setAactive3(false);
    setAactive4(true);
    setAactive5(false);
    history.push("/healthcareprofessionalresources");
  }
  function toggleactive5() {
    localStorage.setItem("mselected", "5");
    localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(false);
    setAactive3(false);
    setAactive4(false);
    setAactive5(true);
    history.push("/valuedcareproviders");
  }

  async function launchEmail() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "mailto:memberservices@seniorhubsolutions.com",
        },
        "*"
      );
    }
    /* else {
      window.open("mailto:memberservices@seniorhubsolutions.com", "_system");
    } */
  }

  async function launchTel() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "tel:+1 (808) 999-9830",
        },
        "*"
      );
    }
    /* else {
      window.open("tel:+1 (808) 999-9830", "_system");
    } */
  }

  async function launchAppFB() {
    //window.open("fb://page/104958162837", "_system");
    //window.open("fb://page/1747847172155314", "_system");
    //window.open("fb://profile/seniorHUBsolutions", "_system");
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "socmed",
          message: "1",
        },
        "*"
      );
    } else {
      window.open("https://www.facebook.com/seniorHUBsolutions", "_system");
    }
  }

  async function launchAppIG() {
    //window.open("instagram://user?username=seniorhubsolutions", "_system");
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "socmed",
          message: "2",
        },
        "*"
      );
    } else {
      window.open("https://www.instagram.com/seniorhubsolutions", "_system");
    }
  }

  async function launchAppTwitter() {
    //window.open("twitter://user?screen_name=seniorhub_HI", "_system");
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "socmed",
          message: "3",
        },
        "*"
      );
    } else {
      window.open("https://www.twitter.com/seniorhub_HI", "_system");
    }
  }

  async function launchAppLinkedin() {
    //window.open("linkedin://profile/seniorhubsolutions", "_system");
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "socmed",
          message: "4",
        },
        "*"
      );
      /*  window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.linkedin.com/company/seniorhubsolutions/",
        },
        "*"
      ); */
    } else {
      window.open(
        "https://www.linkedin.com/company/seniorhubsolutions",
        "_system"
      );
    }
  }

  async function launchAppPinterest() {
    //window.open("pinterest://user?screen_name=seniorhubsolutions", "_system");
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "socmed",
          message: "5",
        },
        "*"
      );
    } else {
      window.open("https://www.pinterest.com/seniorhubsolutions", "_system");
    }
  }

  let pagename = "seniorHUBsolutions Footer";

  return (
    <div
      id="bottom"
      className="border-top"
      style={{
        backgroundColor: "#008080", //#ffe685
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol id="facebook" viewBox="0 0 16 16">
          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
        </symbol>
        <symbol id="instagram" viewBox="0 0 16 16">
          <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
        </symbol>
        <symbol id="twitter" viewBox="0 0 16 16">
          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
        </symbol>
        <symbol id="linkedin" viewBox="0 0 16 16">
          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
        </symbol>
        <symbol id="pinterest" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z" />
        </symbol>
      </svg>

      <div style={{ height: "24px" }}></div>

      <div
        /* id="footerLayout" */
        className="row container-fluid" //py-3 my-4  justify-content-lg-center align-items-center text-center
        style={{
          padding: "0px",
          margin: "0px",
          backgroundColor: "#008080", //#ffe685
        }}
      >
        <div
          className="col-md-5 order-md-1"
          style={{
            padding: "0px",
            margin: "0px",
          }}
        >
          <div className="container-fluid">
            <div style={{ textAlign: "left" }}>
              <p
                style={{
                  textAlign: "left",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <img
                  src={require("../assets/logofooter.jpg")}
                  style={{
                    border: "2px solid #008080",
                    height: "60px",
                    width: "60px",
                    padding: "0px",
                    margin: "0px",
                    borderRadius: "10px",
                  }}
                ></img>
              </p>
            </div>
            <p
              style={{
                textAlign: "left",
                fontSize: "26px",
                fontFamily: "Gill Sans Nova",
                color: "white", //#ffcc00 //#8A1F61
                padding: "0px",
                margin: "0px",
                textShadow: "-1px 1px 2px #000,",
              }}
            >
              senior
              <b
                style={{
                  fontSize: "1.1em",
                  fontFamily: "Gill Sans Nova",
                  color: "white", //#8A1F61
                  textShadow: "-1px 1px 2px #000,",
                }}
              >
                HUB
              </b>
              solutions
            </p>
            <p
              style={{
                textAlign: "left",
                fontSize: "20px",
                fontFamily: "Abadi MT Std",
                color: "white",
                padding: "0px",
                margin: "0px",
              }}
            >
              Honolulu, Hawaii
            </p>
            <div style={{ height: "12px" }}></div>

            {(!isnativeusedglobal || isnativeusedglobal === "false") && (
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    fontFamily: "Abadi MT Std",
                    color: "white",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  Email:
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    fontFamily: "Abadi MT Std",
                    color: "white",
                    padding: "0px",
                    margin: "0px",
                    cursor: "pointer",
                    textDecorationLine: "none",
                  }}
                >
                  {!platformusedglobal || platformusedglobal === "" ? (
                    <a
                      href="mailto:memberservices@seniorhubsolutions.com"
                      style={
                        hoveremail
                          ? {
                              textAlign: "left",
                              fontSize: "20px",
                              fontFamily: "Abadi MT Std",
                              color: "white",
                              padding: "0px",
                              margin: "0px",
                              cursor: "pointer",
                              textDecorationLine: "underline",
                            }
                          : {
                              textAlign: "left",
                              fontSize: "20px",
                              fontFamily: "Abadi MT Std",
                              color: "white",
                              padding: "0px",
                              margin: "0px",
                              cursor: "pointer",
                              textDecorationLine: "none",
                            }
                      }
                      onMouseEnter={togglehoveremail}
                      onMouseLeave={togglehoveremail}
                      /* onClick={launchEmail} */
                    >
                      memberservices@seniorhubsolutions.com
                    </a>
                  ) : (
                    <a
                      style={
                        hoveremail
                          ? {
                              textAlign: "left",
                              fontSize: "20px",
                              fontFamily: "Abadi MT Std",
                              color: "white",
                              padding: "0px",
                              margin: "0px",
                              cursor: "pointer",
                              textDecorationLine: "underline",
                            }
                          : {
                              textAlign: "left",
                              fontSize: "20px",
                              fontFamily: "Abadi MT Std",
                              color: "white",
                              padding: "0px",
                              margin: "0px",
                              cursor: "pointer",
                              textDecorationLine: "none",
                            }
                      }
                      onMouseEnter={togglehoveremail}
                      onMouseLeave={togglehoveremail}
                      onClick={launchEmail}
                    >
                      memberservices@seniorhubsolutions.com
                    </a>
                  )}
                </p>
                <div style={{ height: "12px" }}></div>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    fontFamily: "Abadi MT Std",
                    color: "white",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  Phone:
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    fontFamily: "Abadi MT Std",
                    color: "white",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  {!platformusedglobal || platformusedglobal === "" ? (
                    <a
                      href="tel:+1 (808) 999-9830"
                      style={
                        hovertel
                          ? {
                              textAlign: "left",
                              fontSize: "20px",
                              fontFamily: "Abadi MT Std",
                              color: "white",
                              padding: "0px",
                              margin: "0px",
                              cursor: "pointer",
                              textDecorationLine: "underline",
                            }
                          : {
                              textAlign: "left",
                              fontSize: "20px",
                              fontFamily: "Abadi MT Std",
                              color: "white",
                              padding: "0px",
                              margin: "0px",
                              cursor: "pointer",
                              textDecorationLine: "none",
                            }
                      }
                      onMouseEnter={togglehovertel}
                      onMouseLeave={togglehovertel}
                      /* onClick={launchTel} */
                    >
                      +1 (808) 999-9830
                    </a>
                  ) : (
                    <a
                      style={
                        hovertel
                          ? {
                              textAlign: "left",
                              fontSize: "20px",
                              fontFamily: "Abadi MT Std",
                              color: "white",
                              padding: "0px",
                              margin: "0px",
                              cursor: "pointer",
                              textDecorationLine: "underline",
                            }
                          : {
                              textAlign: "left",
                              fontSize: "20px",
                              fontFamily: "Abadi MT Std",
                              color: "white",
                              padding: "0px",
                              margin: "0px",
                              cursor: "pointer",
                              textDecorationLine: "none",
                            }
                      }
                      onMouseEnter={togglehovertel}
                      onMouseLeave={togglehovertel}
                      onClick={launchTel}
                    >
                      +1 (808) 999-9830
                    </a>
                  )}
                </p>
              </div>
            )}
          </div>
        </div>

        <div style={{ height: "12px" }}></div>
        <div
          id="footerline"
          style={{
            height: "0px",
            border: "1px solid white",
          }}
        ></div>

        {(!isnativeusedglobal || isnativeusedglobal === "false") && (
          <div
            className="col-md-7 order-md-2"
            style={{
              /* border: "2px solid #ffcc00", */
              padding: "0px",
              margin: "0px",
            }}
          >
            <div style={{ height: "12px" }}></div>
            <div
              className="container"
              style={{
                padding: "0px",
              }}
            >
              <p
                style={{
                  textAlign: "right",
                  fontSize: "26px",
                  fontFamily: "AvantGarde Bk BT",
                  color: "white", //#ffcc00 //#8A1F61
                  padding: "0px",
                  paddingRight: "24px",
                  margin: "0px",
                  textShadow: "-1px 1px 2px #000,",
                }}
              >
                {platformusedglobal && platformusedglobal !== ""
                  ? "EXPLORE THIS APP:"
                  : "EXPLORE THIS SITE:"}
              </p>

              <div
                className="container"
                style={{
                  padding: "0px",
                  paddingRight: "24px",
                }}
              >
                <ul
                  className="nav justify-content-end" //pb-3 mb-3
                  style={{ padding: "0px", margin: "0px" }}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link px-2 "
                      style={
                        hover1
                          ? {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive1 ? "underline" : "none"
                              }`,
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive1 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover1}
                      onMouseLeave={togglehover1}
                      onClick={toggleactive1}
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link px-2 " style={{ color: "white" }}>
                      &middot;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link px-2 "
                      style={
                        hover2
                          ? {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive2 ? "underline" : "none"
                              }`,
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive2 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover2}
                      onMouseLeave={togglehover2}
                      onClick={toggleactive2}
                    >
                      Meet Your Visionary Team
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link px-2 " style={{ color: "white" }}>
                      &middot;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link px-2 "
                      style={
                        hover3
                          ? {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive3 ? "underline" : "none"
                              }`,
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive3 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover3}
                      onMouseLeave={togglehover3}
                      onClick={toggleactive3}
                    >
                      Gallery of Homes
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link px-2 " style={{ color: "white" }}>
                      &middot;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link px-2 "
                      style={
                        hover4
                          ? {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive4 ? "underline" : "none"
                              }`,
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive4 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover4}
                      onMouseLeave={togglehover4}
                      onClick={toggleactive4}
                    >
                      Health Care Professional Resources
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link px-2 " style={{ color: "white" }}>
                      &middot;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link px-2 "
                      style={
                        hover5
                          ? {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive5 ? "underline" : "none"
                              }`,
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              cursor: "pointer",
                              color: "white",
                              textDecorationLine: `${
                                aactive5 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover5}
                      onMouseLeave={togglehover5}
                      onClick={toggleactive5}
                    >
                      Valued Care Providers
                    </a>
                  </li>
                </ul>
              </div>
              {(!isnativeusedglobal || isnativeusedglobal === "false") && (
                <div style={{ height: "24px" }}></div>
              )}
              {(!isnativeusedglobal || isnativeusedglobal === "false") && (
                <div
                  className="container"
                  style={{
                    padding: "0px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "right",
                      fontSize: "26px",
                      fontFamily: "AvantGarde Bk BT",
                      color: "white", //#ffcc00 //#8A1F61
                      padding: "0px",
                      paddingRight: "24px",
                      margin: "0px",
                      textShadow: "-1px 1px 2px #000,",
                    }}
                  >
                    Social Media:
                  </p>
                  <div
                    className="container" //align-items-right text-end
                    style={{
                      padding: "0px",
                      paddingRight: "24px",
                    }}
                  >
                    <ul className="nav justify-content-end">
                      <li className="ms-3">
                        <a
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={launchAppFB}
                        >
                          <svg className="bi" width="24" height="24">
                            <use xlinkHref="#facebook" />
                          </svg>
                        </a>
                      </li>
                      <li className="ms-3">
                        <a
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={launchAppIG}
                        >
                          <svg className="bi" width="24" height="24">
                            <use xlinkHref="#instagram" />
                          </svg>
                        </a>
                      </li>
                      <li className="ms-3">
                        <a
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={launchAppTwitter}
                        >
                          <svg className="bi" width="24" height="24">
                            <use xlinkHref="#twitter" />
                          </svg>
                        </a>
                      </li>
                      <li className="ms-3">
                        <a
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={launchAppLinkedin}
                        >
                          <svg className="bi" width="24" height="24">
                            <use xlinkHref="#linkedin" />
                          </svg>
                        </a>
                      </li>
                      <li className="ms-3">
                        <a
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={launchAppPinterest}
                        >
                          <svg className="bi" width="24" height="24">
                            <use xlinkHref="#pinterest" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {(!isnativeusedglobal || isnativeusedglobal === "false") && (
        <div style={{ height: "12px" }}></div>
      )}

      <div className="container">
        <div
          className="container-fluid justify-content-lg-center align-items-center"
          style={{
            padding: "0px",
          }}
        >
          <p
            className="text-center"
            style={{
              color: "white",
              fontSize: "large",
              fontFamily: "Abadi MT Std",
              margin: "0px",
              padding: "0px",
            }}
          >
            Locally owned and operated with&nbsp;
            <a
              className="text-center"
              style={{
                color: "white",
                fontSize: "xx-large",
                fontFamily: "Angelinas",
                margin: "0px",
                padding: "0px",
                textDecorationLine: "none",
                fontStyle: "italic",
              }}
            >
              Aloha!
            </a>
          </p>
        </div>
        <div style={{ height: "12px" }}></div>
      </div>
    </div>
  );
};

export default FooterMain;
