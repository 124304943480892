/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */

/* function isLocalNetwork(hostname: string) {
  return (
    ["localhost", "127.0.0.1", "", "::1"].includes(hostname) ||
    hostname.startsWith("192.168.") ||
    hostname.startsWith("10.0.") ||
    hostname.endsWith(".local")
  );
} */

/* const API_URL1 =
  "https://us-central1-carehomeservicesproj.cloudfunctions.net/application/api/";
const API_URL =
  "http://localhost:5001/carehomeservicesproj/us-central1/application/api/"; */

function isLocalNetwork(hostname: string) {
  if (
    ["localhost", "127.0.0.1", "", "::1"].includes(hostname) ||
    hostname.startsWith("192.168.") ||
    hostname.startsWith("10.0.") ||
    hostname.endsWith(".local")
  ) {
    return "http://localhost:5001/carehomeservicesproj/us-central1";
  } else {
    return "https://us-central1-carehomeservicesproj.cloudfunctions.net";
  }
}

//console.log(isLocalNetwork(window.location.hostname));
const API_URL = isLocalNetwork(window.location.hostname) + "/application/api/";

export default { API_URL };
