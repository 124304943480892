/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import { IonContent, IonModal } from "@ionic/react";

/* Core CSS required for Bootstrap components to work properly */
import "bootstrap/dist/css/bootstrap.min.css";
/* Components */
import NavBar from "../components/NavBar";
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./Home.css";
/* Others */
import { history } from "../helpers/history";
import { HashLink } from "react-router-hash-link";
//import { url } from "inspector";

const Home: React.FC = () => {
  //const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [hover11, setHover11] = useState<boolean>(false);
  const [hover12, setHover12] = useState<boolean>(false);
  const [hover13, setHover13] = useState<boolean>(false);
  const [hover21, setHover21] = useState<boolean>(false);
  const [hover22, setHover22] = useState<boolean>(false);
  const [hover23, setHover23] = useState<boolean>(false);
  const [hover24, setHover24] = useState<boolean>(false);
  const [hover25, setHover25] = useState<boolean>(false);
  const [hover26, setHover26] = useState<boolean>(false);

  useEffect(() => {}, []);

  function togglehover11() {
    setHover11(!hover11);
  }
  function togglehover12() {
    setHover12(!hover12);
  }
  function togglehover13() {
    setHover13(!hover13);
  }
  function togglehover21() {
    setHover21(!hover21);
  }
  function togglehover22() {
    setHover22(!hover22);
  }
  function togglehover23() {
    setHover23(!hover23);
  }
  function togglehover24() {
    setHover24(!hover24);
  }
  function togglehover25() {
    setHover25(!hover25);
  }
  function togglehover26() {
    setHover26(!hover26);
  }

  function toggleactive11() {
    //history.push("/");
  }
  function toggleactive12() {
    /* history.push("/valuedcareproviders"); */
  }
  function toggleactive13() {
    /* history.push("/team"); */
  }
  function toggleactive21() {
    history.push("/gallery/arch");
  }
  function toggleactive22() {
    history.push("/gallery/earch");
  }
  function toggleactive23() {
    history.push("/gallery/afch");
  }
  function toggleactive24() {
    history.push("/gallery/arch");
    /* history.push("/gallery/other"); */
    /* history.push("/gallery/rc"); */
  }
  function toggleactive25() {
    /* history.push("/gallery/hc"); */
  }
  function toggleactive26() {
    /* history.push("/gallery/mc"); */
  }

  let pagename = "Welcome to seniorHUBsolutions";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <NavBar />

      <div style={{ height: "14vh" }}></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        <div
          id="myCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
          data-interval="12000"
          style={{
            height: "70vh",
            margin: "0px",
          }}
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner" data-interval="12000">
            <div
              className="carousel-item active"
              data-interval="12000"
              style={{ height: "70vh" }}
            >
              <img
                id="slideshow1"
                /* src={require("../assets/SlideShow1.jpg")} */
                style={{
                  height: "100%",
                  width: "100%",
                }}
              ></img>
              <div className="container-fluid justify-content-lg-center align-items-center">
                <div
                  id="slideshowpositionaloha"
                  className="carousel-caption justify-content-lg-center align-items-center"
                  style={{ padding: "0px" }}
                >
                  <div
                    className="container-fluid justify-content-lg-center align-items-center"
                    style={{ padding: "0px" }}
                  >
                    <p
                      id="slideshow1alohalabel"
                      className="col-md-3 order-md-1"
                      style={{
                        float: "left",
                        textAlign: "justify",
                        fontFamily: "Angelinas",
                        color: "white", //#ffcc00 //#8A1F61
                        padding: "0px",
                        margin: "0px",
                        fontStyle: "italic",
                        fontWeight: "bold",
                        textShadow:
                          "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000",
                      }}
                    >
                      Aloha!
                    </p>
                    <p
                      id="slideshow1mainlabel"
                      className="col-md-9 order-md-2"
                      style={{
                        textAlign: "center",
                        fontFamily: "Gill Sans Nova",
                        color: "white", //#ffcc00 //#8A1F61
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        /* paddingTop: "80px", */
                        margin: "0px",
                        width: "100%",
                        textShadow:
                          "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000",
                      }}
                    >
                      senior
                      <b
                        style={{
                          textAlign: "justify",
                          fontSize: "1.1em",
                          fontFamily: "Gill Sans Nova",
                          color: "white", //#8A1F61
                          textShadow:
                            "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000",
                          fontWeight: "bold",
                        }}
                      >
                        HUB
                      </b>
                      solutions
                    </p>
                  </div>

                  <div style={{ height: "0.5vh" }}></div>
                  <div
                    className="container-fluid justify-content-lg-center align-items-center"
                    style={{
                      textAlign: "justify",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    <p
                      id="slideshow1sublabel"
                      className="col-md-12 order-md-1"
                      style={{
                        float: "none",
                        textAlign: "center",
                        fontFamily: "Abadi MT Std",
                        color: "white",
                        padding: "0px",
                        margin: "0px",
                        width: "100%",
                        textShadow:
                          /* "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000", */
                          "-1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000",
                      }}
                    >
                      Providing an innovative online website to a network of
                      specialized health care professional providers and
                      fundamental information
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="carousel-item"
              data-interval="12000"
              style={{ height: "70vh" }}
            >
              <img
                id="slideshow2"
                /* src={require("../assets/SlideShow2.jpg")} */
                style={{
                  height: "100%",
                  width: "100%",
                }}
              ></img>
              <div className="container">
                <div
                  id="slideshowposition"
                  className="carousel-caption justify-content-lg-center align-items-center"
                  style={{ padding: "0px" }}
                >
                  <div
                    className="container-fluid justify-content-lg-center align-items-center"
                    style={{ padding: "0px" }}
                  >
                    <p
                      id="slideshow2mainlabel"
                      className="col-md-12 order-md-1"
                      style={{
                        textAlign: "center",
                        fontFamily: "AvantGarde Bk BT",
                        color: "white", //#8A1F61
                        padding: "0px",
                        margin: "0px",
                        width: "100%",
                        textShadow:
                          "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000",
                      }}
                    >
                      Valued Care Providers
                    </p>
                  </div>
                  <div
                    className="container-fluid justify-content-lg-center align-items-center"
                    style={{
                      textAlign: "justify",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    <p
                      id="slideshow2sublabel"
                      className="col-md-12 order-md-1"
                      style={{
                        float: "none",
                        textAlign: "center",
                        fontFamily: "Abadi MT Std",
                        color: "white",
                        padding: "0px",
                        margin: "0px",
                        width: "100%",
                        textShadow:
                          /* "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000", */
                          "-1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000",
                      }}
                    >
                      Fosters safe personal care to your loved ones.
                    </p>
                  </div>
                  <div style={{ height: "24px" }}></div>
                  <div
                    className="container-fluid justify-content-lg-center align-items-center"
                    style={{ padding: "0px" }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <HashLink
                        smooth
                        to="/home#network"
                        className="btn btn-lg btn-secondary col-md-12 order-md-1"
                        style={
                          hover12
                            ? {
                                textAlign: "center",
                                fontSize: "large",
                                cursor: "pointer",
                                color: "#8f6b00",
                                width: "100%",
                                maxHeight: "56px",
                                maxWidth: "212px",
                                minWidth: "212px",
                                margin: "0px",
                                backgroundColor: "#ffe685",
                              }
                            : {
                                textAlign: "center",
                                fontSize: "large",
                                cursor: "pointer",
                                color: "#8f6b00",
                                width: "100%",
                                maxHeight: "56px",
                                maxWidth: "212px",
                                minWidth: "212px",
                                margin: "0px",
                                backgroundColor: "#ffcc00",
                              }
                        }
                        onMouseEnter={togglehover12}
                        onMouseLeave={togglehover12}
                        onClick={toggleactive12}
                      >
                        More Information..
                      </HashLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="carousel-item"
              data-interval="12000"
              style={{ height: "70vh" }}
            >
              <img
                id="slideshow3"
                /* src={require("../assets/SlideShow3.jpg")} */
                style={{
                  height: "100%",
                  width: "100%",
                }}
              ></img>
              <div className="container">
                <div
                  id="slideshowposition"
                  className="carousel-caption justify-content-lg-center align-items-center"
                  style={{ padding: "0px" }}
                >
                  <div
                    className="container-fluid justify-content-lg-center align-items-center"
                    style={{ padding: "0px" }}
                  >
                    <p
                      id="slideshow3mainlabel"
                      className="col-md-12 order-md-1"
                      style={{
                        textAlign: "center",
                        fontFamily: "AvantGarde Bk BT",
                        color: "white", //#8A1F61
                        padding: "0px",
                        margin: "0px",
                        width: "100%",
                        textShadow:
                          "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000",
                      }}
                    >
                      Health Care Professional Providers
                    </p>
                  </div>
                  <div
                    className="container-fluid justify-content-lg-center align-items-center"
                    style={{
                      textAlign: "justify",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    <p
                      id="slideshow3sublabel"
                      className="col-md-12 order-md-1"
                      style={{
                        float: "none",
                        textAlign: "center",
                        fontFamily: "Abadi MT Std",
                        color: "white",
                        padding: "0px",
                        margin: "0px",
                        width: "100%",
                        textShadow:
                          /* "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000", */
                          "-1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000",
                      }}
                    >
                      Where the integration of Hawaii's Valued Care Providers
                      and Health Care Professional Resources come together.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div style={{ width: "100%", borderBottom: "6px solid #ffcc00" }}></div>

        <div style={{ height: "24px" }}></div>
        <div className="container">
          <h1
            /* className="lead" */
            style={{
              textAlign: "center",
              fontFamily: "AvantGarde Bk BT",
              paddingRight: "0px",
              paddingLeft: "0px",
              color: "#008080",
              /* textShadow: "-1px 1px 2px #000", */
            }}
          >
            INNOVATIVE, INTEGRITY, TRUST
          </h1>
        </div>
        <div style={{ height: "5px" }}></div>
        <div
          className="container"
          /* id="slideshow3" */
          style={{
            /* backgroundImage:
              "url('" + require("../assets/Headline.png") + "')", */
            opacity: "80%",
            backgroundSize: "100% 100%",
            objectFit: "cover",
          }}
        >
          <div
            className="row featurette"
            style={{
              opacity: "100%",
            }}
          >
            <div style={{ height: "5px" }}></div>
            <div
              className="row featurette"
              style={{
                margin: "0px",
              }}
            >
              <div className="container">
                <h4
                  /* className="lead" */
                  style={{
                    opacity: "100%",
                    color: "black",
                    textAlign: "justify",
                    fontFamily: "Abadi MT Std",
                    /* textShadow: "-1px 1px 2px #000", */
                  }}
                >
                  Designed specifically with the needs of Hawaii’s Kūpuna in
                  mind, the senior
                  <a
                    /* className="lead" */
                    style={{
                      color: "black",
                      fontSize: "1em",
                      fontFamily: "Abadi MT Std",
                      /* textShadow: "-1px 1px 2px #000", */
                      fontWeight: "bold",
                    }}
                  >
                    HUB
                  </a>
                  solutions concept of Access & Availability allows Health Care
                  Professionals and family members direct access to a
                  comprehensive registry of appropriate care options. The senior
                  <a
                    /* className="lead" */
                    style={{
                      color: "black",
                      fontSize: "1em",
                      fontFamily: "Abadi MT Std",
                      /* textShadow: "-1px 1px 2px #000", */
                      fontWeight: "bold",
                    }}
                  >
                    HUB
                  </a>
                  solutions Web Portal features a gallery of homes offering
                  living accommodations easily navigated via a computer or
                  mobile device…in real time!
                </h4>
                <div style={{ height: "10px" }}></div>
                <h4
                  /* className="lead" */
                  style={{
                    opacity: "100%",
                    color: "black",
                    textAlign: "justify",
                    fontFamily: "Abadi MT Std",
                    /* textShadow: "-1px 1px 2px #000", */
                  }}
                >
                  senior
                  <a
                    /* className="lead" */
                    style={{
                      color: "black",
                      fontSize: "1em",
                      fontFamily: "Abadi MT Std",
                      /* textShadow: "-1px 1px 2px #000", */
                      fontWeight: "bold",
                    }}
                  >
                    HUB
                  </a>
                  solutions understands the importance of carefully exploring
                  options and outcomes with a clear understanding of health care
                  needs combined with relevant information. For this reason, the
                  senior
                  <a
                    /* className="lead" */
                    style={{
                      color: "black",
                      fontSize: "1em",
                      fontFamily: "Abadi MT Std",
                      /* textShadow: "-1px 1px 2px #000", */
                      fontWeight: "bold",
                    }}
                  >
                    HUB
                  </a>
                  solutions Web Portal is purposeful in its design to those
                  needs.
                </h4>
              </div>
            </div>
          </div>
        </div>

        {/* <hr className="featurette-divider" style={{ color: "black" }} /> */}

        <div style={{ height: "80px" }}></div>

        <div
          /* className="border-top border-bottom" */ style={{
            borderBottom: "4px solid white", //#ffcc00
          }}
        />
        <div style={{ backgroundColor: "white" }}>
          <b>
            <div style={{ height: "72px" }}></div>
            <h1
              /* className="lead" */
              style={{
                opacity: "100%",
                textAlign: "center",
                fontSize: "3.5em",
                fontFamily: "Angelinas",
                paddingRight: "24px",
                paddingLeft: "24px",
                margin: "0px",
                color: "#008080",
                /* textShadow: "-1px 1px 2px #000", */
              }}
            >
              We are Here for You, Every Step of the Way
            </h1>
            <div style={{ height: "0" }} id="network"></div>
            <div style={{ height: "72px" }}></div>
          </b>
        </div>
        <div
          /* className="border-top border-bottom" */ style={{
            borderBottom: "4px solid white", //#ffcc00
          }}
        />

        <div style={{ height: "80px" }}></div>

        {/* <hr className="featurette-divider" style={{ color: "black" }} /> */}

        <div className="container marketing">
          <div className="row featurette">
            <div
              className="container"
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <h1
                /* className="lead" */
                style={{
                  textAlign: "center",
                  fontFamily: "AvantGarde Bk BT",
                  color: "#008080",
                  /* textShadow: "-1px 1px 2px #000", */
                }}
              >
                NETWORK OF VALUED CARE PROVIDERS
              </h1>
              <div style={{ height: "24px" }}></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 text-center">
              <div className="container">
                <img
                  src={require("../assets/ARCH.jpg")}
                  style={{
                    border: "2px solid #ffcc00",
                    height: "100%",
                    width: "100%",
                    borderRadius: "25px",
                  }}
                />
              </div>
              <h2
                style={{
                  textAlign: "center",
                  fontFamily: "AvantGarde Bk BT",
                  color: "#008080",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  margin: "0px",
                  /* textShadow: "-1px 1px 2px #000", */
                }}
              >
                Adult Residential Care Homes (ARCH)
              </h2>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                }}
              >
                An Adult Residential Care Home (ARCH) is a licensed residential
                care setting that provides 24-hour living accommodations for a
                fee to adults (unrelated to the family) who require at least
                minimal assistance in ADLs, known as the activities of daily
                living.
              </p>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                }}
              >
                ADLs are defined as things we normally do such as feeding
                ourselves, bathing, dressing, grooming, etc. Health
                professionals routinely refer to the ability or inability to
                perform ADLs as a measurement of a person’s functional status.
              </p>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                }}
              >
                Adult Residential Care Homes are highly desirable for loved ones
                who cherish their independence but need some assistance with
                daily activities. Loved ones can live in private or semi-private
                rooms and enjoy complete freedom from home maintenance and
                household chores.
              </p>
              <div id="optionbalancer1" className="container"></div>
              <p style={{ margin: "0px" }}>
                <a
                  className="btn btn-lg btn-secondary"
                  style={
                    hover21
                      ? {
                          fontSize: "large",
                          cursor: "pointer",
                          color: "#8f6b00",
                          width: "172px",
                          margin: "0px",
                          backgroundColor: "#ffe685",
                        }
                      : {
                          fontSize: "large",
                          cursor: "pointer",
                          color: "#8f6b00",
                          width: "172px",
                          margin: "0px",
                          backgroundColor: "#ffcc00",
                        }
                  }
                  onMouseEnter={togglehover21}
                  onMouseLeave={togglehover21}
                  onClick={toggleactive21}
                >
                  View Gallery &raquo;
                </a>
              </p>
            </div>
            <div className="col-lg-4 text-center">
              <div className="container">
                <img
                  src={require("../assets/EARCH.jpg")}
                  style={{
                    border: "2px solid #ffcc00",
                    height: "100%",
                    width: "100%",
                    borderRadius: "25px",
                  }}
                />
              </div>
              <h2
                style={{
                  textAlign: "center",
                  fontFamily: "AvantGarde Bk BT",
                  color: "#008080",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  margin: "0px",
                  /* textShadow: "-1px 1px 2px #000", */
                }}
              >
                Adult Residential Care Homes (E-ARCH)
              </h2>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                }}
              >
                An Expanded Adult Residential Care Home (E-ARCH) is defined as a
                licensed residential care setting that provides 24-hour living
                accommodations for a fee to adults (unrelated to the family) who
                require assistance with activities of daily living (ADLs). An
                Expanded ARCH (E-ARCH) has the additional provision of
                health-related services needed by residents who meet nursing
                facility level of care criteria.
              </p>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                }}
              >
                ADLs are defined as things we normally do such as feeding
                ourselves, bathing, dressing, grooming, etc. Health
                professionals routinely refer to the ability or inability to
                perform ADLs as a measurement of a person’s functional status.
              </p>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                }}
              >
                Expanded Adult Residential Care Homes are highly desirable for
                loved ones who cherish their independence but need 24 hrs.
                assistance with daily activities and may require skilled nursing
                services. Loved ones can live in private or semi-private rooms
                and enjoy complete freedom from home maintenance and household
                chores.
              </p>
              <div id="optionbalancer2" className="container"></div>
              <p style={{ margin: "0px" }}>
                <a
                  className="btn btn-lg btn-secondary"
                  style={
                    hover22
                      ? {
                          fontSize: "large",
                          cursor: "pointer",
                          color: "#8f6b00",
                          width: "172px",
                          margin: "0px",
                          backgroundColor: "#ffe685",
                        }
                      : {
                          fontSize: "large",
                          cursor: "pointer",
                          color: "#8f6b00",
                          width: "172px",
                          margin: "0px",
                          backgroundColor: "#ffcc00",
                        }
                  }
                  onMouseEnter={togglehover22}
                  onMouseLeave={togglehover22}
                  onClick={toggleactive22}
                >
                  View Gallery &raquo;
                </a>
              </p>
            </div>
            <div className="col-lg-4 text-center">
              <div className="container">
                <img
                  src={require("../assets/AFCH.jpg")}
                  style={{
                    border: "2px solid #ffcc00",
                    height: "100%",
                    width: "100%",
                    borderRadius: "25px",
                  }}
                />
              </div>
              <h2
                style={{
                  textAlign: "center",
                  fontFamily: "AvantGarde Bk BT",
                  color: "#008080",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  margin: "0px",
                  /* textShadow: "-1px 1px 2px #000", */
                }}
              >
                Adult Foster Care Homes (AFCH)
              </h2>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                }}
              >
                Adult Foster Care Homes are licensed and designed in caring for
                the elderly and disabled. Providing personal care, management
                and administering of medications, assistance with the activities
                of daily living (mobility, bathing, toileting, etc.), room and
                board, 24-hour supervision, and homemaker services (preparation
                of meals, laundry, shopping, etc.), for their residents. The
                Adult Foster Care Home provides care for up to three persons,
                providing a family-like environment for your loved one(s).
              </p>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                }}
              >
                For Medicaid recipients, State and Federal programs help with
                costs for those with limited income and assets. Private pay
                individuals are also accepted into an Adult Foster Care Home.
                Residents are placed into an Adult Foster Care Home at their
                appropriate level of care by a Case Management Agency and
                continue to receive case management services by the agency.
              </p>
              <div id="optionbalancer3" className="container"></div>
              <p style={{ margin: "0px" }}>
                <a
                  className="btn btn-lg btn-secondary"
                  style={
                    hover23
                      ? {
                          fontSize: "large",
                          cursor: "pointer",
                          color: "#8f6b00",
                          width: "172px",
                          margin: "0px",
                          backgroundColor: "#ffe685",
                        }
                      : {
                          fontSize: "large",
                          cursor: "pointer",
                          color: "#8f6b00",
                          width: "172px",
                          margin: "0px",
                          backgroundColor: "#ffcc00",
                        }
                  }
                  onMouseEnter={togglehover23}
                  onMouseLeave={togglehover23}
                  onClick={toggleactive23}
                >
                  View Gallery &raquo;
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* <hr className="featurette-divider" style={{ color: "black" }} /> */}
        <div style={{ height: "80px" }}></div>
        <div
          /* className="text-muted" */ style={{
            borderTop: "4px solid #ffcc00",
            borderBottom: "4px solid #ffcc00",
            color: "black",
            textAlign: "center",
            height: "33px",
            backgroundImage: "url(" + require("../assets/Background.jpg") + ")",
            backgroundSize: "contain",
            backgroundRepeat: "repeat",
          }}
        >
          {/* ...pending logo image from Jeanne (with different color/texture)... */}
        </div>
        <div style={{ height: "80px" }}></div>

        <div className="container marketing">
          <div className="row featurette" id="section1">
            <div
              className="container"
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <h1
                /* className="lead" */
                style={{
                  textAlign: "center",
                  fontFamily: "AvantGarde Bk BT",
                  color: "#008080",
                  /* textShadow: "-1px 1px 2px #000", */
                }}
              >
                OTHER CARE OPTIONS
              </h1>
              <div style={{ height: "24px" }}></div>
            </div>
          </div>

          <div className="container marketing">
            <div className="row">
              <div className="col-lg-4 text-center">
                <div className="container">
                  <img
                    src={require("../assets/RESPITE CARE.jpg")}
                    style={{
                      border: "2px solid #ffcc00",
                      height: "100%",
                      width: "100%",
                      borderRadius: "25px",
                    }}
                  />
                </div>
                <h2
                  style={{
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "#008080",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    margin: "0px",
                    /* textShadow: "-1px 1px 2px #000", */
                  }}
                >
                  Respite Care
                </h2>
                <p
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontFamily: "Abadi MT Std",
                    fontSize: "1.1em",
                  }}
                >
                  Respite care provides a planned short-term and time-limited
                  break for family caregivers and for older adults who need
                  short- term stay for recovery. Families take immense joy in
                  being able to provide care for their loved ones so that they
                  can remain at home. The physical, emotional, and financial
                  consequences for the family caregivers can be overwhelming
                  without a support system, such as respite. Respite care
                  provides a break for the family caregiver and supports in
                  maintaining the caregiving relationship to sustain family
                  caregiver health and wellness. Speak with your Valued Care
                  Provider for further details.
                </p>
              </div>
              <div className="col-lg-4 text-center">
                <div className="container">
                  <img
                    src={require("../assets/HOSPICE CARE.jpg")}
                    style={{
                      border: "2px solid #ffcc00",
                      height: "100%",
                      width: "100%",
                      borderRadius: "25px",
                    }}
                  />
                </div>
                <h2
                  style={{
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "#008080",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    margin: "0px",
                    /* textShadow: "-1px 1px 2px #000", */
                  }}
                >
                  Hospice Care
                </h2>
                <p
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontFamily: "Abadi MT Std",
                    fontSize: "1.1em",
                  }}
                >
                  Hospice care is a type of health care that focuses on the
                  palliation of a terminally ill patient’s pain and symptoms and
                  attending to their emotional and spiritual needs at the end of
                  life. Hospice care provides support to families and
                  prioritizes individual wishes, comfort and quality of life by
                  reducing pain and suffering. This can include addressing
                  physical, emotional, spiritual and/or social needs. In hospice
                  care, patient-directed goals are integral and interwoven
                  throughout the care. Speak with your Valued Care Provider for
                  further details.
                </p>
              </div>
              <div className="col-lg-4 text-center">
                <div className="container">
                  <img
                    src={require("../assets/MEMORY CARE.jpg")}
                    style={{
                      border: "2px solid #ffcc00",
                      height: "100%",
                      width: "100%",
                      borderRadius: "25px",
                    }}
                  />
                </div>
                <h2
                  style={{
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "#008080",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    margin: "0px",
                    /* textShadow: "-1px 1px 2px #000", */
                  }}
                >
                  Memory Care
                </h2>
                <p
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontFamily: "Abadi MT Std",
                    fontSize: "1.1em",
                  }}
                >
                  Memory care services are focused firmly on the individual
                  living with dementia, including its’ most common form,
                  Alzheimer’s. Memory care services provide a secure and safe
                  environment for your loved one. Residents are encouraged to
                  participate in daily activities, these activities are designed
                  to stimulate and help memory care residents work on their
                  cognitive abilities. Speak with your Valued Care Provider for
                  further details.
                </p>
              </div>
            </div>
          </div>

          <div className="row featurette">
            <div className="container-fluid justify-content-lg-center align-items-center">
              <p style={{ textAlign: "center", margin: "0px" }}>
                <a
                  className="btn btn-lg btn-secondary"
                  style={
                    hover24
                      ? {
                          fontSize: "large",
                          cursor: "pointer",
                          color: "#8f6b00",
                          width: "200px",
                          margin: "0px",
                          backgroundColor: "#ffe685",
                        }
                      : {
                          fontSize: "large",
                          cursor: "pointer",
                          color: "#8f6b00",
                          width: "200px",
                          margin: "0px",
                          backgroundColor: "#ffcc00",
                        }
                  }
                  onMouseEnter={togglehover24}
                  onMouseLeave={togglehover24}
                  onClick={toggleactive24}
                >
                  Gallery of Homes &raquo;
                </a>
              </p>
            </div>
          </div>
        </div>

        <div style={{ height: "56px" }}></div>
        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default Home;
