import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "./types";

export const registerSuccess = () => ({
  type: REGISTER_SUCCESS,
});

export const registerFailed = () => ({
  type: REGISTER_FAIL,
});

export const loginSuccess = (data: any) => ({
  type: LOGIN_SUCCESS,
  payload: { user: data },
});

export const loginFailed = () => ({
  type: LOGIN_FAIL,
});

export const invokeLogout = () => ({
  type: LOGOUT,
});
