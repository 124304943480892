/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import {
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonToolbar,
} from "@ionic/react";

/* Theme variables */
import "./NavBar.css";
/* Icons */
import { personCircle } from "ionicons/icons";
/* Icons */
import { menu } from "ionicons/icons";
/* Others */
import { history } from "../helpers/history";
import { HashLink } from "react-router-hash-link";

const NavBar: React.FC = () => {
  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");

  const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  /* const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [hover1, setHover1] = useState<boolean>(false);
  const [hover2, setHover2] = useState<boolean>(false);
  const [hover3, setHover3] = useState<boolean>(false);
  const [hover4, setHover4] = useState<boolean>(false);
  const [hover5, setHover5] = useState<boolean>(false);
  const [hover6, setHover6] = useState<boolean>(false);
  const [hover7, setHover7] = useState<boolean>(false);
  const [aactive1, setAactive1] = useState<boolean>(true);
  const [aactive2, setAactive2] = useState<boolean>(false);
  const [aactive3, setAactive3] = useState<boolean>(false);
  const [aactive4, setAactive4] = useState<boolean>(false);
  const [aactive5, setAactive5] = useState<boolean>(false);
  const [aactive6, setAactive6] = useState<boolean>(false);
  const [aactive7, setAactive7] = useState<boolean>(false);

  const [tempdisablesubbar1, setTempdisablesubbar1] = useState<boolean>(false);
  const [tempdisablesubbar2, setTempdisablesubbar2] = useState<boolean>(false);

  const [hoversub1, setHoversub1] = useState<boolean>(false);
  const [hoversub2, setHoversub2] = useState<boolean>(false);
  const [hoversub3, setHoversub3] = useState<boolean>(false);
  const [hoversub4, setHoversub4] = useState<boolean>(false);
  const [hoversub5, setHoversub5] = useState<boolean>(false);
  const [hoversub6, setHoversub6] = useState<boolean>(false);
  const [hoversub7, setHoversub7] = useState<boolean>(false);
  const [hoversub8, setHoversub8] = useState<boolean>(false);
  const [hoversub9, setHoversub9] = useState<boolean>(false);
  const [aactivesub1, setAactivesub1] = useState<boolean>(false);
  const [aactivesub2, setAactivesub2] = useState<boolean>(false);
  const [aactivesub3, setAactivesub3] = useState<boolean>(false);
  const [aactivesub4, setAactivesub4] = useState<boolean>(false);
  const [aactivesub5, setAactivesub5] = useState<boolean>(false);
  const [aactivesub6, setAactivesub6] = useState<boolean>(false);
  const [aactivesub7, setAactivesub7] = useState<boolean>(false);
  const [aactivesub8, setAactivesub8] = useState<boolean>(false);
  const [aactivesub9, setAactivesub9] = useState<boolean>(false);

  const [hoverprofile, setHoverprofile] = useState<boolean>(false);

  useEffect(() => {
    const platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);

    const isnative: any = localStorage.getItem("isnative")
      ? localStorage.getItem("isnative")
      : "";

    setIsnativeusedglobal(isnative);
  }, []);

  useEffect(() => {
    if (history.location.pathname === "/home") {
      localStorage.setItem("mselected", "1");
      localStorage.setItem("msubselected", "0");
    } else if (history.location.pathname === "/team") {
      localStorage.setItem("mselected", "2");
      localStorage.setItem("msubselected", "1");
    } else if (history.location.pathname === "/missionstatement") {
      localStorage.setItem("mselected", "2");
      localStorage.setItem("msubselected", "2");
    } else if (history.location.pathname === "/visionstatement") {
      localStorage.setItem("mselected", "2");
      localStorage.setItem("msubselected", "3");
    } else if (history.location.pathname === "/gallery/arch") {
      localStorage.setItem("mselected", "3");
      localStorage.setItem("msubselected", "4");
    } else if (history.location.pathname === "/gallery/earch") {
      localStorage.setItem("mselected", "3");
      localStorage.setItem("msubselected", "5");
    } else if (history.location.pathname === "/gallery/afch") {
      localStorage.setItem("mselected", "3");
      localStorage.setItem("msubselected", "6");
    } else if (history.location.pathname === "/gallery/rc") {
      localStorage.setItem("mselected", "3");
      localStorage.setItem("msubselected", "7");
    } else if (history.location.pathname === "/gallery/hc") {
      localStorage.setItem("mselected", "3");
      localStorage.setItem("msubselected", "8");
    } else if (history.location.pathname === "/gallery/mc") {
      localStorage.setItem("mselected", "3");
      localStorage.setItem("msubselected", "9");
    } else if (
      history.location.pathname === "/healthcareprofessionalresources"
    ) {
      localStorage.setItem("mselected", "4");
      localStorage.setItem("msubselected", "0");
    } else if (history.location.pathname === "/valuedcareproviders") {
      localStorage.setItem("mselected", "5");
      localStorage.setItem("msubselected", "0");
    } else if (history.location.pathname === "/casemanagement") {
      localStorage.setItem("mselected", "6");
      localStorage.setItem("msubselected", "0");
    } else if (history.location.pathname === "/helpfulinformation") {
      localStorage.setItem("mselected", "7");
      localStorage.setItem("msubselected", "0");
    }
  }, [history.location.pathname]);

  useEffect(() => {
    const currentMenu = localStorage.getItem("mselected");

    if (currentMenu === "1") {
      setAactive1(true);
      setAactive2(false);
      setAactive3(false);
      setAactive4(false);
      setAactive5(false);
      setAactive6(false);
      setAactive7(false);
    } else if (currentMenu === "2") {
      setAactive1(false);
      setAactive2(true);
      setAactive3(false);
      setAactive4(false);
      setAactive5(false);
      setAactive6(false);
      setAactive7(false);
    } else if (currentMenu === "3") {
      setAactive1(false);
      setAactive2(false);
      setAactive3(true);
      setAactive4(false);
      setAactive5(false);
      setAactive6(false);
      setAactive7(false);
    } else if (currentMenu === "4") {
      setAactive1(false);
      setAactive2(false);
      setAactive3(false);
      setAactive4(true);
      setAactive5(false);
      setAactive6(false);
      setAactive7(false);
    } else if (currentMenu === "5") {
      setAactive1(false);
      setAactive2(false);
      setAactive3(false);
      setAactive4(false);
      setAactive5(true);
      setAactive6(false);
      setAactive7(false);
    } else if (currentMenu === "6") {
      setAactive1(false);
      setAactive2(false);
      setAactive3(false);
      setAactive4(false);
      setAactive5(false);
      setAactive6(true);
      setAactive7(false);
    } else if (currentMenu === "7") {
      setAactive1(false);
      setAactive2(false);
      setAactive3(false);
      setAactive4(false);
      setAactive5(false);
      setAactive6(false);
      setAactive7(true);
    } else {
      setAactive1(true);
      setAactive2(false);
      setAactive3(false);
      setAactive4(false);
      setAactive5(false);
      setAactive6(false);
      setAactive7(false);
    }
  }, [localStorage.getItem("mselected")]);

  useEffect(() => {
    const currentsubMenu = localStorage.getItem("msubselected");

    if (currentsubMenu === "1") {
      setAactivesub1(true);
      setAactivesub2(false);
      setAactivesub3(false);
      setAactivesub4(false);
      setAactivesub5(false);
      setAactivesub6(false);
      setAactivesub7(false);
      setAactivesub8(false);
      setAactivesub9(false);
    } else if (currentsubMenu === "2") {
      setAactivesub1(false);
      setAactivesub2(true);
      setAactivesub3(false);
      setAactivesub4(false);
      setAactivesub5(false);
      setAactivesub6(false);
      setAactivesub7(false);
      setAactivesub8(false);
      setAactivesub9(false);
    } else if (currentsubMenu === "3") {
      setAactivesub1(false);
      setAactivesub2(false);
      setAactivesub3(true);
      setAactivesub4(false);
      setAactivesub5(false);
      setAactivesub6(false);
      setAactivesub7(false);
      setAactivesub8(false);
      setAactivesub9(false);
    } else if (currentsubMenu === "4") {
      setAactivesub1(false);
      setAactivesub2(false);
      setAactivesub3(false);
      setAactivesub4(true);
      setAactivesub5(false);
      setAactivesub6(false);
      setAactivesub7(false);
      setAactivesub8(false);
      setAactivesub9(false);
    } else if (currentsubMenu === "5") {
      setAactivesub1(false);
      setAactivesub2(false);
      setAactivesub3(false);
      setAactivesub4(false);
      setAactivesub5(true);
      setAactivesub6(false);
      setAactivesub7(false);
      setAactivesub8(false);
      setAactivesub9(false);
    } else if (currentsubMenu === "6") {
      setAactivesub1(false);
      setAactivesub2(false);
      setAactivesub3(false);
      setAactivesub4(false);
      setAactivesub5(false);
      setAactivesub6(true);
      setAactivesub7(false);
      setAactivesub8(false);
      setAactivesub9(false);
    } else if (currentsubMenu === "7") {
      setAactivesub1(false);
      setAactivesub2(false);
      setAactivesub3(false);
      setAactivesub4(false);
      setAactivesub5(false);
      setAactivesub6(false);
      setAactivesub7(true);
      setAactivesub8(false);
      setAactivesub9(false);
    } else if (currentsubMenu === "8") {
      setAactivesub1(false);
      setAactivesub2(false);
      setAactivesub3(false);
      setAactivesub4(false);
      setAactivesub5(false);
      setAactivesub6(false);
      setAactivesub7(false);
      setAactivesub8(true);
      setAactivesub9(false);
    } else if (currentsubMenu === "9") {
      setAactivesub1(false);
      setAactivesub2(false);
      setAactivesub3(false);
      setAactivesub4(false);
      setAactivesub5(false);
      setAactivesub6(false);
      setAactivesub7(false);
      setAactivesub8(false);
      setAactivesub9(true);
    } else if (currentsubMenu === "0") {
      setAactivesub1(false);
      setAactivesub2(false);
      setAactivesub3(false);
      setAactivesub4(false);
      setAactivesub5(false);
      setAactivesub6(false);
      setAactivesub7(false);
      setAactivesub8(false);
      setAactivesub9(false);
    }
  }, [localStorage.getItem("msubselected")]);

  function togglehover1() {
    setHover1(!hover1);
  }
  function togglehover2() {
    setHover2(!hover2);
    setTempdisablesubbar1(false);

    if (!hover2) {
      if (aactive3) {
        setTempdisablesubbar2(true);
      }
    } else if (hover2) {
      if (aactive3) {
        setTempdisablesubbar2(false);
      }
    }
  }
  function togglehover3() {
    setHover3(!hover3);
    setTempdisablesubbar2(false);

    if (!hover3) {
      if (aactive2) {
        setTempdisablesubbar1(true);
      }
    } else if (hover3) {
      if (aactive2) {
        setTempdisablesubbar1(false);
      }
    }
  }
  function togglehover4() {
    setHover4(!hover4);
  }
  function togglehover5() {
    setHover5(!hover5);
  }
  function togglehover6() {
    setHover6(!hover6);
  }
  function togglehover7() {
    setHover7(!hover7);
  }

  function togglehoversub1true() {
    setHoversub1(true);
  }
  function togglehoversub1false() {
    setHoversub1(false);
  }
  function togglehoversub2true() {
    setHoversub2(true);
  }
  function togglehoversub2false() {
    setHoversub2(false);
  }
  function togglehoversub3true() {
    setHoversub3(true);
  }
  function togglehoversub3false() {
    setHoversub3(false);
  }
  function togglehoversub4true() {
    setHoversub4(true);
  }
  function togglehoversub4false() {
    setHoversub4(false);
  }
  function togglehoversub5true() {
    setHoversub5(true);
  }
  function togglehoversub5false() {
    setHoversub5(false);
  }
  function togglehoversub6true() {
    setHoversub6(true);
  }
  function togglehoversub6false() {
    setHoversub6(false);
  }
  function togglehoversub7true() {
    setHoversub7(true);
  }
  function togglehoversub7false() {
    setHoversub7(false);
  }
  function togglehoversub8true() {
    setHoversub8(true);
  }
  function togglehoversub8false() {
    setHoversub8(false);
  }
  function togglehoversub9true() {
    setHoversub9(true);
  }
  function togglehoversub9false() {
    setHoversub9(false);
  }

  function togglehoverprofile() {
    setHoverprofile(!hoverprofile);
  }

  function toggleactive1() {
    localStorage.setItem("mselected", "1");
    //localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "1");
    setAactive1(true);
    setAactive2(false);
    setAactive3(false);
    setAactive4(false);
    setAactive5(false);
    setAactive6(false);
    setAactive7(false);
    history.push("/home");
  }
  function toggleactive2() {
    localStorage.setItem("mselected", "2");
    //localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(true);
    setAactive3(false);
    setAactive4(false);
    setAactive5(false);
    setAactive6(false);
    setAactive7(false);

    if (window.innerWidth < 768) {
      if (
        localStorage.getItem("msubselected") !== "1" &&
        localStorage.getItem("msubselected") !== "2" &&
        localStorage.getItem("msubselected") !== "3"
      ) {
        toggleactivesub1();
      } else {
        if (localStorage.getItem("msubselected") === "1") {
          toggleactivesub1();
        } else if (localStorage.getItem("msubselected") === "2") {
          toggleactivesub2();
        } else if (localStorage.getItem("msubselected") === "3") {
          toggleactivesub3();
        }
      }

      setTempdisablesubbar1(!tempdisablesubbar1);
    } else {
      toggleactivesub1();
      //history.push("/team");
    }
  }
  function toggleactive3() {
    localStorage.setItem("mselected", "3");
    //localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(false);
    setAactive3(true);
    setAactive4(false);
    setAactive5(false);
    setAactive6(false);
    setAactive7(false);

    if (window.innerWidth < 768) {
      if (
        localStorage.getItem("msubselected") === "0" ||
        localStorage.getItem("msubselected") === "1" ||
        localStorage.getItem("msubselected") === "2" ||
        localStorage.getItem("msubselected") === "3"
      ) {
        toggleactivesub4();
      } else {
        if (localStorage.getItem("msubselected") === "4") {
          toggleactivesub4();
        } else if (localStorage.getItem("msubselected") === "5") {
          toggleactivesub5();
        } else if (localStorage.getItem("msubselected") === "6") {
          toggleactivesub6();
        } else if (localStorage.getItem("msubselected") === "7") {
          toggleactivesub7();
        } else if (localStorage.getItem("msubselected") === "8") {
          toggleactivesub8();
        } else if (localStorage.getItem("msubselected") === "9") {
          toggleactivesub9();
        }
      }

      setTempdisablesubbar2(!tempdisablesubbar2);
    } else {
      toggleactivesub4();
      //history.push("/team");
    }
  }
  function toggleactive4() {
    localStorage.setItem("mselected", "4");
    localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(false);
    setAactive3(false);
    setAactive4(true);
    setAactive5(false);
    setAactive6(false);
    setAactive7(false);
    history.push("/healthcareprofessionalresources");
  }
  function toggleactive5() {
    localStorage.setItem("mselected", "5");
    localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(false);
    setAactive3(false);
    setAactive4(false);
    setAactive5(true);
    setAactive6(false);
    setAactive7(false);
    history.push("/valuedcareproviders");
  }
  function toggleactive6() {
    localStorage.setItem("mselected", "6");
    localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(false);
    setAactive3(false);
    setAactive4(false);
    setAactive5(false);
    setAactive6(true);
    setAactive7(false);
    history.push("/casemanagement");
  }
  function toggleactive7() {
    localStorage.setItem("mselected", "7");
    localStorage.setItem("msubselected", "0");
    localStorage.setItem("mfooterselected", "0");
    setAactive1(false);
    setAactive2(false);
    setAactive3(false);
    setAactive4(false);
    setAactive5(false);
    setAactive6(false);
    setAactive7(true);
    history.push("/helpfulinformation");
  }

  function toggleactivesub1() {
    localStorage.setItem("mselected", "2");
    localStorage.setItem("msubselected", "1");
    localStorage.setItem("mfooterselected", "0");
    setAactivesub1(true);
    setAactivesub2(false);
    setAactivesub3(false);
    setAactivesub4(false);
    setAactivesub5(false);
    setAactivesub6(false);
    setAactivesub7(false);
    setAactivesub8(false);
    setAactivesub9(false);

    if (window.innerWidth < 768) {
      setTempdisablesubbar1(true);
    }
    history.push("/team");
  }
  function toggleactivesub2() {
    localStorage.setItem("mselected", "2");
    localStorage.setItem("msubselected", "2");
    localStorage.setItem("mfooterselected", "0");
    setAactivesub1(false);
    setAactivesub2(true);
    setAactivesub3(false);
    setAactivesub4(false);
    setAactivesub5(false);
    setAactivesub6(false);
    setAactivesub7(false);
    setAactivesub8(false);
    setAactivesub9(false);

    if (window.innerWidth < 768) {
      setTempdisablesubbar1(true);
    }
    /* history.push("/missionstatement"); */
  }
  function toggleactivesub3() {
    localStorage.setItem("mselected", "2");
    localStorage.setItem("msubselected", "3");
    localStorage.setItem("mfooterselected", "0");
    setAactivesub1(false);
    setAactivesub2(false);
    setAactivesub3(true);
    setAactivesub4(false);
    setAactivesub5(false);
    setAactivesub6(false);
    setAactivesub7(false);
    setAactivesub8(false);
    setAactivesub9(false);

    if (window.innerWidth < 768) {
      setTempdisablesubbar1(true);
    }
    /* history.push("/visionstatement"); */
  }
  function toggleactivesub4() {
    localStorage.setItem("mselected", "3");
    localStorage.setItem("msubselected", "4");
    localStorage.setItem("mfooterselected", "0");
    setAactivesub1(false);
    setAactivesub2(false);
    setAactivesub3(false);
    setAactivesub4(true);
    setAactivesub5(false);
    setAactivesub6(false);
    setAactivesub7(false);
    setAactivesub8(false);
    setAactivesub9(false);

    if (window.innerWidth < 768) {
      setTempdisablesubbar2(true);
    }
    history.push("/gallery/arch");
  }
  function toggleactivesub5() {
    localStorage.setItem("mselected", "3");
    localStorage.setItem("msubselected", "5");
    localStorage.setItem("mfooterselected", "0");
    setAactivesub1(false);
    setAactivesub2(false);
    setAactivesub3(false);
    setAactivesub4(false);
    setAactivesub5(true);
    setAactivesub6(false);
    setAactivesub7(false);
    setAactivesub8(false);
    setAactivesub9(false);
    if (window.innerWidth < 768) {
      setTempdisablesubbar2(true);
    }
    history.push("/gallery/earch");
  }
  function toggleactivesub6() {
    localStorage.setItem("mselected", "3");
    localStorage.setItem("msubselected", "6");
    localStorage.setItem("mfooterselected", "0");
    setAactivesub1(false);
    setAactivesub2(false);
    setAactivesub3(false);
    setAactivesub4(false);
    setAactivesub5(false);
    setAactivesub6(true);
    setAactivesub7(false);
    setAactivesub8(false);
    setAactivesub9(false);
    if (window.innerWidth < 768) {
      setTempdisablesubbar2(true);
    }
    history.push("/gallery/afch");
  }
  function toggleactivesub7() {
    localStorage.setItem("mselected", "3");
    localStorage.setItem("msubselected", "7");
    localStorage.setItem("mfooterselected", "0");
    setAactivesub1(false);
    setAactivesub2(false);
    setAactivesub3(false);
    setAactivesub4(false);
    setAactivesub5(false);
    setAactivesub6(false);
    setAactivesub7(true);
    setAactivesub8(false);
    setAactivesub9(false);
    if (window.innerWidth < 768) {
      setTempdisablesubbar2(true);
    }
    history.push("/gallery/rc");
  }
  function toggleactivesub8() {
    localStorage.setItem("mselected", "3");
    localStorage.setItem("msubselected", "8");
    localStorage.setItem("mfooterselected", "0");
    setAactivesub1(false);
    setAactivesub2(false);
    setAactivesub3(false);
    setAactivesub4(false);
    setAactivesub5(false);
    setAactivesub6(false);
    setAactivesub7(false);
    setAactivesub8(true);
    setAactivesub9(false);
    if (window.innerWidth < 768) {
      setTempdisablesubbar2(true);
    }
    history.push("/gallery/hc");
  }
  function toggleactivesub9() {
    localStorage.setItem("mselected", "3");
    localStorage.setItem("msubselected", "9");
    localStorage.setItem("mfooterselected", "0");
    setAactivesub1(false);
    setAactivesub2(false);
    setAactivesub3(false);
    setAactivesub4(false);
    setAactivesub5(false);
    setAactivesub6(false);
    setAactivesub7(false);
    setAactivesub8(false);
    setAactivesub9(true);
    if (window.innerWidth < 768) {
      setTempdisablesubbar2(true);
    }
    history.push("/gallery/mc");
  }

  let pagename = "Welcome to seniorHUBsolutions";

  return (
    <IonHeader /* color="primary" */
      /* id="backgroundwhitebrand"  */ className="fixed-top" //ion-no-border
    >
      <IonToolbar
        color="primary"
        id="backgroundwhitebrand"
        style={{
          height: "0px",
        }}
      ></IonToolbar>
      <nav
        className="navbar navbar-expand-md"
        aria-label="Main navigation"
        style={{
          height: "66px",
          backgroundImage: "url(" + require("../assets/Background.jpg") + ")",
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
        }}
      ></nav>
      <IonToolbar
        id="backgroundwhitebrand"
        style={{
          backgroundColor: "white",
        }}
      >
        <div
          className="nav-scroller bg-body shadow-sm align-items-center"
          style={{ backgroundColor: "white" }}
        >
          <nav
            className="navbar navbar-expand-md justify-content-lg-center"
            aria-label="Primary navigation"
            style={{ backgroundColor: "white" }}
          >
            <div
              className="container-fluid" //justify-content-center align-items-center
              style={{
                textAlign: "center",
                margin: "0px",
                verticalAlign: "middle",
                backgroundColor: "white",
                width: "100%",
              }}
            >
              <IonItem
                id="backgroundwhitebrandparent"
                className="justify-content-md-start align-items-justify" // align-items-center
                style={{
                  textAlign: "center",
                  padding: "0px",
                  minWidth: "262px",
                }}
                lines="none"
              >
                <IonItem
                  id="backgroundwhitebrand"
                  className="container-fluid justify-content-md-center align-items-justify" //navbar-brand container-fluid align-items-center
                  style={{
                    textAlign: "center",
                    margin: "0px",
                    marginLeft: "0px",
                    padding: "0px",
                  }}
                  lines="none"
                >
                  {/* <IonImg
                    src={require("../assets/logo.jpg")}
                    slot="start"
                    style={{
                      float: "center",
                      height: "46px",
                      width: "46px",
                      cursor: "pointer",
                      marginRight: "0px",
                    }}
                    onClick={toggleactive1}
                  /> */}
                  <IonLabel
                    className="container-fluid"
                    style={{
                      verticalAlign: "middle",
                      fontFamily: "Gill Sans Nova",
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "black",
                      padding: "0px",
                      paddingLeft: "22px",
                      margin: "0px",
                      width: "100%",
                      fontWeight: "normal",
                    }}
                    onClick={toggleactive1}
                  >
                    &nbsp;senior
                    <a
                      style={{
                        opacity: "100%",
                        fontFamily: "Gill Sans Nova",
                        color: "black",
                        cursor: "pointer",
                        fontSize: "1.1em",
                        fontWeight: "bold",
                      }}
                    >
                      HUB
                    </a>
                    solutions
                  </IonLabel>
                </IonItem>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarCollapse"
                  aria-controls="navbarCollapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  style={{
                    float: "right",
                    border: "2px #ffcc00",
                    height: "40px",
                    width: "40px",
                    cursor: "pointer",
                    padding: "0px",
                    marginTop: "4px",
                    marginLeft: "4px",
                    marginRight: "4px",
                    marginBottom: "4px",
                    borderColor: "#dba400",
                    borderWidth: "4px",
                  }}
                >
                  <IonIcon
                    color="primary"
                    icon={menu}
                    slot="start"
                    style={{
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                      padding: "0px",
                      margin: "0px",
                    }}
                  />
                </button>
              </IonItem>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav me-auto mb-2 mb-md-0">
                  {/* <li className="nav-item">
                  <p
                    className="nav-link"
                    style={
                      hover1
                        ? {
                            fontSize: `${aactive1 ? "large" : "medium"}`,
                            cursor: "pointer",
                            color: "black",
                            textAlign: "center",
                            textDecorationLine: `${
                              aactive1 ? "underline" : "none"
                            }`,
                          }
                        : {
                            fontSize: `${aactive1 ? "large" : "medium"}`,
                            cursor: "pointer",
                            color: "black",
                            textAlign: "center",
                            textDecorationLine: `${
                              aactive1 ? "underline" : "none"
                            }`,
                          }
                    }
                    onMouseEnter={togglehover1}
                    onMouseLeave={togglehover1}
                    onClick={toggleactive1}
                  >
                    Home
                  </p>
                </li> */}
                  <li className="nav-item">
                    <p
                      className="nav-link"
                      style={
                        hover2
                          ? {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive2 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              /* textDecorationLine: `${
                                aactive2 ? "underline" : "none"
                              }`, */
                              textDecorationLine: "underline",
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive2 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              textDecorationLine: `${
                                aactive2 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover2}
                      onMouseLeave={togglehover2}
                      onClick={toggleactive2}
                    >
                      Meet your Visionary Team
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className="nav-link"
                      style={
                        hover3
                          ? {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive3 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              /* textDecorationLine: `${
                                aactive3 ? "underline" : "none"
                              }`, */
                              textDecorationLine: "underline",
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive3 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              textDecorationLine: `${
                                aactive3 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover3}
                      onMouseLeave={togglehover3}
                      onClick={toggleactive3}
                    >
                      Gallery of Homes
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className="nav-link"
                      style={
                        hover4
                          ? {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive4 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              /* textDecorationLine: `${
                                aactive4 ? "underline" : "none"
                              }`, */
                              textDecorationLine: "underline",
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive4 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              textDecorationLine: `${
                                aactive4 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover4}
                      onMouseLeave={togglehover4}
                      onClick={toggleactive4}
                    >
                      Health Care Professional Resources
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className="nav-link"
                      style={
                        hover5
                          ? {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive5 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              /* textDecorationLine: `${
                                aactive5 ? "underline" : "none"
                              }`, */
                              textDecorationLine: "underline",
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive5 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              textDecorationLine: `${
                                aactive5 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover5}
                      onMouseLeave={togglehover5}
                      onClick={toggleactive5}
                    >
                      Valued Care Providers
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className="nav-link"
                      style={
                        hover6
                          ? {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive6 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              /* textDecorationLine: `${
                                aactive6 ? "underline" : "none"
                              }`, */
                              textDecorationLine: "underline",
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive6 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              textDecorationLine: `${
                                aactive6 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover6}
                      onMouseLeave={togglehover6}
                      onClick={toggleactive6}
                    >
                      Case Management
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className="nav-link"
                      style={
                        hover7
                          ? {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive7 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              /* textDecorationLine: `${
                                aactive7 ? "underline" : "none"
                              }`, */
                              textDecorationLine: "underline",
                            }
                          : {
                              fontFamily: "Abadi MT Std",
                              fontSize: `${aactive7 ? "large" : "medium"}`,
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              margin: "0px",
                              textDecorationLine: `${
                                aactive7 ? "underline" : "none"
                              }`,
                            }
                      }
                      onMouseEnter={togglehover7}
                      onMouseLeave={togglehover7}
                      onClick={toggleactive7}
                    >
                      Helfpul Information
                    </p>
                  </li>
                </ul>
                {currentUser && (
                  <div className="border-top" style={{ height: "10px" }}></div>
                )}
                {currentUser && (
                  <form
                    id="profiledashboardmain"
                    style={{
                      textAlign: "right",
                      margin: "0px",
                      padding: "0px",
                      cursor: "pointer",
                      minWidth: "200px",
                    }}
                    onClick={() => {
                      history.push("/profile");
                    }}
                  >
                    <div
                      className="row align-items-center text-align-center"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <div
                        /* id="profiledashboard" */
                        className="col-lg-2 text-center text-align-center align-contents-center"
                        style={{
                          textAlign: "center",
                          margin: "0px",
                          padding: "0px",
                          maxWidth: "32px",
                          minWidth: "32px",
                        }}
                      >
                        <IonIcon
                          id="profiledashboard"
                          color="primary"
                          style={{
                            height: "32px",
                            width: "32px",
                          }}
                          src={personCircle}
                        />
                      </div>
                      <div
                        className="col-lg-10 text-center text-align-center align-contents-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <p
                          style={
                            hoverprofile
                              ? {
                                  textAlign: "center",
                                  fontFamily: "Abadi MT Std",
                                  color: "black",
                                  margin: "0px",
                                  padding: "0px",
                                  paddingLeft: "6px",
                                  width: "100%",
                                  textDecorationLine: "underline",
                                }
                              : {
                                  textAlign: "center",
                                  fontFamily: "Abadi MT Std",
                                  color: "black",
                                  margin: "0px",
                                  padding: "0px",
                                  paddingLeft: "6px",
                                  width: "100%",
                                  textDecorationLine: "none",
                                }
                          }
                          onMouseEnter={togglehoverprofile}
                          onMouseLeave={togglehoverprofile}
                        >
                          <b id="profiledashboardchangetext1">{`Welcome ${currentUser.firstname} ${currentUser.lastname}`}</b>
                          <b id="profiledashboardchangetext2">View Account</b>
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              </div>

              {/* </div> */}
            </div>
          </nav>
        </div>
        <div className="border-top border-bottom" />

        {(hover2 || aactive2) && !tempdisablesubbar1 && (
          <div className="nav-scroller bg-body shadow-sm align-items-center">
            <div className="container-fluid align-items-center">
              <nav
                className="nav nav-underline justify-content-md-center"
                aria-label="Secondary navigation"
              >
                <p
                  className="nav-link nav-item" // col-lg-4
                  style={
                    hoversub1
                      ? {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          /* textDecorationLine: `${
                            aactivesub1 ? "underline" : "none"
                          }`, */
                          textDecorationLine: "underline",
                        }
                      : {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: `${
                            aactivesub1 ? "underline" : "none"
                          }`,
                        }
                  }
                  onMouseEnter={togglehoversub1true}
                  onMouseLeave={togglehoversub1false}
                  onClick={toggleactivesub1}
                >
                  <HashLink
                    className="nav-link"
                    to="/team#section1"
                    style={{
                      fontFamily: "Abadi MT Std",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    Mission Statement
                  </HashLink>
                </p>
                <p
                  className="nav-link nav-item" // col-lg-4
                  style={
                    hoversub2
                      ? {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          /* textDecorationLine: `${
                            aactivesub2 ? "underline" : "none"
                          }`, */
                          textDecorationLine: "underline",
                        }
                      : {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: `${
                            aactivesub2 ? "underline" : "none"
                          }`,
                        }
                  }
                  onMouseEnter={togglehoversub2true}
                  onMouseLeave={togglehoversub2false}
                  onClick={toggleactivesub2}
                >
                  <HashLink
                    className="nav-link"
                    to="/team#section2"
                    style={{
                      fontFamily: "Abadi MT Std",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    Vision Statement
                  </HashLink>
                </p>
                <p
                  className="nav-link nav-item" // col-lg-4
                  style={
                    hoversub3
                      ? {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          /* textDecorationLine: `${
                            aactivesub3 ? "underline" : "none"
                          }`, */
                          textDecorationLine: "underline",
                        }
                      : {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: `${
                            aactivesub3 ? "underline" : "none"
                          }`,
                        }
                  }
                  onMouseEnter={togglehoversub3true}
                  onMouseLeave={togglehoversub3false}
                  onClick={toggleactivesub3}
                >
                  <HashLink
                    className="nav-link"
                    to="/team#section3"
                    style={{
                      fontFamily: "Abadi MT Std",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    Team Bio and Photo
                  </HashLink>
                </p>
              </nav>
            </div>
          </div>
        )}
        {(hover3 || aactive3) && !tempdisablesubbar2 && (
          <div className="nav-scroller bg-body shadow-sm align-items-center">
            <div className="container-fluid align-items-center">
              <nav
                className="nav nav-underline justify-content-md-center"
                aria-label="Secondary navigation"
              >
                <p
                  className="nav-link nav-item" // col-lg-4
                  style={
                    hoversub4
                      ? {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          /* textDecorationLine: `${
                            aactivesub4 ? "underline" : "none"
                          }`, */
                          textDecorationLine: "underline",
                        }
                      : {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: `${
                            aactivesub4 ? "underline" : "none"
                          }`,
                        }
                  }
                  onMouseEnter={togglehoversub4true}
                  onMouseLeave={togglehoversub4false}
                  onClick={toggleactivesub4}
                >
                  Adult Residential Care Homes (ARCH)
                </p>
                <p
                  className="nav-link nav-item" // col-lg-4
                  style={
                    hoversub5
                      ? {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          /* textDecorationLine: `${
                            aactivesub5 ? "underline" : "none"
                          }`, */
                          textDecorationLine: "underline",
                        }
                      : {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: `${
                            aactivesub5 ? "underline" : "none"
                          }`,
                        }
                  }
                  onMouseEnter={togglehoversub5true}
                  onMouseLeave={togglehoversub5false}
                  onClick={toggleactivesub5}
                >
                  Adult Residential Care Homes (EARCH)
                </p>
                <p
                  className="nav-link nav-item" // col-lg-4
                  style={
                    hoversub6
                      ? {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          /* textDecorationLine: `${
                            aactivesub6 ? "underline" : "none"
                          }`, */
                          textDecorationLine: "underline",
                        }
                      : {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: `${
                            aactivesub6 ? "underline" : "none"
                          }`,
                        }
                  }
                  onMouseEnter={togglehoversub6true}
                  onMouseLeave={togglehoversub6false}
                  onClick={toggleactivesub6}
                >
                  Adult Foster Care Homes (AFCH)
                </p>
                {/* <p
                  className="nav-link nav-item" // col-lg-4
                  style={
                    hoversub7
                      ? {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: "underline",
                        }
                      : {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: `${
                            aactivesub7 ? "underline" : "none"
                          }`,
                        }
                  }
                  onMouseEnter={togglehoversub7true}
                  onMouseLeave={togglehoversub7false}
                  onClick={toggleactivesub7}
                >
                  Respite Care
                </p> */}
                {/* <p
                  className="nav-link nav-item" // col-lg-4
                  style={
                    hoversub8
                      ? {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: "underline",
                        }
                      : {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: `${
                            aactivesub8 ? "underline" : "none"
                          }`,
                        }
                  }
                  onMouseEnter={togglehoversub8true}
                  onMouseLeave={togglehoversub8false}
                  onClick={toggleactivesub8}
                >
                  Hospice Care
                </p> */}
                {/* <p
                  className="nav-link nav-item" // col-lg-4
                  style={
                    hoversub9
                      ? {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: "underline",
                        }
                      : {
                          fontFamily: "Abadi MT Std",
                          cursor: "pointer",
                          color: "black",
                          textAlign: "center",
                          margin: "0px",
                          textDecorationLine: `${
                            aactivesub9 ? "underline" : "none"
                          }`,
                        }
                  }
                  onMouseEnter={togglehoversub9true}
                  onMouseLeave={togglehoversub9false}
                  onClick={toggleactivesub9}
                >
                  Memory Care
                </p> */}
              </nav>
            </div>
          </div>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default NavBar;
