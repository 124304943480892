/* eslint-disable no-cond-assign */
/* eslint-disable no-undef */

if (!localStorage.getItem("isnative") ||
    localStorage.getItem("isnative") === ""
) {
    const currentUser = localStorage.getItem("user") ?
        JSON.parse(localStorage.getItem("user")) :
        false;
    if (currentUser) {
        if (
            currentUser.roles[0] !== "ROLE_ADMIN" &&
            currentUser.roles[1] !== "ROLE_ADMIN" &&
            currentUser.roles[2] !== "ROLE_ADMIN"
        ) {
            if (window.innerWidth === 500) {
                window.liveSiteAsyncInit = function() {
                    LiveSite.init({
                        id: "WI-YCUCI7LP9UPDP7ZMMF4H",
                    });
                };
                (function(d, s, id) {
                    var js,
                        fjs = d.getElementsByTagName(s)[0],
                        p = "https://",
                        r = Math.floor(new Date().getTime() / 1000000);
                    if (d.getElementById(id)) {
                        return;
                    }
                    js = d.createElement(s);
                    js.id = id;
                    js.src = p + "d2ra6nuwn69ktl.cloudfront.net/assets/livesite.js?" + r;
                    fjs.parentNode.insertBefore(js, fjs);
                })(document, "script", "livesite-jssdk");
            }
        }
    } else {
        window.liveSiteAsyncInit = function() {
            LiveSite.init({
                id: "WI-YCUCI7LP9UPDP7ZMMF4H",
            });
        };
        (function(d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0],
                p = "https://",
                r = Math.floor(new Date().getTime() / 1000000);
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = p + "d2ra6nuwn69ktl.cloudfront.net/assets/livesite.js?" + r;
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "livesite-jssdk");
    }
}