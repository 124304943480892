/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonAlert, IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Core CSS required for Bootstrap components to work properly */
/* import "bootstrap/dist/css/bootstrap.min.css"; */

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Fonts */
import "./theme/global.scss";
/* Theme variables */
import "./theme/variables.css";
/* Icons */
//import { logIn, personCircle, information } from "ionicons/icons";
/* Components */
//import Menu from "./components/Menu";
//import EditOptions from "./components/EditOptions";
//import EditProfileDetails from "./components/EditProfileDetails";
//import ChangePassword from "./components/ChangePassword";
//import ChangeProfilePicture from "./components/ChangeProfilePicture";
/* Pages */
import Home from "./pages/Home";
import Team from "./pages/Team";
import Gallery from "./pages/Gallery";
import GalleryView from "./pages/GalleryView";
import HealthCareProfessionalResources from "./pages/HealthCareProfessionalResources";
import ValuedCareProviders from "./pages/ValuedCareProviders";
import CaseManagement from "./pages/CaseManagement";
import HelpfulInformation from "./pages/HelpfulInformation";
import FAQs from "./pages/FAQs";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import VCITAPrivacyPolicy from "./pages/VCITAPrivacyPolicy";
//import Logout from "./pages/LogOut";
import Login from "./pages/Login";
import RegisterProvider from "./pages/RegisterProvider";
import ActivateAccount from "./pages/ActivateAccount";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import Profile from "./pages/Profile";
/* Actions */
import { invokeLogout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { setPlayer } from "./actions/player";
/* Services */
import AuthService from "./services/auth.service";
import UserService from "./services/user.service";
/* Others */
import { history } from "./helpers/history";
import axios from "axios";
import CookieConsent, { Cookies } from "react-cookie-consent";
import "./others/vcita.js";
//import Splashr from "splashr";
//import { BrowserView, MobileView } from "react-device-detect";

const App: React.FC = () => {
  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  /* const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [showModeratorBoard, setShowModeratorBoard] = useState<boolean>(false);
  const [showAdminBoard, setShowAdminBoard] = useState<boolean>(false);
  const [countcheck, setCountcheck] = useState(0);
  const [iserror, setIserror] = useState<boolean>(false);
  const [messagelocal, setMessagelocal] = useState<string>("");
  const dispatch = useDispatch();

  localStorage.setItem("mselected", "1");
  localStorage.setItem("msubselected", "0");
  localStorage.setItem("mfooterselected", "1");
  localStorage.removeItem("communitycontactrequest");

  dispatch(setPlayer(false));

  useEffect(() => {
    async function trackhistory() {
      const res = await axios.get("https://geolocation-db.com/json/");
      AuthService.trackvisitors(
        res.data.country_code,
        res.data.country_name,
        res.data.city,
        res.data.postal,
        res.data.latitude,
        res.data.longitude,
        res.data.IPv4,
        res.data.state
      );
    }
    trackhistory();
  }, []);

  useEffect(() => {
    var timeout: NodeJS.Timeout;
    timeout = setTimeout(() => {
      const platform: any = localStorage.getItem("platform")
        ? localStorage.getItem("platform")
        : "";

      setPlatformusedglobal(platform);

      const isnative: any = localStorage.getItem("isnative")
        ? localStorage.getItem("isnative")
        : "";

      setIsnativeusedglobal(isnative);

      if (isnative && isnative === "true") {
        if (!localStorage.getItem("refreshtocheckplatform")) {
          localStorage.setItem("refreshtocheckplatform", "true");
          window.location.reload();
        }
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);

    const isnative: any = localStorage.getItem("isnative")
      ? localStorage.getItem("isnative")
      : "";

    setIsnativeusedglobal(isnative);

    /* const cookiesused: any = localStorage.getItem("cookiesused")
      ? localStorage.getItem("cookiesused")
      : ""; */

    if (isnative && isnative === "true") {
      if (!localStorage.getItem("refreshtocheckplatform")) {
        localStorage.setItem("refreshtocheckplatform", "true");
        window.location.reload();
      }
    }

    /* if (cookiesused && cookiesused !== "") {
      setCookiesused(true);
    } */
  }, [localStorage.getItem("isnative")]);

  useEffect(() => {
    history.listen((location: any) => {
      /* if (
        location.pathname === "/viewplayer/1" ||
        location.pathname === "/viewplayer/2" ||
        location.pathname === "/viewplayer/3" ||
        location.pathname === "/viewplayer/4" ||
        location.pathname === "/viewplayer/5"
      ) {
        //this wil be replaced by player container
        dispatch(setPlayer(true));
      } else { */
      dispatch(setPlayer(false));
      /* } */
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    }
  }, [currentUser]);

  useEffect(() => {
    var timeout: NodeJS.Timeout;
    timeout = setTimeout(() => {
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user") || "{}")
        : null;

      if (user) {
        checkJWTvalidity();
      }

      setCountcheck(countcheck + 1);
    }, 300000);
    return () => clearTimeout(timeout);
  }, [countcheck]);

  const checkJWTvalidity = () => {
    UserService.getJWT().catch(() => {
      AuthService.logout();
      dispatch(invokeLogout());
      setMessagelocal(
        "Your session is expired. Please login again to continue."
      );
      setIserror(true);
    });
  };

  return (
    <IonApp>
      <IonAlert
        isOpen={iserror}
        onDidDismiss={() => {
          setIserror(false);
          history.push("/login");
          window.location.reload();
        }}
        cssClass="ionbackgroundclass"
        header={"Session Timeout!"}
        message={messagelocal}
        buttons={["Dismiss"]}
      />
      <IonReactRouter history={history}>
        <IonSplitPane contentId="main" when="true">
          {/* <Menu /> */}
          <IonRouterOutlet id="main">
            <Route path="/home" component={Home} exact />
            <Route path="/team" component={Team} exact />
            <Route path="/gallery/:carehome" component={Gallery} exact />
            <Route
              path="/galleryview/:carehomeid"
              component={GalleryView}
              exact
            />
            <Route
              path="/healthcareprofessionalresources"
              component={HealthCareProfessionalResources}
              exact
            />
            <Route
              path="/valuedcareproviders"
              component={ValuedCareProviders}
              exact
            />
            <Route path="/casemanagement" component={CaseManagement} exact />
            <Route
              path="/helpfulinformation"
              component={HelpfulInformation}
              exact
            />
            <Route path="/faqs" component={FAQs} exact />
            <Route path="/termsofservice" component={TermsOfService} exact />
            <Route path="/privacypolicy" component={PrivacyPolicy} exact />
            <Route
              path="/vcitaprivacypolicy"
              component={VCITAPrivacyPolicy}
              exact
            />
            <Route path="/login" component={Login} exact />
            <Route
              path="/registerprovider"
              component={RegisterProvider}
              exact
            />
            {/* <Route path="/logout" component={Logout} exact /> */}
            <Route path="/profile" component={Profile} exact />
            {/* <Route path="/editoptions" component={EditOptions} exact />
            <Route
              path="/editprofiledetails"
              component={EditProfileDetails}
              exact
            />
            <Route path="/changepassword" component={ChangePassword} exact />
            <Route
              path="/changeprofilepicture"
              component={ChangeProfilePicture}
              exact
            /> */}
            <Route
              path="/profile/:activationresult"
              component={Profile}
              exact
            />
            <Route
              path="/activateaccount/:activationcode"
              component={ActivateAccount}
              exact
            />
            <Route
              path="/resetpassword/:resetpasswordcode"
              component={ResetPassword}
              exact
            />
            <Route path="/forgotpassword" component={ForgotPassword} exact />
            <Redirect from="/" to="/home" exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
      {(!localStorage.getItem("isnative") ||
        localStorage.getItem("isnative") === "") && (
        <CookieConsent
          buttonText="Yes, I like cookies!"
          declineButtonText="No cookies for me."
          cookieName="user-has-accepted-cookies"
          containerClasses="cookiecustomize"
          contentClasses="cookiecontentcustomize"
          style={{
            display: "block",
            alignItems: "right",
            fontFamily: "Abadi MT Std",
            width: "300px",
            zIndex: "1000",
          }}
          contentStyle={{
            textAlign: "justify",
            fontFamily: "Abadi MT Std",
            fontSize: "16px",
            margin: "0px",
            marginTop: "12px",
            width: "100%",
          }}
          buttonStyle={{
            fontFamily: "Abadi MT Std",
            background: "#ffcc00",
            fontSize: "13px",
            borderRadius: "4px",
            width: "106px",
            marginLeft: "7.5px",
            marginRight: "7.5px",
            float: "right",
          }}
          declineButtonStyle={{
            fontFamily: "Abadi MT Std",
            background: "red",
            fontSize: "13px",
            borderRadius: "4px",
            width: "90px",
            marginLeft: "7.5px",
            marginRight: "15px",
            float: "right",
          }}
          expires={7}
          enableDeclineButton
        >
          <div
            className="container"
            style={{
              width: "100%",
              fontFamily: "Abadi MT Std",
              textAlign: "center",
            }}
          >
            This website uses cookies to ensure you get the best experience on
            our website.
          </div>
        </CookieConsent>
      )}
    </IonApp>
  );
};

export default App;
