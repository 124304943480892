/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import {
  IonAlert,
  IonContent,
  IonLoading,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonCard,
  IonCardContent,
  IonLabel,
} from "@ionic/react";
/* Components */
/* import NavBar from "../components/NavBar";
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile"; */
import MapsUser from "../components/MapsUser";
/* Theme variables */
import "./Profile.css";
import "../theme/ImageUploader/index.scss";
import "../theme/ImageUploader/App.scss";
import "../theme/ImageUploader/Spinner.scss";
import "../theme/ImageUploader/Status.scss";
import "../theme/ImageUploader/Uploader.scss";
/* Icons */
import {
  settings,
  personCircle,
  home,
  key,
  map,
  pricetag,
  reader,
  image,
  wallet,
  checkmarkOutline,
  book,
} from "ionicons/icons"; /* Others */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
/* Actions */
import { invokeLogout } from "../actions/auth";
import { loginSuccess } from "../actions/auth";
import { setMessage } from "../actions/message";
/* Services */
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import UrlService from "../services/url.service";
/* Others */
import { history } from "../helpers/history";
import Spinner from "../others/spinner";
import axios from "axios";
//import debounce from "debounce";
import { isEmail } from "validator";

const eyeshown = <FontAwesomeIcon icon={faEye} />;
const eyehidden = <FontAwesomeIcon icon={faEyeSlash} />;

function validatePassword(password: string) {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*^()_-`~{}|]).{8,}/;
  return re.test(password);
}

const urlaxios = UrlService.API_URL + "auth/uploadprofilepicture";
const urlaxiosfirstupload =
  UrlService.API_URL + "auth/uploadprofilepicturefirstupload";

const Profile: React.FC = () => {
  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  /* const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [showModeratorBoard, setShowModeratorBoard] = useState<boolean>(false);
  const [showAdminBoard, setShowAdminBoard] = useState<boolean>(false);

  const [showprofile, setShowprofile] = useState<boolean>(true);
  const [showchangepassword, setShowchangepassword] = useState<boolean>(false);
  const [showservices, setShowservices] = useState<boolean>(false);
  const [showlisting, setShowlisting] = useState<boolean>(false);
  const [showmap, setShowmap] = useState<boolean>(false);
  const [showimages, setShowimages] = useState<boolean>(false);
  const [showvcita, setShowvcita] = useState<boolean>(false);
  const [showforpayment, setShowforpayment] = useState<boolean>(false);
  const [showforapproval, setShowforapproval] = useState<boolean>(false);
  const [showapprovedproviderlist, setShowapprovedproviderlist] = useState<
    boolean
  >(false);
  const [showadminlistview, setShowadminlistview] = useState<boolean>(false);

  //const [datavalue, setDatavalue] = useState<any>([]);
  const [datavalue1, setDatavalue1] = useState<any>([]);
  //const [datavalue1_2, setDatavalue1_2] = useState<any>([]);
  const [datavalue2, setDatavalue2] = useState<any>([]);
  const [datavalue3, setDatavalue3] = useState<any>([]);

  const [showloading, setShowloading] = useState<boolean>(false);
  const [showmodal, setShowmodal] = useState<boolean>(false);
  const [showloadingmodifyline, setShowloadingmodifyline] = useState<boolean>(
    false
  );
  const [showloadingLogout, setShowloadingLogout] = useState<boolean>(false);
  const [
    showloadingfilterforpayment,
    setShowloadingfilterforpayment,
  ] = useState<boolean>(false);

  const [listcity, setListcity] = useState<any>([]);
  const [liststate, setListstate] = useState<any>([]);
  const [listzipcode, setListzipcode] = useState<any>([]);
  const [listmonths, setListmonths] = useState<any>([]);
  const [listdays, setListdays] = useState<any>([]);
  const [listlicense, setListlicense] = useState<any>([]);

  const [providerid, setProviderid] = useState<string>("");
  const [providerbusinessname, setProviderbusinessname] = useState<string>("");
  const [providerbusinessaddress, setProviderbusinessaddress] = useState<
    string
  >("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");
  const [citylocationlat, setCitylocationlat] = useState<string>("0.0");
  const [citylocationlng, setCitylocationlng] = useState<string>("0.0");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [contactno1, setContactno1] = useState<string>("");
  const [birthday, setBirthday] = useState<number>(0);
  const [birthmonth, setBirthmonth] = useState<number>(0);
  const [birthdayequiv, setBirthdayequiv] = useState<string>("");
  const [birthmonthequiv, setBirthmonthequiv] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [licensedtooperate, setLicensedtooperate] = useState<boolean>(false);

  const [active, setActive] = useState<boolean>(false);
  const [subscribedprovider, setSubscribedprovider] = useState<boolean>(false);
  const [approvedprovider, setApprovedprovider] = useState<boolean>(false);
  const [pausedprovider, setPausedprovider] = useState<boolean>(false);
  const [paidtypeannually, setPaidtypeannually] = useState<boolean>(false);

  const [opassword, setOpassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cpassword, setCpassword] = useState<string>("");
  const [showopassword, setShowopassword] = useState<boolean>(false);
  const [showpassword, setShowpassword] = useState<boolean>(false);
  const [showcpassword, setShowcpassword] = useState<boolean>(false);

  const [servicetype1, setServicetype1] = useState<boolean>(false);
  const [servicetype2, setServicetype2] = useState<boolean>(false);
  const [servicetype3, setServicetype3] = useState<boolean>(false);
  const [servicetype1availability, setServicetype1availability] = useState<
    boolean
  >(false);
  const [servicetype2availability, setServicetype2availability] = useState<
    boolean
  >(false);
  const [servicetype3availability, setServicetype3availability] = useState<
    boolean
  >(false);

  const [selectedlistingcategory, setSelectedlistingcategory] = useState<
    string
  >("");
  const [listingcategory1, setListingcategory1] = useState<any>([]);
  const [listingcategory2, setListingcategory2] = useState<any>([]);
  const [listingcategory3, setListingcategory3] = useState<any>([]);
  //const [listingcategory, setListingcategory] = useState<any>([]);

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fileName, setFileName] = useState<any>(null);
  const [preview, setPreview] = useState<any>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>(
    "Select your file first"
  );
  const [imagelink, setImagelink] = useState<string>("");
  const [imagetoken, setImagetoken] = useState<string>("");

  const [disabledbasicinfo, setDisabledbasicinfo] = useState<boolean>(true);
  const [disabledchangepassword, setDisabledchangepassword] = useState<boolean>(
    true
  );
  const [disabledservicetype, setDisabledservicetype] = useState<boolean>(true);
  const [disableddetailslisting, setDisableddetailslisting] = useState<boolean>(
    true
  );
  const [disabledmap, setDisabledmap] = useState<boolean>(true);

  const [button1namebasicinfo, setButton1namebasicinfo] = useState<string>(
    "Edit"
  );
  const [button1namechangepassword, setButton1namechangepassword] = useState<
    string
  >("Edit");
  const [button1nameservicetype, setButton1nameservicetype] = useState<string>(
    "Edit"
  );
  const [button1namedetailslisting, setButton1namedetailslisting] = useState<
    string
  >("Edit");
  const [button1namemap, setButton1namemap] = useState<string>("Edit");

  const [iserror, setIserror] = useState<boolean>(false);
  const [issaved, setIssaved] = useState<boolean>(false);
  const [headerlocal, setHeaderlocal] = useState<string>("");
  const [messagelocal, setMessagelocal] = useState<string>("");
  const [iswarning, setIswarning] = useState<boolean>(false);
  const [iswarning2, setIswarning2] = useState<boolean>(false);
  const [iswarning3, setIswarning3] = useState<boolean>(false);

  const inputRefshowprofile = useRef<any>(null);
  const inputRefshowchangepassword = useRef<any>(null);
  const inputRefshowservices = useRef<any>(null);
  const inputRefshowlisting = useRef<any>(null);
  const inputRefshowmap = useRef<any>(null);
  const inputRefshowimages = useRef<any>(null);
  const inputRefshowvcita = useRef<any>(null);
  const inputRefshowforpayment = useRef<any>(null);
  const inputRefshowforapproval = useRef<any>(null);
  const inputRefshowapprovedproviderlist = useRef<any>(null);

  const inputRefBasicInfo = useRef<any>(null);
  const inputRefChangePassword = useRef<any>(null);
  const inputRefCity = useRef<any>(null);
  const inputRefState = useRef<any>(null);
  const inputRefZipCode = useRef<any>(null);
  const inputRefMonths = useRef<any>(null);
  const inputRefDays = useRef<any>(null);
  const inputRefLicense = useRef<any>(null);

  const [indexmodifyline, setIndexmodifyline] = useState<number>(0);
  const [modemodifyline, setModemodifyline] = useState<boolean>(false);
  const [selecteduseruniquenumber, setSelecteduseruniquenumber] = useState<
    string
  >("");
  const [selectedoption1, setSelectedoption1] = useState<boolean>(false);
  const [selectedoption2, setSelectedoption2] = useState<boolean>(false);
  //const [width, setWidth] = useState<number>(0);
  //const inputRefwidth = useRef<any>(null);

  const [galleryuseruniquenumber, setGalleryuseruniquenumber] = useState<
    string
  >("");
  const [galleryimage, setGalleryimage] = useState<string>("");
  const [galleryimagetoken, setGalleryimagetoken] = useState<string>("");
  const [galleryviewname, setGalleryviewname] = useState<string>("");
  const [gallerydescription, setGallerydescription] = useState<string>("");
  const [galleryaddress, setGalleryaddress] = useState<string>("");
  const [galleryzipcode, setGalleryzipcode] = useState<string>("");
  const [gallerycontactnumber1, setGallerycontactnumber1] = useState<string>(
    ""
  );
  const [gallerycontactnumber2, setGallerycontactnumber2] = useState<string>(
    ""
  );
  const [galleryemail, setGalleryemail] = useState<string>("");
  const [gallerycity, setGallerycity] = useState<string>("");
  const [gallerystate, setGallerystate] = useState<string>("");
  const [gallerysection1, setGallerysection1] = useState<any>([]);
  const [gallerysection2, setGallerysection2] = useState<any>([]);
  const [gallerysection3, setGallerysection3] = useState<any>([]);

  const [disabledforpaymentbutton1, setDisabledforpaymentbutton1] = useState<
    boolean
  >(false);
  const [disabledforpaymentbutton2, setDisabledforpaymentbutton2] = useState<
    boolean
  >(true);
  const [disabledforpaymentbutton3, setDisabledforpaymentbutton3] = useState<
    boolean
  >(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);

    const isnative: any = localStorage.getItem("isnative")
      ? localStorage.getItem("isnative")
      : "";

    setIsnativeusedglobal(isnative);

    window.addEventListener("message", (event) => {
      if (event.data && event.data.sender === "links") {
        localStorage.setItem("links", event.data.message);
        window.location.reload();
      }
    });
  }, []);

  /* useEffect(() => {
    const updateDimensions = debounce(() => {
      if (inputRefwidth.current) {
        setWidth(inputRefwidth.current.clientWidth);
        localStorage.setItem("width", inputRefwidth.current.clientWidth);
      }
    }, 50);

    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []); */

  useEffect(() => {
    if (inputRefshowprofile.current) {
      if (showprofile) {
        inputRefshowprofile.current.style.backgroundColor = "#ffe685";
      } else {
        inputRefshowprofile.current.style.backgroundColor = "white";
      }
    }
  }, [showprofile]);

  useEffect(() => {
    if (inputRefshowchangepassword.current) {
      if (showchangepassword) {
        inputRefshowchangepassword.current.style.backgroundColor = "#ffe685";
      } else {
        inputRefshowchangepassword.current.style.backgroundColor = "white";
      }
    }
  }, [showchangepassword]);

  useEffect(() => {
    if (inputRefshowservices.current) {
      if (showservices) {
        inputRefshowservices.current.style.backgroundColor = "#ffe685";
      } else {
        inputRefshowservices.current.style.backgroundColor = "white";
      }
    }
  }, [showservices]);

  useEffect(() => {
    if (inputRefshowlisting.current) {
      if (showlisting) {
        inputRefshowlisting.current.style.backgroundColor = "#ffe685";
      } else {
        inputRefshowlisting.current.style.backgroundColor = "white";
      }
    }
  }, [showlisting]);

  useEffect(() => {
    if (inputRefshowmap.current) {
      if (showmap) {
        inputRefshowmap.current.style.backgroundColor = "#ffe685";
      } else {
        inputRefshowmap.current.style.backgroundColor = "white";
      }
    }
  }, [showmap]);

  useEffect(() => {
    if (inputRefshowimages.current) {
      if (showimages) {
        inputRefshowimages.current.style.backgroundColor = "#ffe685";
      } else {
        inputRefshowimages.current.style.backgroundColor = "white";
      }
    }
  }, [showimages]);

  useEffect(() => {
    if (inputRefshowvcita.current) {
      if (showvcita) {
        inputRefshowvcita.current.style.backgroundColor = "#ffe685";
      } else {
        inputRefshowvcita.current.style.backgroundColor = "white";
      }
    }
  }, [showvcita]);

  useEffect(() => {
    if (inputRefshowforpayment.current) {
      if (showforpayment) {
        inputRefshowforpayment.current.style.backgroundColor = "#ffe685";
      } else {
        inputRefshowforpayment.current.style.backgroundColor = "white";
      }
    }
  }, [showforpayment]);

  useEffect(() => {
    if (inputRefshowforapproval.current) {
      if (showforapproval) {
        inputRefshowforapproval.current.style.backgroundColor = "#ffe685";
      } else {
        inputRefshowforapproval.current.style.backgroundColor = "white";
      }
    }
  }, [showforapproval]);

  useEffect(() => {
    if (inputRefshowapprovedproviderlist.current) {
      if (showapprovedproviderlist) {
        inputRefshowapprovedproviderlist.current.style.backgroundColor =
          "#ffe685";
      } else {
        inputRefshowapprovedproviderlist.current.style.backgroundColor =
          "white";
      }
    }
  }, [showapprovedproviderlist]);

  useEffect(() => {
    var inputraw1: any = [];
    inputraw1.push("Aiea");
    inputraw1.push("Kapolei");
    inputraw1.push("Ewa Beach");
    inputraw1.push("Haleiwa");
    inputraw1.push("Kailua");
    inputraw1.push("Kaneohe");
    inputraw1.push("Pearl City");
    inputraw1.push("Wahiawa");
    inputraw1.push("Mililani");
    inputraw1.push("Waialua");
    inputraw1.push("Waianae");
    inputraw1.push("Waipahu");
    inputraw1.push("Hauula");
    inputraw1.push("Honolulu");
    setListcity(inputraw1);
    var inputraw2: any = [];
    inputraw2.push("Hawaii");
    setListstate(inputraw2);
    var inputraw3: any = [];
    inputraw3.push("Jan");
    inputraw3.push("Feb");
    inputraw3.push("Mar");
    inputraw3.push("Apr");
    inputraw3.push("May");
    inputraw3.push("Jun");
    inputraw3.push("Jul");
    inputraw3.push("Aug");
    inputraw3.push("Sep");
    inputraw3.push("Oct");
    inputraw3.push("Nov");
    inputraw3.push("Dec");
    setListmonths(inputraw3);
    var inputraw4: any = [];
    inputraw4.push("Yes");
    inputraw4.push("No");
    setListlicense(inputraw4);
  }, []);

  useEffect(() => {
    var inputraw1: any = [];
    if (city === "Aiea") {
      inputraw1.push("96701");
      setListzipcode(inputraw1);
      setZipcode("96701");
    } else if (city === "Kapolei") {
      inputraw1.push("96707");
      setListzipcode(inputraw1);
      setZipcode("96707");
    } else if (city === "Ewa Beach") {
      inputraw1.push("96706");
      setListzipcode(inputraw1);
      setZipcode("96706");
    } else if (city === "Haleiwa") {
      inputraw1.push("96712");
      setListzipcode(inputraw1);
      setZipcode("96712");
    } else if (city === "Kailua") {
      inputraw1.push("96734");
      setListzipcode(inputraw1);
      setZipcode("96734");
    } else if (city === "Kaneohe") {
      inputraw1.push("96744");
      setListzipcode(inputraw1);
      setZipcode("96744");
    } else if (city === "Pearl City") {
      inputraw1.push("96782");
      setListzipcode(inputraw1);
      setZipcode("96782");
    } else if (city === "Wahiawa") {
      inputraw1.push("96786");
      setListzipcode(inputraw1);
      setZipcode("96786");
    } else if (city === "Mililani") {
      inputraw1.push("96789");
      setListzipcode(inputraw1);
      setZipcode("96789");
    } else if (city === "Waialua") {
      inputraw1.push("96791");
      setListzipcode(inputraw1);
      setZipcode("96791");
    } else if (city === "Waianae") {
      inputraw1.push("96792");
      setListzipcode(inputraw1);
      setZipcode("96792");
    } else if (city === "Waipahu") {
      inputraw1.push("96797");
      setListzipcode(inputraw1);
      setZipcode("96797");
    } else if (city === "Hauula") {
      inputraw1.push("96717");
      setListzipcode(inputraw1);
      setZipcode("96717");
    } else if (city === "Honolulu") {
      inputraw1.push("96813");
      inputraw1.push("96814");
      inputraw1.push("96815");
      inputraw1.push("96816");
      inputraw1.push("96817");
      inputraw1.push("96818");
      inputraw1.push("96819");
      inputraw1.push("96821");
      inputraw1.push("96822");
      inputraw1.push("96825");
      setListzipcode(inputraw1);
      setZipcode("96813");
    }
  }, [city]);

  useEffect(() => {
    if (zipcode.includes("96701")) {
      setCitylocationlat("21.3901330316975");
      setCitylocationlng("-157.926661627854");
    } else if (zipcode.includes("96707")) {
      setCitylocationlat("21.3361663430404");
      setCitylocationlng("-158.056815484123");
    } else if (zipcode.includes("96706")) {
      setCitylocationlat("21.3421951461452");
      setCitylocationlng("-158.011819259209");
    } else if (zipcode.includes("96712")) {
      setCitylocationlat("21.5942629880248");
      setCitylocationlng("-158.103135512785");
    } else if (zipcode.includes("96734")) {
      setCitylocationlat("21.4000859054047");
      setCitylocationlng("-157.750164294097");
    } else if (zipcode.includes("96744")) {
      setCitylocationlat("21.4013537781007");
      setCitylocationlng("-157.797605507742");
    } else if (zipcode.includes("96782")) {
      setCitylocationlat("21.3990025014531");
      setCitylocationlng("-157.973140279599");
    } else if (zipcode.includes("96786")) {
      setCitylocationlat("21.5026021494474");
      setCitylocationlng("-158.021365107468");
    } else if (zipcode.includes("96789")) {
      setCitylocationlat("21.4521015491935");
      setCitylocationlng("-158.014980906438");
    } else if (zipcode.includes("96791")) {
      setCitylocationlat("21.5774248988967");
      setCitylocationlng("-158.130252473122");
    } else if (zipcode.includes("96792")) {
      setCitylocationlat("21.4378768405390");
      setCitylocationlng("-158.185027676834");
    } else if (zipcode.includes("96797")) {
      setCitylocationlat("21.3872672689067");
      setCitylocationlng("-158.009093147885");
    } else if (zipcode.includes("96717")) {
      setCitylocationlat("21.5731871152107");
      setCitylocationlng("-157.892419896975");
    } else if (zipcode.includes("96813")) {
      setCitylocationlat("21.3152627975776");
      setCitylocationlng("-157.848074237621");
    } else if (zipcode.includes("96814")) {
      setCitylocationlat("21.2972788449607");
      setCitylocationlng("-157.848084510546");
    } else if (zipcode.includes("96815")) {
      setCitylocationlat("21.2715807316443");
      setCitylocationlng("-157.818753098099");
    } else if (zipcode.includes("96816")) {
      setCitylocationlat("21.2898930897850");
      setCitylocationlng("-157.798395455224");
    } else if (zipcode.includes("96817")) {
      setCitylocationlat("21.3471800391181");
      setCitylocationlng("-157.839632225116");
    } else if (zipcode.includes("96818")) {
      setCitylocationlat("21.3513354648898");
      setCitylocationlng("-157.958711203439");
    } else if (zipcode.includes("96819")) {
      setCitylocationlat("21.3676475588442");
      setCitylocationlng("-157.868523344520");
    } else if (zipcode.includes("96821")) {
      setCitylocationlat("21.2843957862203");
      setCitylocationlng("-157.735730798779");
    } else if (zipcode.includes("96822")) {
      setCitylocationlat("21.3192614346513");
      setCitylocationlng("-157.815664674414");
    } else if (zipcode.includes("96825")) {
      setCitylocationlat("21.2979684324660");
      setCitylocationlng("-157.702186578809");
    }
  }, [zipcode]);

  useEffect(() => {
    var inputraw1: any = [];
    if (birthmonthequiv !== "") {
      inputraw1.push("01");
      inputraw1.push("02");
      inputraw1.push("03");
      inputraw1.push("04");
      inputraw1.push("05");
      inputraw1.push("06");
      inputraw1.push("07");
      inputraw1.push("08");
      inputraw1.push("09");
      inputraw1.push("10");
      inputraw1.push("11");
      inputraw1.push("12");
      inputraw1.push("13");
      inputraw1.push("14");
      inputraw1.push("15");
      inputraw1.push("16");
      inputraw1.push("17");
      inputraw1.push("18");
      inputraw1.push("19");
      inputraw1.push("20");
      inputraw1.push("21");
      inputraw1.push("22");
      inputraw1.push("23");
      inputraw1.push("24");
      inputraw1.push("25");
      inputraw1.push("26");
      inputraw1.push("27");
      inputraw1.push("28");
    }
    if (birthmonthequiv === "Jan") {
      inputraw1.push("29");
      inputraw1.push("30");
      inputraw1.push("31");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(1);
    } else if (birthmonthequiv === "Feb") {
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(2);
    } else if (birthmonthequiv === "Mar") {
      inputraw1.push("29");
      inputraw1.push("30");
      inputraw1.push("31");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(3);
    } else if (birthmonthequiv === "Apr") {
      inputraw1.push("29");
      inputraw1.push("30");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(4);
    } else if (birthmonthequiv === "May") {
      inputraw1.push("29");
      inputraw1.push("30");
      inputraw1.push("31");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(5);
    } else if (birthmonthequiv === "Jun") {
      inputraw1.push("29");
      inputraw1.push("30");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(6);
    } else if (birthmonthequiv === "Jul") {
      inputraw1.push("29");
      inputraw1.push("30");
      inputraw1.push("31");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(7);
    } else if (birthmonthequiv === "Aug") {
      inputraw1.push("29");
      inputraw1.push("30");
      inputraw1.push("31");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(8);
    } else if (birthmonthequiv === "Sep") {
      inputraw1.push("29");
      inputraw1.push("30");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(9);
    } else if (birthmonthequiv === "Oct") {
      inputraw1.push("29");
      inputraw1.push("30");
      inputraw1.push("31");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(10);
    } else if (birthmonthequiv === "Nov") {
      inputraw1.push("29");
      inputraw1.push("30");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(11);
    } else if (birthmonthequiv === "Dec") {
      inputraw1.push("29");
      inputraw1.push("30");
      inputraw1.push("31");
      setListdays(inputraw1);
      setBirthdayequiv("01");
      setBirthmonth(12);
    }
  }, [birthmonthequiv]);

  useEffect(() => {
    if (birthdayequiv === "01") {
      setBirthday(1);
    } else if (birthdayequiv === "02") {
      setBirthday(2);
    } else if (birthdayequiv === "03") {
      setBirthday(3);
    } else if (birthdayequiv === "04") {
      setBirthday(4);
    } else if (birthdayequiv === "05") {
      setBirthday(5);
    } else if (birthdayequiv === "06") {
      setBirthday(6);
    } else if (birthdayequiv === "07") {
      setBirthday(7);
    } else if (birthdayequiv === "08") {
      setBirthday(8);
    } else if (birthdayequiv === "09") {
      setBirthday(9);
    } else if (birthdayequiv === "10") {
      setBirthday(10);
    } else if (birthdayequiv === "11") {
      setBirthday(11);
    } else if (birthdayequiv === "12") {
      setBirthday(12);
    } else if (birthdayequiv === "13") {
      setBirthday(13);
    } else if (birthdayequiv === "14") {
      setBirthday(14);
    } else if (birthdayequiv === "15") {
      setBirthday(15);
    } else if (birthdayequiv === "16") {
      setBirthday(16);
    } else if (birthdayequiv === "17") {
      setBirthday(17);
    } else if (birthdayequiv === "18") {
      setBirthday(18);
    } else if (birthdayequiv === "19") {
      setBirthday(19);
    } else if (birthdayequiv === "20") {
      setBirthday(20);
    } else if (birthdayequiv === "21") {
      setBirthday(21);
    } else if (birthdayequiv === "22") {
      setBirthday(22);
    } else if (birthdayequiv === "23") {
      setBirthday(23);
    } else if (birthdayequiv === "24") {
      setBirthday(24);
    } else if (birthdayequiv === "25") {
      setBirthday(25);
    } else if (birthdayequiv === "26") {
      setBirthday(26);
    } else if (birthdayequiv === "27") {
      setBirthday(27);
    } else if (birthdayequiv === "28") {
      setBirthday(28);
    } else if (birthdayequiv === "29") {
      setBirthday(29);
    } else if (birthdayequiv === "30") {
      setBirthday(30);
    } else if (birthdayequiv === "31") {
      setBirthday(31);
    }
  }, [birthdayequiv]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    }
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      history.push("/home");
    } else {
      if (currentUser.id && currentUser.id !== "") {
        setProviderid(currentUser.id);
      } else {
        setProviderid("N/A");
      }
      if (
        currentUser.providerbusinessname &&
        currentUser.providerbusinessname !== ""
      ) {
        setProviderbusinessname(currentUser.providerbusinessname);
      } else {
        setProviderbusinessname("N/A");
      }
      if (
        currentUser.providerbusinessaddress &&
        currentUser.providerbusinessaddress !== ""
      ) {
        setProviderbusinessaddress(currentUser.providerbusinessaddress);
      } else {
        setProviderbusinessaddress("N/A");
      }
      if (currentUser.city && currentUser.city !== "") {
        setCity(currentUser.city);
      } else {
        setCity("N/A");
      }
      if (currentUser.state && currentUser.state !== "") {
        setState(currentUser.state);
      } else {
        setState("N/A");
      }
      if (currentUser.zipcode && currentUser.zipcode !== "") {
        setZipcode(currentUser.zipcode);
      } else {
        setZipcode("N/A");
      }
      if (currentUser.citylocationlat && currentUser.citylocationlat !== "") {
        setCitylocationlat(currentUser.citylocationlat);
      } else {
        setCitylocationlat("0.0");
      }
      if (currentUser.citylocationlng && currentUser.citylocationlng !== "") {
        setCitylocationlng(currentUser.citylocationlng);
      } else {
        setCitylocationlng("0.0");
      }
      if (currentUser.firstname && currentUser.firstname !== "") {
        setFirstname(currentUser.firstname);
      } else {
        setFirstname("N/A");
      }
      if (currentUser.lastname && currentUser.lastname !== "") {
        setLastname(currentUser.lastname);
      } else {
        setLastname("N/A");
      }
      if (currentUser.contactno1 && currentUser.contactno1 !== "") {
        setContactno1(currentUser.contactno1);
      } else {
        setContactno1("N/A");
      }
      if (currentUser.birthday && currentUser.birthday !== 0) {
        setBirthday(currentUser.birthday);
        setBirthdayequiv(currentUser.birthday);
      } else {
        setBirthday(0);
        if (currentUser.birthday === 0) {
          setBirthdayequiv("N/A");
        }
      }
      if (currentUser.birthmonth && currentUser.birthmonth !== 0) {
        setBirthmonth(currentUser.birthmonth);
        if (currentUser.birthmonth === 1) {
          setBirthmonthequiv("Jan");
        } else if (currentUser.birthmonth === 2) {
          setBirthmonthequiv("Feb");
        } else if (currentUser.birthmonth === 3) {
          setBirthmonthequiv("Mar");
        } else if (currentUser.birthmonth === 4) {
          setBirthmonthequiv("Apr");
        } else if (currentUser.birthmonth === 5) {
          setBirthmonthequiv("May");
        } else if (currentUser.birthmonth === 6) {
          setBirthmonthequiv("Jun");
        } else if (currentUser.birthmonth === 7) {
          setBirthmonthequiv("Jul");
        } else if (currentUser.birthmonth === 8) {
          setBirthmonthequiv("Aug");
        } else if (currentUser.birthmonth === 9) {
          setBirthmonthequiv("Sep");
        } else if (currentUser.birthmonth === 10) {
          setBirthmonthequiv("Oct");
        } else if (currentUser.birthmonth === 11) {
          setBirthmonthequiv("Nov");
        } else if (currentUser.birthmonth === 12) {
          setBirthmonthequiv("Dec");
        }
      } else {
        setBirthmonth(0);
        if (currentUser.birthmonth === 0) {
          setBirthmonthequiv("N/A");
        }
      }
      if (currentUser.email && currentUser.email !== "") {
        setEmail(currentUser.email);
      } else {
        setEmail("N/A");
      }
      if (currentUser.licensedtooperate) {
        setLicensedtooperate(currentUser.licensedtooperate);
      } else {
        setLicensedtooperate(false);
      }
      if (currentUser.active) {
        setActive(currentUser.active);
      } else {
        setActive(false);
      }
      if (currentUser.approvedprovider) {
        setApprovedprovider(currentUser.approvedprovider);
      } else {
        setApprovedprovider(false);
      }
      if (currentUser.subscribedprovider) {
        setSubscribedprovider(currentUser.subscribedprovider);
      } else {
        setSubscribedprovider(false);
      }
      if (currentUser.pausedprovider) {
        setPausedprovider(currentUser.pausedprovider);
      } else {
        setPausedprovider(false);
      }
      if (currentUser.paidtypeannually) {
        setPaidtypeannually(currentUser.paidtypeannually);
      } else {
        setPaidtypeannually(false);
      }
      //setPaidtypeannually(currentUser.paidtypeannually);

      if (currentUser.servicetype) {
        if (currentUser.servicetype.servicetype1) {
          setServicetype1(currentUser.servicetype.servicetype1);
        } else {
          setServicetype1(false);
        }
        if (currentUser.servicetype.servicetype2) {
          setServicetype2(currentUser.servicetype.servicetype2);
        } else {
          setServicetype2(false);
        }
        if (currentUser.servicetype.servicetype3) {
          setServicetype3(currentUser.servicetype.servicetype3);
        } else {
          setServicetype3(false);
        }
        if (currentUser.servicetype.servicetypeavailability1) {
          setServicetype1availability(
            currentUser.servicetype.servicetypeavailability1
          );
        } else {
          setServicetype1availability(false);
        }
        if (currentUser.servicetype.servicetypeavailability2) {
          setServicetype2availability(
            currentUser.servicetype.servicetypeavailability2
          );
        } else {
          setServicetype2availability(false);
        }
        if (currentUser.servicetype.servicetypeavailability3) {
          setServicetype3availability(
            currentUser.servicetype.servicetypeavailability3
          );
        } else {
          setServicetype3availability(false);
        }
      }

      if (currentUser.providerdetailslistingsection1) {
        var inputraw1: any = [];
        currentUser.providerdetailslistingsection1.map((data: any) => {
          inputraw1.push(data.providerdetailslistingtypedescription);
        });
        setListingcategory1(inputraw1);
      }
      if (currentUser.providerdetailslistingsection2) {
        var inputraw2: any = [];
        currentUser.providerdetailslistingsection2.map((data: any) => {
          inputraw2.push(data.providerdetailslistingtypedescription);
        });
        setListingcategory2(inputraw2);
      }
      if (currentUser.providerdetailslistingsection3) {
        var inputraw3: any = [];
        currentUser.providerdetailslistingsection3.map((data: any) => {
          inputraw3.push(data.providerdetailslistingtypedescription);
        });
        setListingcategory3(inputraw3);
      }
      setSelectedlistingcategory("Room Details");

      if (currentUser.providerimageslinks) {
        setImagelink(currentUser.providerimageslinks[0].providerimagelink);
        setImagetoken(currentUser.providerimageslinks[0].providerimagetoken);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!disabledbasicinfo && inputRefBasicInfo.current) {
      inputRefBasicInfo.current.focus();
    } else if (disabledbasicinfo) {
      if (currentUser.id && currentUser.id !== "") {
        setProviderid(currentUser.id);
      } else {
        setProviderid("N/A");
      }
      if (
        currentUser.providerbusinessname &&
        currentUser.providerbusinessname !== ""
      ) {
        setProviderbusinessname(currentUser.providerbusinessname);
      } else {
        setProviderbusinessname("N/A");
      }
      if (
        currentUser.providerbusinessaddress &&
        currentUser.providerbusinessaddress !== ""
      ) {
        setProviderbusinessaddress(currentUser.providerbusinessaddress);
      } else {
        setProviderbusinessaddress("N/A");
      }
      if (currentUser.city && currentUser.city !== "") {
        setCity(currentUser.city);
      } else {
        setCity("N/A");
      }
      if (currentUser.state && currentUser.state !== "") {
        setState(currentUser.state);
      } else {
        setState("N/A");
      }
      if (currentUser.zipcode && currentUser.zipcode !== "") {
        setZipcode(currentUser.zipcode);
      } else {
        setZipcode("N/A");
      }
      if (currentUser.citylocationlat && currentUser.citylocationlat !== "") {
        setCitylocationlat(currentUser.citylocationlat);
      } else {
        setCitylocationlat("0.0");
      }
      if (currentUser.citylocationlng && currentUser.citylocationlng !== "") {
        setCitylocationlng(currentUser.citylocationlng);
      } else {
        setCitylocationlng("0.0");
      }
      if (currentUser.firstname && currentUser.firstname !== "") {
        setFirstname(currentUser.firstname);
      } else {
        setFirstname("N/A");
      }
      if (currentUser.lastname && currentUser.lastname !== "") {
        setLastname(currentUser.lastname);
      } else {
        setLastname("N/A");
      }
      if (currentUser.contactno1 && currentUser.contactno1 !== "") {
        setContactno1(currentUser.contactno1);
      } else {
        setContactno1("N/A");
      }
      if (currentUser.birthday && currentUser.birthday !== 0) {
        setBirthday(currentUser.birthday);
        setBirthdayequiv(currentUser.birthday);
      } else {
        setBirthday(0);
        if (currentUser.birthday === 0) {
          setBirthdayequiv("N/A");
        }
      }
      if (currentUser.birthmonth && currentUser.birthmonth !== 0) {
        setBirthmonth(currentUser.birthmonth);
        if (currentUser.birthmonth === 1) {
          setBirthmonthequiv("Jan");
        } else if (currentUser.birthmonth === 2) {
          setBirthmonthequiv("Feb");
        } else if (currentUser.birthmonth === 3) {
          setBirthmonthequiv("Mar");
        } else if (currentUser.birthmonth === 4) {
          setBirthmonthequiv("Apr");
        } else if (currentUser.birthmonth === 5) {
          setBirthmonthequiv("May");
        } else if (currentUser.birthmonth === 6) {
          setBirthmonthequiv("Jun");
        } else if (currentUser.birthmonth === 7) {
          setBirthmonthequiv("Jul");
        } else if (currentUser.birthmonth === 8) {
          setBirthmonthequiv("Aug");
        } else if (currentUser.birthmonth === 9) {
          setBirthmonthequiv("Sep");
        } else if (currentUser.birthmonth === 10) {
          setBirthmonthequiv("Oct");
        } else if (currentUser.birthmonth === 11) {
          setBirthmonthequiv("Nov");
        } else if (currentUser.birthmonth === 12) {
          setBirthmonthequiv("Dec");
        }
      } else {
        setBirthmonth(0);
        if (currentUser.birthmonth === 0) {
          setBirthmonthequiv("N/A");
        }
      }
      if (currentUser.email && currentUser.email !== "") {
        setEmail(currentUser.email);
      } else {
        setEmail("N/A");
      }
      if (currentUser.licensedtooperate) {
        setLicensedtooperate(currentUser.licensedtooperate);
      } else {
        setLicensedtooperate(false);
      }
    }
  }, [disabledbasicinfo]);

  useEffect(() => {
    if (!disabledchangepassword && inputRefChangePassword.current) {
      inputRefChangePassword.current.focus();
    } else if (disabledchangepassword) {
      setOpassword("");
      setCpassword("");
      setPassword("");
    }
  }, [disabledchangepassword]);

  useEffect(() => {
    if (disabledservicetype) {
      if (currentUser.servicetype) {
        if (currentUser.servicetype.servicetype1) {
          setServicetype1(currentUser.servicetype.servicetype1);
        } else {
          setServicetype1(false);
        }
        if (currentUser.servicetype.servicetype2) {
          setServicetype2(currentUser.servicetype.servicetype2);
        } else {
          setServicetype2(false);
        }
        if (currentUser.servicetype.servicetype3) {
          setServicetype3(currentUser.servicetype.servicetype3);
        } else {
          setServicetype3(false);
        }
        if (currentUser.servicetype.servicetypeavailability1) {
          setServicetype1availability(
            currentUser.servicetype.servicetypeavailability1
          );
        } else {
          setServicetype1availability(false);
        }
        if (currentUser.servicetype.servicetypeavailability2) {
          setServicetype2availability(
            currentUser.servicetype.servicetypeavailability2
          );
        } else {
          setServicetype2availability(false);
        }
        if (currentUser.servicetype.servicetypeavailability3) {
          setServicetype3availability(
            currentUser.servicetype.servicetypeavailability3
          );
        } else {
          setServicetype3availability(false);
        }
      }
    }
  }, [disabledservicetype]);

  useEffect(() => {
    if (disableddetailslisting) {
      if (currentUser.providerdetailslistingsection1) {
        var inputraw1: any = [];
        currentUser.providerdetailslistingsection1.map((data: any) => {
          inputraw1.push(data.providerdetailslistingtypedescription);
        });
        setListingcategory1(inputraw1);
      }
      if (currentUser.providerdetailslistingsection2) {
        var inputraw2: any = [];
        currentUser.providerdetailslistingsection2.map((data: any) => {
          inputraw2.push(data.providerdetailslistingtypedescription);
        });
        setListingcategory2(inputraw2);
      }
      if (currentUser.providerdetailslistingsection3) {
        var inputraw3: any = [];
        currentUser.providerdetailslistingsection3.map((data: any) => {
          inputraw3.push(data.providerdetailslistingtypedescription);
        });
        setListingcategory3(inputraw3);
      }
    }
  }, [disableddetailslisting]);

  useEffect(() => {
    if (disabledmap) {
      if (currentUser.citylocationlat && currentUser.citylocationlat !== "") {
        setCitylocationlat(currentUser.citylocationlat);
      } else {
        setCitylocationlat("0.0");
      }
      if (currentUser.citylocationlng && currentUser.citylocationlng !== "") {
        setCitylocationlng(currentUser.citylocationlng);
      } else {
        setCitylocationlng("0.0");
      }
    }
  }, [disabledmap]);

  useEffect(() => {
    if (isSuccess) {
      setImagelink(currentUser.providerimageslinks[0].providerimagelink);
      setImagetoken(currentUser.providerimageslinks[0].providerimagetoken);

      /* const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user") || "{}")
        : null;

      setImagelink(user.providerimageslinks[0].providerimagelink);
      setImagetoken(user.providerimageslinks[0].providerimagetoken); */
    }
  }, [isSuccess]);

  useEffect(() => {
    if (selectedFile) {
      const reader: any = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(selectedFile);

      /* reader.onload = function (e: any) {

        //Initiate the JavaScript Image object.
        var image = new Image();
      
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;
      
        //Validate the File Height and Width.
        image.onload = function (e: any) {
          var height = e.target.height;
          var width = e.target.width;
          if (height > 100 || width > 100) {
            alert("Height and Width must not exceed 100px.");
            return false;
          }
          alert("Uploaded image has valid Height and Width.");
          return true;
        };
      }; */
    }
  }, [selectedFile]);

  /* useEffect(() => {
    var inputraw: any = [];
    if (selectedlistingcategory === "Room Details") {
      listingcategory1.map((data: any) => {
        inputraw.push(data);
      });
      setListingcategory(inputraw);
    } else if (selectedlistingcategory === "Room Features") {
      listingcategory2.map((data: any) => {
        inputraw.push(data);
      });
      setListingcategory(inputraw);
    } else if (selectedlistingcategory === "Services Provided") {
      listingcategory3.map((data: any) => {
        inputraw.push(data);
      });
      setListingcategory(inputraw);
    }
  }, [selectedlistingcategory]); */

  useEffect(() => {
    if (disabledbasicinfo && !iserror && inputRefBasicInfo.current) {
      inputRefBasicInfo.current.focus();
    }
    if (disabledchangepassword && !iserror && inputRefChangePassword.current) {
      inputRefChangePassword.current.focus();
    }
  }, [iserror]);

  const usedefaultsvalue = () => {
    var inputraw1: any = [];
    var inputraw2: any = [];
    var inputraw3: any = [];

    if (selectedlistingcategory === "Room Details") {
      inputraw1.push("Private Bedroom");
      inputraw1.push("Semi-Private Bedroom");
      inputraw1.push("Medicaid");
      inputraw1.push("Private Bathroom");
      setListingcategory1(inputraw1);

      setSelectedlistingcategory("");
      setSelectedlistingcategory("Room Details");
    } else if (selectedlistingcategory === "Room Features") {
      inputraw2.push("Air Conditioning");
      inputraw2.push("Television");
      inputraw2.push("WIFI");
      inputraw2.push("Hospital Bed-Regular Single Bed");
      inputraw2.push("Ceiling Fan");
      inputraw2.push("Nightstand with Lamp");
      inputraw2.push("Call Light System");
      inputraw2.push("Sitting Area");
      inputraw2.push("Closet Area");
      inputraw2.push("Emergency Smoke Detector or Sprinkler System");
      setListingcategory2(inputraw2);

      setSelectedlistingcategory("");
      setSelectedlistingcategory("Room Features");
    } else if (selectedlistingcategory === "Services Provided") {
      inputraw3.push("24-hour Care Supervision");
      inputraw3.push(
        "Meals: Home prepared nutritional cooked meals three times a day including snacks"
      );
      inputraw3.push("Special Diets Offered (as per physician’s orders)");
      inputraw3.push(
        "Activities of Daily living: assistance with feeding, walking, transfers, bathing, toileting/incontinence care"
      );
      inputraw3.push("Medication Management");
      inputraw3.push(
        "Exercise: Offered daily and as needed; per physician instructions"
      );
      inputraw3.push(
        "Activities: Individualized plan based on client’s preferences"
      );
      inputraw3.push("Laundry Service");
      inputraw3.push("Housekeeping Service");
      inputraw3.push(
        "Transportation Options: Caregiver may provide free transportation as part of their service to doctor’s appointments for client’s able to transfer in and out of a car. For clients requiring more assistance, caregiver can arrange for private transport which is an out-of-pocket expense for families/clients."
      );
      setListingcategory3(inputraw3);

      setSelectedlistingcategory("");
      setSelectedlistingcategory("Services Provided");
    }
  };

  const detailslistingmodifyline = () => {
    var templistingcategory: any;
    var newarr: any = [];

    if (selectedlistingcategory === "Room Details") {
      templistingcategory = listingcategory1;
      if (modemodifyline) {
        templistingcategory.push("");
        newarr = templistingcategory;
      } else {
        templistingcategory.map((item: any, index: any) => {
          if (item !== listingcategory1[indexmodifyline]) {
            newarr.push(item);
          }
        });
      }
      setListingcategory1(newarr);
      setSelectedlistingcategory("");
      setSelectedlistingcategory("Room Details");
    }

    if (selectedlistingcategory === "Room Features") {
      templistingcategory = listingcategory2;
      if (modemodifyline) {
        templistingcategory.push("");
        newarr = templistingcategory;
      } else {
        templistingcategory.map((item: any, index: any) => {
          if (item !== listingcategory2[indexmodifyline]) {
            newarr.push(item);
          }
        });
      }
      setListingcategory2(newarr);
      setSelectedlistingcategory("");
      setSelectedlistingcategory("Room Features");
    }

    if (selectedlistingcategory === "Services Provided") {
      templistingcategory = listingcategory3;
      if (modemodifyline) {
        templistingcategory.push("");
        newarr = templistingcategory;
      } else {
        templistingcategory.map((item: any, index: any) => {
          if (item !== listingcategory3[indexmodifyline]) {
            newarr.push(item);
          }
        });
      }
      setListingcategory3(newarr);
      setSelectedlistingcategory("");
      setSelectedlistingcategory("Services Provided");
    }
  };

  const toggleOPasswordVisiblity = () => {
    setShowopassword(showopassword ? false : true);
  };

  const togglePasswordVisiblity = () => {
    setShowpassword(showpassword ? false : true);
  };

  const toggleCPasswordVisiblity = () => {
    setShowcpassword(showcpassword ? false : true);
  };

  const onFileSelected = (e: any) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 3000000) {
        setSelectedFile(null);
        setPreview(null);
        setFileName(null);
        setIsDisabled(false); // Disabling upload button
        setButtonText("Select your file first");

        setHeaderlocal("Error!");
        setMessagelocal("File size must be 3mb or less.");
        setIserror(true);
        return;
      }

      if (
        e.target.files[0].type !== "image/png" &&
        e.target.files[0].type !== "image/jpg" &&
        e.target.files[0].type !== "image/jpeg"
      ) {
        setSelectedFile(null);
        setPreview(null);
        setFileName(null);
        setIsDisabled(false); // Disabling upload button
        setButtonText("Select your file first");

        setHeaderlocal("Error!");
        setMessagelocal("File size must be .png or .jpg/.jpeg only.");
        setIserror(true);
        return;
      }

      setSelectedFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setIsDisabled(false); // Enabling upload button
      setButtonText("Let's upload this!");
    }
  };

  const handleEditBasicInfo = (e: any) => {
    if (e === "1") {
      //bobby
      //if ((!providerbusinessname || providerbusinessname === "" || providerbusinessname === "N/A") && showAdminBoard) {
      if (!providerbusinessname && showAdminBoard) {
        alert(providerbusinessname);
        setHeaderlocal("Error!");
        setMessagelocal("Please enter your provider business name");
        setIserror(true);
        return;
      }

      //if ((!providerbusinessaddress || providerbusinessaddress === "" || providerbusinessaddress === "N/A") && showAdminBoard) {
      if (!providerbusinessaddress && showAdminBoard) {
        alert(providerbusinessaddress);
        setHeaderlocal("Error!");
        setMessagelocal("Please enter your provider business address");
        setIserror(true);
        return;
      }

      if (!city && showAdminBoard) {
        setHeaderlocal("Error!");
        setMessagelocal("Please select your city");
        setIserror(true);
        return;
      }

      if (!state && showAdminBoard) {
        setHeaderlocal("Error!");
        setMessagelocal("Please select your state");
        setIserror(true);
        return;
      }

      if (!zipcode && showAdminBoard) {
        setHeaderlocal("Error!");
        setMessagelocal("Please select your zip code");
        setIserror(true);
        return;
      }

      if (!firstname) {
        setHeaderlocal("Error!");
        setMessagelocal("Please enter your first name");
        setIserror(true);
        return;
      }

      if (!lastname) {
        setHeaderlocal("Error!");
        setMessagelocal("Please enter your last name");
        setIserror(true);
        return;
      }

      if (!contactno1) {
        setHeaderlocal("Error!");
        setMessagelocal("Please enter your contact number");
        setIserror(true);
        return;
      }

      if (!email) {
        setHeaderlocal("Error!");
        setMessagelocal("Please enter your Email / Username");
        setIserror(true);
        return;
      }
    }

    if (e === "2") {
      if (!servicetype1 && !servicetype2 && !servicetype3) {
        setHeaderlocal("Error!");
        setMessagelocal("Please select at least one Service Type");
        setIserror(true);
        return;
      }
    }

    if (e === "3") {
      var countofblanks: any = 0;
      listingcategory1.map((data: any, index: any) => {
        if (data === "") {
          countofblanks = countofblanks + 1;
        }
      });

      listingcategory2.map((data: any, index: any) => {
        if (data === "") {
          countofblanks = countofblanks + 1;
        }
      });

      listingcategory3.map((data: any, index: any) => {
        if (data === "") {
          countofblanks = countofblanks + 1;
        }
      });

      if (countofblanks > 0) {
        setHeaderlocal("Error!");
        setMessagelocal(
          "Please remove any blank line first in any category before saving"
        );
        setIserror(true);
        return;
      }
    }

    setShowloading(true);

    const providerbusinessdescription = "";
    const contactno2 = "";
    const servicetype4 = false;
    const servicetype5 = false;
    const servicetype6 = false;
    const servicetype7 = false;
    const servicetype8 = false;
    const servicetype9 = false;
    const servicetype10 = false;
    const servicetype11 = false;
    const servicetype12 = false;
    const servicetype13 = false;
    const servicetype14 = false;
    const servicetype15 = false;
    const servicetype4availability = false;
    const servicetype5availability = false;
    const servicetype6availability = false;
    const servicetype7availability = false;
    const servicetype8availability = false;
    const servicetype9availability = false;
    const servicetype10availability = false;
    const servicetype11availability = false;
    const servicetype12availability = false;
    const servicetype13availability = false;
    const servicetype14availability = false;
    const servicetype15availability = false;
    /* const detailslistingmode = e === "3" ? true : false; */
    var detailslistingmode = false;
    if (e === "1") {
      detailslistingmode =
        currentUser.providerbusinessaddress !== providerbusinessaddress ||
        currentUser.city !== city ||
        currentUser.state !== state ||
        currentUser.zipcode !== zipcode
          ? true
          : false;
    }
    var locationcoordinates = false;
    if (currentUser.zipcode !== zipcode) {
      locationcoordinates = true;
    }
    const foronhold =
      (currentUser.providerbusinessaddress !== providerbusinessaddress ||
        currentUser.city !== city ||
        currentUser.state !== state ||
        currentUser.zipcode !== zipcode) &&
      currentUser.approvedprovider
        ? true
        : false;

    const specialmode = showAdminBoard; //bbbb ? true : false;
    AuthService.editaccountdetails(
      providerbusinessname,
      providerbusinessdescription,
      providerbusinessaddress,
      firstname,
      lastname,
      contactno1,
      contactno2,
      city,
      state,
      zipcode,
      citylocationlat,
      citylocationlng,
      birthmonth,
      birthday,
      email,
      licensedtooperate,
      servicetype1,
      servicetype2,
      servicetype3,
      servicetype4,
      servicetype5,
      servicetype6,
      servicetype7,
      servicetype8,
      servicetype9,
      servicetype10,
      servicetype11,
      servicetype12,
      servicetype13,
      servicetype14,
      servicetype15,
      servicetype1availability,
      servicetype2availability,
      servicetype3availability,
      servicetype4availability,
      servicetype5availability,
      servicetype6availability,
      servicetype7availability,
      servicetype8availability,
      servicetype9availability,
      servicetype10availability,
      servicetype11availability,
      servicetype12availability,
      servicetype13availability,
      servicetype14availability,
      servicetype15availability,
      listingcategory1,
      listingcategory2,
      listingcategory3,
      specialmode,
      detailslistingmode,
      locationcoordinates,
      foronhold
    ).then(
      (data: any) => {
        if (e === "1") {
          if (showModeratorBoard) {
            inputRefCity.current.classList.toggle("dropdown-toggle");
            inputRefState.current.classList.toggle("dropdown-toggle");
            inputRefZipCode.current.classList.toggle("dropdown-toggle");
            inputRefLicense.current.classList.toggle("dropdown-toggle");
          }
          inputRefMonths.current.classList.toggle("dropdown-toggle");
          inputRefDays.current.classList.toggle("dropdown-toggle");
        }
        dispatch(loginSuccess(data));
        setHeaderlocal("Success!");
        if (showprofile) {
          if (currentUser.approvedprovider) {
            setMessagelocal(
              `Your Provider Account Profile has been updated. An address change will place your Account "On Hold" and will be reviewed for approval.`
            );
          } else {
            setMessagelocal(`Your Provider Account Profile has been updated.`);
          }
        } else if (showservices) {
          setMessagelocal("Room availability has been updated.");
        } else if (showlisting) {
          setMessagelocal("Details Listing has been updated.");
        }
        setDisabledbasicinfo(true);
        setButton1namebasicinfo("Edit");
        setDisabledservicetype(true);
        setButton1nameservicetype("Edit");
        setDisableddetailslisting(true);
        setButton1namedetailslisting("Edit");
        setShowloading(false);
        setIssaved(true);
        return Promise.resolve();
      },
      (error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setMessage(message));
        if (e === "1") {
          if (showModeratorBoard) {
            inputRefCity.current.classList.toggle("dropdown-toggle");
            inputRefState.current.classList.toggle("dropdown-toggle");
            inputRefZipCode.current.classList.toggle("dropdown-toggle");
            inputRefLicense.current.classList.toggle("dropdown-toggle");
          }
          inputRefMonths.current.classList.toggle("dropdown-toggle");
          inputRefDays.current.classList.toggle("dropdown-toggle");
        }
        setHeaderlocal("Error!");
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        return Promise.reject();
      }
    );
  };

  const handleEditChangePassword = () => {
    if (!opassword) {
      setHeaderlocal("Error!");
      setMessagelocal("Please enter your password");
      setIserror(true);
      return;
    }
    if (opassword.length < 8 || opassword.length > 12) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "Password should not be less than 8 and not more than 12 characters"
      );
      setIserror(true);
      return;
    }
    if (validatePassword(opassword) === false) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "Password must contain at least one number, special character and one uppercase and lowercase letter, and at least 8 up to 12 characters"
      );
      setIserror(true);
      return;
    }
    if (!password) {
      setHeaderlocal("Error!");
      setMessagelocal("Please enter your new password");
      setIserror(true);
      return;
    }
    if (password.length < 8 || password.length > 12) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "New Password should not be less than 8 and not more than 12 characters"
      );
      setIserror(true);
      return;
    }
    if (validatePassword(password) === false) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "New Password must contain at least one number, special character and one uppercase and lowercase letter, and at least 8 up to 12 characters"
      );
      setIserror(true);
      return;
    }
    if (!cpassword) {
      setHeaderlocal("Error!");
      setMessagelocal("Please enter your confirm new password");
      setIserror(true);
      return;
    }
    if (cpassword.length < 8 || cpassword.length > 12) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "Confirm New Password should not be less than 8 and not more than 12 characters"
      );
      setIserror(true);
      return;
    }
    if (validatePassword(cpassword) === false) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "Confirm New Password must contain at least one number, special character and one uppercase and lowercase letter, and at least 8 up to 12 characters"
      );
      setIserror(true);
      return;
    }

    if (password !== cpassword) {
      setHeaderlocal("Error!");
      setMessagelocal("New Passwords do not matched. Please try again.");
      setIserror(true);
      return;
    }

    setHeaderlocal("Change Password");
    setShowloading(true);

    const specialmode = showAdminBoard; //bbbb ? true : false;
    AuthService.changepassword(
      opassword,
      password,
      cpassword,
      specialmode
    ).then(
      (response: any) => {
        setHeaderlocal("Success!");
        setMessagelocal(response.data.message);
        setDisabledchangepassword(true);
        setButton1namechangepassword("Edit");
        setShowloading(false);
        setIssaved(true);
        return Promise.resolve();
      },
      (error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setMessage(message));
        setHeaderlocal("Error!");
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        return Promise.reject();
      }
    );
  };

  const handleEditMap = () => {
    setShowloading(true);

    const citylocationlatvalue = localStorage.getItem("usermaplat")
      ? localStorage.getItem("usermaplat")
      : citylocationlat;
    const citylocationlngvalue = localStorage.getItem("usermaplng")
      ? localStorage.getItem("usermaplng")
      : citylocationlng;

    AuthService.editmap(citylocationlatvalue, citylocationlngvalue).then(
      (data: any) => {
        dispatch(loginSuccess(data));
        setHeaderlocal("Success!");
        setMessagelocal("Map location successfully changed!");
        setDisabledmap(true);
        setButton1namemap("Edit");
        setShowloading(false);
        setIssaved(true);
        localStorage.removeItem("usermaplat");
        localStorage.removeItem("usermaplng");
        return Promise.resolve();
      },
      (error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setMessage(message));
        setHeaderlocal("Error!");
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        localStorage.removeItem("usermaplat");
        localStorage.removeItem("usermaplng");
        return Promise.reject();
      }
    );
  };

  const handleFileUpload = async (/* e: any */) => {
    /* e.preventDefault(); */
    setIsLoading(true);
    setIsDisabled(true);
    setButtonText("Wait we're uploading your file...");

    /* // Creating a FormData object
    let fileData: any = new FormData();

    // Adding the 'image' field and the selected file as value to our FormData object
    // Changing file name to make it unique and avoid potential later overrides
    fileData.set(
      "image",
      selectedFile,
      `${Date.now()}-${selectedFile.name}`
    );

    await AuthService.uploadprofilepicture(
      fileData,
    ).then(
      async(response: any) => {
        dispatch(loginSuccess(data));
        //setMessagelocal(response.data.message);
        //setDisabledchangepassword(true);
        //setButton1namechangepassword("Edit");
        //setShowloading(false);
        //setIssaved(true);
        setIsLoading(false);
        setIsSuccess(true);

        // Reset to default values after 3 seconds
        setTimeout(() => {
          setSelectedFile(null);
          setPreview(null);
          setIsSuccess(false);
          setFileName(null);
          setButtonText("Select your file first");
        }, 3000);
        return Promise.resolve();
      },
      async(error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //dispatch(setMessage(message));
        //setHeaderlocal("Error!");
        //setMessagelocal(message);
        //setShowloading(false);
        //setIserror(true);
        console.log(error);

        setIsLoading(false);
        setIsError(true);
        setFileName(null);
  
        setTimeout(() => {
          setIsError(false);
          setButtonText("Select your file first");
        }, 3000);
        return Promise.reject();
      }
    ); */

    try {
      if (selectedFile !== "") {
        // Creating a FormData object
        let fileData: any = new FormData();

        // Adding the 'image' field and the selected file as value to our FormData object
        // Changing file name to make it unique and avoid potential later overrides
        fileData.set(
          "image",
          selectedFile,
          `${Date.now()}-${selectedFile.name}`
        );

        const user = localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user") || "{}")
          : null;

        /* let aboutfileuploaded =  */
        await axios({
          method: "post",
          url:
            currentUser.providerimageslinks[0].providerimagetoken ===
              "272be1a5-abab-4d60-8470-5600a5b40c40" ||
            !currentUser.approvedprovider
              ? urlaxiosfirstupload
              : urlaxios,
          data: fileData,
          headers: {
            "x-access-token": user.accessToken,
            "Content-Type": "multipart/form-data",
          },
        }).then((response: any) => {
          const getraw: any = localStorage.getItem("user")
            ? localStorage.getItem("user")
            : {};
          const previoususerinfo: any = JSON.parse(getraw);

          localStorage.setItem(
            "user",
            JSON.stringify({
              ...previoususerinfo,
              providerimageslinks: [response.data.data],
            })
          );

          dispatch(
            loginSuccess({
              ...previoususerinfo,
              providerimageslinks: [response.data.data],
            })
          );
        });

        /* console.log(aboutfileuploaded); */

        setIsLoading(false);
        setIsSuccess(true);

        // Reset to default values after 3 seconds
        setTimeout(() => {
          setSelectedFile(null);
          setPreview(null);
          setIsSuccess(false);
          setFileName(null);
          setButtonText("Select your file first");
        }, 3000);
      }
    } catch (error) {
      /* console.log(error); */
      dispatch(setMessage("Something went wrong ..."));

      setIsLoading(false);
      setIsError(true);
      setFileName(null);

      setTimeout(() => {
        setIsError(false);
        setButtonText("Select your file first");
      }, 3000);
    }
  };

  const logOut = () => {
    UserService.getLogout();
    AuthService.logout();
    dispatch(invokeLogout());
    history.push("/home");
    window.location.reload();
  };

  const adminlist1retrieve = () => {
    AuthService.adminlist1("1")
      .then((data: any) => {
        setIserror(false);
        setShowloading(false);
        adminlist1rawpopulate();
        return Promise.resolve();
      })
      .catch((error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setHeaderlocal("Error!");
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        return Promise.reject();
      });
  };

  const adminlist2retrieve = () => {
    AuthService.adminlist2("1")
      .then((data: any) => {
        setIserror(false);
        setShowloading(false);
        adminlist2rawpopulate();
        return Promise.resolve();
      })
      .catch((error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setHeaderlocal("Error!");
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        return Promise.reject();
      });
  };

  const adminlist3retrieve = () => {
    AuthService.adminlist3("1")
      .then((data: any) => {
        setIserror(false);
        setShowloading(false);
        adminlist3rawpopulate();
        return Promise.resolve();
      })
      .catch((error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setHeaderlocal("Error!");
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        return Promise.reject();
      });
  };

  const adminlist1rawpopulate = () => {
    if (
      localStorage.getItem("adminlist1raw") ||
      localStorage.getItem("adminlist1raw2")
    ) {
      setDatavalue1([]);
      //var datavalue: any = [];
      var datavaluesorted: any = [];
      if (!disabledforpaymentbutton1 || !disabledforpaymentbutton2) {
        if (localStorage.getItem("adminlist1raw")) {
          const datavalueraw: any = localStorage.getItem("adminlist1raw");
          JSON.parse(datavalueraw).map((datasub: any) => {
            datavaluesorted.push(datasub);
          });

          datavaluesorted.sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return a.useruniquenumber - b.useruniquenumber;
          });
        }
      }
      if (!disabledforpaymentbutton1 || !disabledforpaymentbutton3) {
        if (localStorage.getItem("adminlist1raw2")) {
          const datavalueraw: any = localStorage.getItem("adminlist1raw2");
          JSON.parse(datavalueraw).map((datasub: any) => {
            datavaluesorted.push(datasub);
          });

          datavaluesorted.sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return a.useruniquenumber - b.useruniquenumber;
          });
        }
      }
      setDatavalue1(datavaluesorted);
    }
  };

  const adminlist2rawpopulate = () => {
    if (localStorage.getItem("adminlist2raw")) {
      setDatavalue2([]);
      //var datavalue: any = [];
      var datavaluesorted: any = [];
      const datavalueraw: any = localStorage.getItem("adminlist2raw");
      JSON.parse(datavalueraw).map((datasub: any) => {
        datavaluesorted.push(datasub);
      });

      datavaluesorted.sort(function (a: any, b: any) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return a.useruniquenumber - b.useruniquenumber;
      });
      setDatavalue2(datavaluesorted);
    }
  };

  const adminlist3rawpopulate = () => {
    if (localStorage.getItem("adminlist3raw")) {
      setDatavalue3([]);
      //var datavalue: any = [];
      var datavaluesorted: any = [];
      const datavalueraw: any = localStorage.getItem("adminlist3raw");
      JSON.parse(datavalueraw).map((datasub: any) => {
        datavaluesorted.push(datasub);
      });

      datavaluesorted.sort(function (a: any, b: any) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return a.useruniquenumber - b.useruniquenumber;
      });
      setDatavalue3(datavaluesorted);
    }
  };

  const adminlist1saveretrieve = (s: any, p: any, m: any) => {
    if (galleryaddress === "" /* || galleryaddress === "N/A" */) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "The Provider is not yet inputting business address. Please communicate with the Provider to input the business address first."
      );
      setIserror(true);
      return;
    }

    if (gallerycity === "" /*  || gallerycity === "N/A" */) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "The Provider is not yet selecting city. Please communicate with the Provider to select the city first."
      );
      setIserror(true);
      return;
    }

    if (gallerystate === "" /*  || gallerystate === "N/A" */) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "The Provider is not yet selecting state. Please communicate with the Provider to select the state first."
      );
      setIserror(true);
      return;
    }

    if (galleryzipcode === "" /*  || galleryzipcode === "N/A" */) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "The Provider is not yet selecting zip code. Please communicate with the Provider to select the zip code first."
      );
      setIserror(true);
      return;
    }

    /* if (galleryimagetoken === "272be1a5-abab-4d60-8470-5600a5b40c40") {
      setHeaderlocal("Error!");
      setMessagelocal("The Provider is not yet uploading photo. Please communicate with the Provider to upload photo first.");
      setIserror(true);
      return;
    } */

    AuthService.saveadminlist1(s, p, m)
      .then((data: any) => {
        setIserror(false);
        setShowloading(false);
        adminlist1rawpopulate();
        setShowadminlistview(false);
        return Promise.resolve();
      })
      .catch((error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setHeaderlocal("Error!");
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        return Promise.reject();
      });
  };

  const adminlist2saveretrieve = (p: any) => {
    if (galleryaddress === "" /*  || galleryaddress === "N/A" */) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "The Provider is not yet inputting business address. Please communicate with the Provider to input the business address first."
      );
      setIserror(true);
      return;
    }

    if (gallerycity === "" /*  || gallerycity === "N/A" */) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "The Provider is not yet selecting city. Please communicate with the Provider to select the city first."
      );
      setIserror(true);
      return;
    }

    if (gallerystate === "" /*  || gallerystate === "N/A" */) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "The Provider is not yet selecting state. Please communicate with the Provider to select the state first."
      );
      setIserror(true);
      return;
    }

    if (galleryzipcode === "" /*  || galleryzipcode === "N/A" */) {
      setHeaderlocal("Error!");
      setMessagelocal(
        "The Provider is not yet selecting zip code. Please communicate with the Provider to select the zip code first."
      );
      setIserror(true);
      return;
    }

    /* if (galleryimagetoken === "272be1a5-abab-4d60-8470-5600a5b40c40") {
      setHeaderlocal("Error!");
      setMessagelocal("The Provider is not yet uploading photo. Please communicate with the Provider to upload photo first.");
      setIserror(true);
      return;
    } */

    AuthService.saveadminlist2(p)
      .then((data: any) => {
        setIserror(false);
        setShowloading(false);
        adminlist2rawpopulate();
        setShowadminlistview(false);
        return Promise.resolve();
      })
      .catch((error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setHeaderlocal("Error!");
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        return Promise.reject();
      });
  };

  const adminlist3saveretrieve = (s: any, p: any) => {
    AuthService.saveadminlist3(s, p)
      .then((data: any) => {
        setIserror(false);
        setShowloading(false);
        adminlist3rawpopulate();
        setShowadminlistview(false);
        return Promise.resolve();
      })
      .catch((error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setHeaderlocal("Error!");
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        return Promise.reject();
      });
  };

  async function launchLink1() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/VCP%20Terms%20%26%20Conditions.pdf?alt=media&token=44d5a2be-11e0-4765-82ea-45d84fb0b981",
        },
        "*"
      );
    } else {
      window.open(
        "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/VCP%20Terms%20%26%20Conditions.pdf?alt=media&token=44d5a2be-11e0-4765-82ea-45d84fb0b981",
        "_system"
      );
    }
  }

  async function launchLink2() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/VCP%20Earning%20Power.pdf?alt=media&token=695073a3-58b5-467a-9442-5e55fe8823d7",
        },
        "*"
      );
    } else {
      window.open(
        "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/VCP%20Earning%20Power.pdf?alt=media&token=695073a3-58b5-467a-9442-5e55fe8823d7",
        "_system"
      );
    }
  }

  async function launchLink3() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/Visionary%20Team%20Concept%2C%20English.pdf?alt=media&token=32d1bed1-fdfa-4c1d-9932-bb50af5566ff",
        },
        "*"
      );
    } else {
      window.open(
        "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/Visionary%20Team%20Concept%2C%20English.pdf?alt=media&token=32d1bed1-fdfa-4c1d-9932-bb50af5566ff",
        "_system"
      );
    }
  }

  async function launchLink4() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/Visionary%20Team%20Concept%2C%20Tagalog.pdf?alt=media&token=5f6e30e1-e459-43fa-a239-b982a0de6d1b",
        },
        "*"
      );
    } else {
      window.open(
        "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/Visionary%20Team%20Concept%2C%20Tagalog.pdf?alt=media&token=5f6e30e1-e459-43fa-a239-b982a0de6d1b",
        "_system"
      );
    }
  }

  async function launchLink5() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/Share%20Your%20Business%20Link.pdf?alt=media&token=80d949af-2a4a-491c-9824-83a9f1a66b90",
        },
        "*"
      );
    } else {
      window.open(
        "https://firebasestorage.googleapis.com/v0/b/carehomeservicesproj.appspot.com/o/Share%20Your%20Business%20Link.pdf?alt=media&token=80d949af-2a4a-491c-9824-83a9f1a66b90",
        "_system"
      );
    }
  }

  async function launchLink6() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://live.vcita.com/site/seniorhubsolutions/online-scheduling?service=awhgsjlb949bvhne&staff=f638e9d0k585rhhe",
        },
        "*"
      );
    } else {
      window.open(
        "https://live.vcita.com/site/seniorhubsolutions/online-scheduling?service=awhgsjlb949bvhne&staff=f638e9d0k585rhhe",
        "_system"
      );
    }
  }

  async function launchLink7() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://www.google.com/maps/search/?api=1&query=" +
            currentUser.citylocationlat +
            "," +
            currentUser.citylocationlng +
            "&zoom=15",
        },
        "*"
      );
    } else {
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" +
          currentUser.citylocationlat +
          "," +
          currentUser.citylocationlng +
          "&zoom=15",
        "_system"
      );
    }
  }

  async function launchLinkVCITA1() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://live.vcita.com/site/seniorhubsolutions/make-payment?title=Monthly%20Member%20Services&amount=100", //"https://live.vcita.com/site/seniorhubsolutions/make-payment?title=PROMO!%20Annual%20Member%20Service%202022-2023&amount=1000",
        },
        "*"
      );
    } else {
      window.open(
        "https://live.vcita.com/site/seniorhubsolutions/make-payment?title=Monthly%20Member%20Services&amount=100", //"https://live.vcita.com/site/seniorhubsolutions/make-payment?title=PROMO!%20Annual%20Member%20Service%202022-2023&amount=1000",
        "_system"
      );
    }
  }

  async function launchLinkVCITA2() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://live.vcita.com/site/seniorhubsolutions/make-payment?title=Annual%20Member%20Services&amount=500", //"https://live.vcita.com/site/seniorhubsolutions/make-payment?title=PROMO!%20Annual%20Member%20Service%202022-2023&amount=1000",
        },
        "*"
      );
    } else {
      window.open(
        "https://live.vcita.com/site/seniorhubsolutions/make-payment?title=Annual%20Member%20Services&amount=500", //"https://live.vcita.com/site/seniorhubsolutions/make-payment?title=PROMO!%20Annual%20Member%20Service%202022-2023&amount=1000",
        "_system"
      );
    }
  }

  async function launchLinkVCITA3() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://live.vcita.com/site/seniorhubsolutions/make-payment?title=Registration%20Fee&amount=100", //"https://live.vcita.com/site/seniorhubsolutions/make-payment?title=PROMO!%20Annual%20Member%20Service%202022-2023&amount=1000",
        },
        "*"
      );
    } else {
      window.open(
        "https://live.vcita.com/site/seniorhubsolutions/make-payment?title=Registration%20Fee&amount=100", //"https://live.vcita.com/site/seniorhubsolutions/make-payment?title=PROMO!%20Annual%20Member%20Service%202022-2023&amount=1000",
        "_system"
      );
    }
  }

  let pagename = "Account Profile Information";

  return (
    <IonContent>
      <IonAlert
        isOpen={iserror}
        onDidDismiss={() => setIserror(false)}
        cssClass="ionbackgroundclass"
        header={headerlocal}
        message={messagelocal}
        buttons={["Dismiss"]}
      />
      <IonAlert
        isOpen={issaved}
        onDidDismiss={() => {
          setIssaved(false);
        }}
        cssClass="ionbackgroundclass"
        header={headerlocal}
        message={messagelocal}
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={iswarning}
        onDidDismiss={() => setIswarning(false)}
        cssClass="ionbackgroundclass"
        header="Warning!"
        message={messagelocal}
        buttons={[
          {
            text: "Yes",
            handler: (d) => {
              //if (!disableddetailslisting) {}
              if (!disabledbasicinfo) {
                handleEditBasicInfo("1");
                //handleEditBasicInfo("3");
              } else {
                handleFileUpload();
              }
            },
          },
          "Cancel",
        ]}
      />
      <IonAlert
        isOpen={iswarning2}
        onDidDismiss={() => setIswarning2(false)}
        cssClass="ionbackgroundclass"
        header="Warning!"
        message={messagelocal}
        buttons={[
          {
            text: "Yes",
            handler: (d) => {
              if (!disableddetailslisting) {
                usedefaultsvalue();
              }
            },
          },
          "Cancel",
        ]}
      />
      <IonAlert
        isOpen={iswarning3}
        onDidDismiss={() => setIswarning3(false)}
        cssClass="ionbackgroundclass"
        header="Warning!"
        message={messagelocal}
        /* buttons={[
          {
            text: "Yes",
            handler: (d) => {
              if (showforpayment) {
                adminlist1saveretrieve(
                  selectedoption1,
                  selecteduseruniquenumber
                );
              } else if (showforapproval) {
                adminlist2saveretrieve(selecteduseruniquenumber);
              } else if (showapprovedproviderlist) {
                adminlist3saveretrieve(selectedoption2, selecteduseruniquenumber);
              }
            },
          },
          "Cancel",
        ]} */
        buttons={
          showforpayment
            ? [
                {
                  text: "Paid Monthly Subscription",
                  handler: (d) => {
                    adminlist1saveretrieve(
                      selectedoption1,
                      selecteduseruniquenumber,
                      false
                    );
                  },
                },
                {
                  text: "Paid Annual Subscription",
                  handler: (d) => {
                    adminlist1saveretrieve(
                      selectedoption1,
                      selecteduseruniquenumber,
                      true
                    );
                  },
                },
                "Cancel",
              ]
            : [
                {
                  text: "Yes",
                  handler: (d) => {
                    if (showforapproval) {
                      adminlist2saveretrieve(selecteduseruniquenumber);
                    } else if (showapprovedproviderlist) {
                      adminlist3saveretrieve(
                        selectedoption2,
                        selecteduseruniquenumber
                      );
                    }
                  },
                },
                "Cancel",
              ]
        }
      />
      <IonLoading
        cssClass="ionbackgroundclass"
        isOpen={showloading}
        onDidDismiss={() => setShowloading(false)}
        message={"Please wait..."}
        duration={60000}
      />
      <IonLoading
        cssClass="ionbackgroundclass"
        isOpen={showloadingmodifyline}
        onDidDismiss={() => {
          setShowloadingmodifyline(false);
          detailslistingmodifyline();
        }}
        message={"Please wait..."}
        duration={1000}
      />
      <IonLoading
        cssClass="ionbackgroundclass"
        isOpen={showloadingLogout}
        onDidDismiss={() => {
          setShowloadingLogout(false);
          logOut();
        }}
        message={"Please wait..."}
        duration={2500}
      />
      <IonLoading
        cssClass="ionbackgroundclass"
        isOpen={showloadingfilterforpayment}
        onDidDismiss={() => {
          setShowloadingfilterforpayment(false);
          adminlist1rawpopulate();
        }}
        message={"Please wait..."}
        duration={1000}
      />
      <IonModal
        id="heightmodal"
        isOpen={showmodal}
        onDidDismiss={() => setShowmodal(false)}
      >
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle
              className="ion-text-center"
              style={{ fontSize: "large", color: "#8f6b00" }}
            >
              Terms & Conditions
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonItem
          id="backgroundwhiteitem"
          style={{ width: "100%", height: "100%", padding: "0" }}
        >
          <IonCard
            id="backgroundwhiteitem"
            style={{
              width: "100%",
              height: "100%",
              padding: "0",
            }}
          >
            {/* <IonCardContent id="backgroundwhiteitem"> */}

            <IonContent id="backgroundwhiteitem" style={{ height: "64vh" }}>
              <div className="container">
                <div style={{ height: "24px" }}></div>
                <h1
                  className="text-center"
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "center",
                  }}
                >
                  <b>I HAVE READ AND AGREE TO THE TERMS AND CONDITIONS</b>
                </h1>
                <div style={{ height: "12px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  Thank you for using seniorHUBsolutions! These Terms and
                  Conditions (“Terms”) cover your use and access of our website,
                  and related services.
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  These services are provided by seniorHUBsolutions and when not
                  mentioned directly by name, will be referenced through these
                  Terms as “we,” “our,” or “us”.
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  By using our services, you agree to these terms and
                  acknowledge you have reviewed our Privacy Policy. If you do
                  not agree to these Terms or our Privacy Policy, you should not
                  use our services.
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  If you create an account and use the services on behalf of an
                  entity, another person, or other business or employer, you
                  represent that you have the legal authority to bind that
                  entity to these Terms.
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  If you are a user, you also represent that you are at least 18
                  years old and have the legal capacity to accept these Terms.
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  You agree that we can contact you in the future, use your
                  information to deliver services or related content. You waive
                  all rights to recourse against Senior Hub Solutions, our
                  members, and affiliates because of your actions. Below is the
                  detail of that agreement.
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  When you submit information and photos to us, we use it in an
                  ongoing nature to ensure you receive the information or
                  purchases you have made with us to deliver relevant content
                  via email, newsletters, instant messaging, marketing
                  platforms, i.e., to deliver your business content on our site.
                  By giving us your contact information now you are granting us
                  the right to contact you in the future in any manner necessary
                  at our discretion for ongoing professional development. Should
                  you have any questions, contact Senior Hub Solutions via email
                  at memberservices@seniorhubsolutions.com
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  <b>User-Generated Content.</b> As between seniorHUBsolutions
                  and any user, all videos or other content created by a user
                  (“User Generated Content”) is property of the user, as
                  applicable. The user grants to seniorHUBsolutions a
                  non-exclusive, worldwide, royalty-free license to process,
                  reproduce, display, copy, communicate or otherwise use the
                  User Generated Content solely as necessary to provide the
                  services, users are responsible for ensuring that all User
                  Generated Content does not violate these Terms, third party
                  intellectual property rights or any applicable laws or
                  regulations (including illegal, harmful, offensive,
                  defamatory, misleading, obscene, or abusive material) and will
                  take reasonable steps to remove such User Generated Content.
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  <b>User Feedback.</b> Users may provide feedback to
                  seniorHUBsolutions about the services (e.g., technical support
                  input, suggestions, or enhancement requests) and general usage
                  analytics (i.e., aggregated non-personal technical data and
                  metadata from the services). seniorHUBsolutions may develop,
                  modify, and improve the services based on user feedback and
                  usage analytics, provided that such user feedback and usage
                  analytics will not include any information that may be used to
                  identify a specific individual.
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  All services by our company are for informational purposes
                  only. Your level of success in attaining any stated ideas or
                  strategies on our site is dependent upon several factors that
                  differ per individual therefore, we cannot guarantee your
                  specific success or results in any area of your business
                  endeavor.{" "}
                  <b>
                    We make no guarantees that you will achieve any specific
                    results from our member services.
                  </b>{" "}
                  We offer no licensed or professional medical, therapeutic, tax
                  or financial advice. The information contained herein cannot
                  replace or substitute for the services of trained
                  professionals in any field, including, but not limited to,
                  medical, psychological, financial, or legal matters. You alone
                  are responsible and accountable for your decisions, actions,
                  and results. By your registration here today and use of our
                  member services at any time, you agree not to attempt to hold
                  us liable for any such decisions, actions, or results at any
                  time under any circumstance.
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  <b>Services, Pricing &amp; Renewals.</b> Member Service Fees
                  (per client) is due within five (5) calendar days upon the
                  acceptance and/or signing of your care home contract agreement
                  with the seniorHUBsolutions client: payable to Senior HUB
                  Solutions. From time to time the service’s features may
                  change, including withdrawing or adding new features as well
                  as changing the price. You may cancel your renewal at any time
                  via email memberservices@seniorhubsolutions.com
                </p>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  <b>User Conduct.</b> In connection with your access to the
                  services, you are responsible for compliance with all
                  applicable laws, regulations, and policies of all relevant
                  jurisdictions. Recognizing the global nature of the Internet,
                  you agree to comply with all applicable local rules regarding
                  online conduct and acceptable content. Specifically, among
                  other things, you agree that by or while accessing or using
                  the application you will not:
                </p>
                <div style={{ height: "6px" }}></div>
                <ul
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    allow other persons to use your user account, and you agree
                    that you are the sole authorized user of your account; use
                    the Service for any purpose that is unlawful.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    represent yourself as another or as a fictitious individual.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    bypass any measures seniorHUBsolutions may use to present or
                    restrict access to the service, or otherwise attempt (by any
                    - means) to gain access to data or information that you are
                    not entitled to access.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    copy, modify, create derivatives of, decompile, or reverse
                    engineer the service or take any action to interfere with
                    seniorHUBsolutions proprietary and intellectual property
                    rights.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    impersonate any person or entity.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    stalk, threaten, or otherwise harass any person.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    interfere with or disrupt seniorHUBsolutions or the servers
                    or networks connected to seniorHUBsolutions.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    post information or interact on seniorHUBsolutions in a
                    manner which is false, inaccurate, misleading (directly or
                    by omission or failure to update information), defamatory,
                    libelous, abusive, obscene, profane, offensive, sexually
                    oriented, threatening, harassing, or illegal.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    use seniorHUBsolutions in any way that infringes any third
                    party’s rights, including but not limited to: intellectual
                    property rights, copyright, patent, trademark, trade secret
                    or other proprietary rights or rights of publicity or
                    privacy; post, email or otherwise transmit any malicious
                    code, files or programs designed to interrupt, damage,
                    destroy or limit the functionality of any computer software
                    or hardware or telecommunications equipment or
                    surreptitiously intercept or expropriate any system, data or
                    personal information.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    forge headers or otherwise manipulate identifiers to
                    disguise the origin of any information transmitted through
                    seniorHUBsolutions.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    “frame” or “mirror” any part of seniorHUBsolutions, without
                    our prior written authorization or use meta tags or code or
                    other devices containing any reference to us in order to
                    direct any person to any other web site for any purpose;
                    rent, lease, lend, sell, redistribute, license or sublicense
                    seniorHUBsolutions or access to any portion of
                    seniorHUBsolutions use any robot, spider, site
                    search/retrieval application, or other manual or automatic
                    device or process to retrieve, index, scrape, “data mine”,
                    or in any way reproduce or circumvent the navigational
                    structure or presentation of seniorHUBsolutions or its
                    contents.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    transfer or sell your user account, password and/or
                    identification to any other party.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    discriminate against or harass anyone based on race,
                    national origin, religion, gender, gender identity, physical
                    or mental disability, medical condition, marital status,
                    age, or sexual orientation, or
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    cause any third party to engage in the restricted activities
                    above. The above assurances and commitments by you shall
                    survive termination Services.
                  </li>
                  <li
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    represent yourself as another or as a fictitious individual.
                  </li>
                </ul>
                <div style={{ height: "6px" }}></div>
                <p
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontFamily: "Abadi MT Std",
                    marginBottom: "0px",
                    textAlign: "justify",
                  }}
                >
                  <b>LIMITATION OF LIABILITY.</b> TO THE FULLEST EXTENT
                  PERMITTED BY LAW, IN NO EVENT SHALL SENIOR HUB SOLUTIONS LLC
                  BE LIABLE FOR ANY DIRECT OR INDIRECT DAMAGES, LOSS OF DATA,
                  BUSINESS, PROFITS, COMPUTER HARDWARE OR SOFTWARE.
                </p>
              </div>
            </IonContent>

            {/* </IonCardContent> */}
            <IonButton
              expand="block"
              onClick={() => setShowmodal(false)}
              style={{
                margin: "0px",
                color: "#8f6b00",
                width: "100%",
                fontSize: "medium",
                /* height: "56px", */
              }}
            >
              I Agree
            </IonButton>
          </IonCard>
        </IonItem>
        <IonLabel id="backgroundwhiteitem" className="border-top">
          &nbsp;
        </IonLabel>
      </IonModal>

      <div
        className="align-items-start"
        style={{
          margin: "0px",
          padding: "0px",
          /* overflowX: "scroll",
          overflowY: "scroll", */
          marginLeft: "72px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <div
          /* className="col-lg-1 text-center" */
          className="fixed-top"
          style={{
            margin: "0px",
            padding: "0px",
            width: "4.5rem",
          }}
        >
          <div
            className="d-flex flex-column flex-shrink-0 bg-light fixed"
            style={{
              width: "4.5rem",
              height: `${window.innerHeight}px`, // "100vh"
              /* border: "1px solid black", */
            }}
          >
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
              <li className="nav-item">
                <a
                  className="nav-link py-3 border-bottom"
                  aria-current="page"
                  title="Home"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  style={{ cursor: "pointer", backgroundColor: "#ffcc00" }}
                  onClick={() => {
                    history.push("/home");
                  }}
                >
                  <img
                    src={home}
                    alt="mdo"
                    width="24"
                    height="24"
                    className="rounded-circle"
                  />
                </a>
              </li>
              <li
                ref={(ref) => (inputRefshowprofile.current = ref)}
                onClick={() => {
                  if (
                    disabledbasicinfo &&
                    disabledchangepassword &&
                    disabledservicetype &&
                    disableddetailslisting &&
                    disabledmap &&
                    !showadminlistview
                  ) {
                    setShowprofile(true);
                    setShowchangepassword(false);
                    setShowservices(false);
                    setShowlisting(false);
                    setShowmap(false);
                    setShowimages(false);
                    setShowforpayment(false);
                    setShowforapproval(false);
                    setShowapprovedproviderlist(false);
                    setShowvcita(false);
                    setShowadminlistview(false);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <a
                  className="nav-link py-3 border-bottom"
                  title="Profile"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={personCircle}
                    alt="mdo"
                    width="24"
                    height="24"
                    className="rounded-circle"
                    style={{ cursor: "pointer" }}
                  />
                </a>
              </li>
              <li
                ref={(ref) => (inputRefshowchangepassword.current = ref)}
                onClick={() => {
                  if (
                    disabledbasicinfo &&
                    disabledchangepassword &&
                    disabledservicetype &&
                    disableddetailslisting &&
                    disabledmap &&
                    !showadminlistview
                  ) {
                    setShowprofile(false);
                    setShowchangepassword(true);
                    setShowservices(false);
                    setShowlisting(false);
                    setShowimages(false);
                    setShowmap(false);
                    setShowforpayment(false);
                    setShowforapproval(false);
                    setShowapprovedproviderlist(false);
                    setShowvcita(false);
                    setShowadminlistview(false);
                  }
                }}
              >
                <a
                  className="nav-link py-3 border-bottom"
                  title="Change Password"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={key}
                    alt="mdo"
                    width="24"
                    height="24"
                    className="rounded-circle"
                    style={{ cursor: "pointer" }}
                  />
                </a>
              </li>
              {showModeratorBoard && (
                <li
                  ref={(ref) => (inputRefshowservices.current = ref)}
                  onClick={() => {
                    if (
                      disabledbasicinfo &&
                      disabledchangepassword &&
                      disabledservicetype &&
                      disableddetailslisting &&
                      disabledmap &&
                      !showadminlistview
                    ) {
                      setShowprofile(false);
                      setShowchangepassword(false);
                      setShowservices(true);
                      setShowlisting(false);
                      setShowmap(false);
                      setShowimages(false);
                      setShowforpayment(false);
                      setShowforapproval(false);
                      setShowapprovedproviderlist(false);
                      setShowvcita(false);
                      setShowadminlistview(false);
                    }
                  }}
                >
                  <a
                    className="nav-link py-3 border-bottom"
                    title="Services"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={pricetag}
                      alt="mdo"
                      width="24"
                      height="24"
                      className="rounded-circle"
                    />
                  </a>
                </li>
              )}
              {showModeratorBoard && (
                <li
                  ref={(ref) => (inputRefshowlisting.current = ref)}
                  onClick={() => {
                    if (
                      disabledbasicinfo &&
                      disabledchangepassword &&
                      disabledservicetype &&
                      disableddetailslisting &&
                      disabledmap &&
                      !showadminlistview
                    ) {
                      setShowprofile(false);
                      setShowchangepassword(false);
                      setShowservices(false);
                      setShowlisting(true);
                      setShowmap(false);
                      setShowimages(false);
                      setShowforpayment(false);
                      setShowforapproval(false);
                      setShowapprovedproviderlist(false);
                      setShowvcita(false);
                      setShowadminlistview(false);
                    }
                  }}
                >
                  <a
                    className="nav-link py-3 border-bottom"
                    title="Details Listing"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={reader}
                      alt="mdo"
                      width="24"
                      height="24"
                      className="rounded-circle"
                    />
                  </a>
                </li>
              )}
              {showModeratorBoard && (
                <li
                  ref={(ref) => (inputRefshowmap.current = ref)}
                  onClick={() => {
                    if (
                      disabledbasicinfo &&
                      disabledchangepassword &&
                      disabledservicetype &&
                      disableddetailslisting &&
                      disabledmap &&
                      !showadminlistview
                    ) {
                      setShowprofile(false);
                      setShowchangepassword(false);
                      setShowservices(false);
                      setShowlisting(false);
                      setShowmap(true);
                      setShowimages(false);
                      setShowforpayment(false);
                      setShowforapproval(false);
                      setShowapprovedproviderlist(false);
                      setShowvcita(false);
                      setShowadminlistview(false);
                    }
                  }}
                >
                  <a
                    className="nav-link py-3 border-bottom"
                    title="Maps"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={map}
                      alt="mdo"
                      width="24"
                      height="24"
                      className="rounded-circle"
                    />
                  </a>
                </li>
              )}
              {showModeratorBoard && (
                <li
                  ref={(ref) => (inputRefshowimages.current = ref)}
                  onClick={() => {
                    if (
                      disabledbasicinfo &&
                      disabledchangepassword &&
                      disabledservicetype &&
                      disableddetailslisting &&
                      disabledmap &&
                      !showadminlistview
                    ) {
                      setShowprofile(false);
                      setShowchangepassword(false);
                      setShowservices(false);
                      setShowlisting(false);
                      setShowmap(false);
                      setShowimages(true);
                      setShowforpayment(false);
                      setShowforapproval(false);
                      setShowapprovedproviderlist(false);
                      setShowvcita(false);
                      setShowadminlistview(false);
                    }
                  }}
                >
                  <a
                    className="nav-link py-3 border-bottom"
                    title="Image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={image}
                      alt="mdo"
                      width="24"
                      height="24"
                      className="rounded-circle"
                    />
                  </a>
                </li>
              )}
              {showAdminBoard && (
                <li
                  ref={(ref) => (inputRefshowforpayment.current = ref)}
                  onClick={() => {
                    if (
                      disabledbasicinfo &&
                      disabledchangepassword &&
                      disabledservicetype &&
                      disableddetailslisting &&
                      disabledmap &&
                      !showadminlistview
                    ) {
                      setShowprofile(false);
                      setShowchangepassword(false);
                      setShowservices(false);
                      setShowlisting(false);
                      setShowmap(false);
                      setShowimages(false);
                      setShowforpayment(true);
                      setShowforapproval(false);
                      setShowapprovedproviderlist(false);
                      setShowvcita(false);
                      setShowloading(true);
                      setShowadminlistview(false);

                      adminlist1retrieve();
                    }
                  }}
                >
                  <a
                    className="nav-link py-3 border-bottom"
                    title="Accounts Pending Payment and Accounts On Hold"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={wallet}
                      alt="mdo"
                      width="24"
                      height="24"
                      className="rounded-circle"
                    />
                  </a>
                </li>
              )}
              {showAdminBoard && (
                <li
                  ref={(ref) => (inputRefshowforapproval.current = ref)}
                  onClick={() => {
                    if (
                      disabledbasicinfo &&
                      disabledchangepassword &&
                      disabledservicetype &&
                      disableddetailslisting &&
                      disabledmap &&
                      !showadminlistview
                    ) {
                      setShowprofile(false);
                      setShowchangepassword(false);
                      setShowservices(false);
                      setShowlisting(false);
                      setShowmap(false);
                      setShowimages(false);
                      setShowforpayment(false);
                      setShowforapproval(true);
                      setShowapprovedproviderlist(false);
                      setShowvcita(false);
                      setShowloading(true);
                      setShowadminlistview(false);

                      adminlist2retrieve();
                    }
                  }}
                >
                  <a
                    className="nav-link py-3 border-bottom"
                    title="Pending Payment and Accounts on Hold for Review"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={checkmarkOutline}
                      alt="mdo"
                      width="24"
                      height="24"
                      className="rounded-circle"
                    />
                  </a>
                </li>
              )}
              {showAdminBoard && (
                <li
                  ref={(ref) =>
                    (inputRefshowapprovedproviderlist.current = ref)
                  }
                  onClick={() => {
                    if (
                      disabledbasicinfo &&
                      disabledchangepassword &&
                      disabledservicetype &&
                      disableddetailslisting &&
                      disabledmap &&
                      !showadminlistview
                    ) {
                      setShowprofile(false);
                      setShowchangepassword(false);
                      setShowservices(false);
                      setShowlisting(false);
                      setShowmap(false);
                      setShowimages(false);
                      setShowforpayment(false);
                      setShowforapproval(false);
                      setShowapprovedproviderlist(true);
                      setShowvcita(false);
                      setShowloading(true);
                      setShowadminlistview(false);

                      adminlist3retrieve();
                    }
                  }}
                >
                  <a
                    className="nav-link py-3 border-bottom"
                    title="Paid and Approved"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={book}
                      alt="mdo"
                      width="24"
                      height="24"
                      className="rounded-circle"
                    />
                  </a>
                </li>
              )}
              {showModeratorBoard && (
                <li
                  ref={(ref) => (inputRefshowvcita.current = ref)}
                  onClick={() => {
                    if (
                      disabledbasicinfo &&
                      disabledchangepassword &&
                      disabledservicetype &&
                      disableddetailslisting &&
                      disabledmap &&
                      !showadminlistview
                    ) {
                      setShowprofile(false);
                      setShowchangepassword(false);
                      setShowservices(false);
                      setShowlisting(false);
                      setShowmap(false);
                      setShowimages(false);
                      setShowforpayment(false);
                      setShowforapproval(false);
                      setShowapprovedproviderlist(false);
                      setShowvcita(true);
                      setShowadminlistview(false);
                    }
                  }}
                >
                  <a
                    className="nav-link py-3 border-bottom"
                    title="Payments"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={wallet}
                      alt="mdo"
                      width="24"
                      height="24"
                      className="rounded-circle"
                    />
                  </a>
                </li>
              )}
            </ul>
            <div className="dropdown border-top">
              <a
                href="#"
                className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle"
                id="dropdownUser3"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={settings}
                  alt="mdo"
                  width="24"
                  height="24"
                  className="rounded-circle"
                />
              </a>
              <ul
                className="dropdown-menu text-small shadow"
                aria-labelledby="dropdownUser3"
              >
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (
                        disabledbasicinfo &&
                        disabledchangepassword &&
                        disabledservicetype &&
                        disableddetailslisting &&
                        disabledmap
                      ) {
                        setShowloadingLogout(true);
                      }
                    }}
                  >
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          /* className="col-lg-11 text-center" */
          style={{
            margin: "0px",
            padding: "0px",
            /* minWidth: "350px", */
          }}
        >
          <div style={{ height: "1vh" }}></div>
          <main
            className="form-signin"
            style={{
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: "4px",
            }}
          >
            {showprofile && !showadminlistview && (
              <form>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  {`${
                    showModeratorBoard ? "Care Home Provider" : "Admin"
                  } Account Profile (${providerid}):`}
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    {showModeratorBoard && (
                      <div
                        className="form-floating text-center"
                        style={{
                          margin: "0px",
                          padding: "0px",
                          marginLeft: "12px",
                          marginRight: "12px",
                          /* width: "100%", */
                          border: "1px solid black",
                          borderRadius: "6px",
                        }}
                      >
                        <p
                          className="featurette-heading"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            textAlign: "center",
                            fontFamily: "AvantGarde Bk BT",
                            color: "black",
                            fontSize: "large",
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          Guidelines / Downloadable Contents
                        </p>
                        <ul>
                          {/* <li
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                              fontSize: "medium",
                            }}
                          >
                            <a
                              style={{
                                textAlign: "left",
                                fontFamily: "AvantGarde Bk BT",
                                color: "black",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={launchLink1}
                            >
                              VCP Terms & Conditions.pdf
                            </a>
                          </li> */}
                          {/* <li
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                              fontSize: "medium",
                            }}
                          >
                            <a
                              style={{
                                textAlign: "left",
                                fontFamily: "AvantGarde Bk BT",
                                color: "black",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={launchLink2}
                            >
                              VCP Earning Power.pdf
                            </a>
                          </li> */}
                          <li
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                              fontSize: "medium",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "left",
                                fontFamily: "AvantGarde Bk BT",
                                color: "black",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Visionary Team Concept.pdf&nbsp;(
                              <a
                                style={{
                                  textAlign: "left",
                                  fontFamily: "AvantGarde Bk BT",
                                  color: "black",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={launchLink3}
                              >
                                English,
                              </a>
                              <a
                                style={{
                                  textAlign: "left",
                                  fontFamily: "AvantGarde Bk BT",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              >
                                &nbsp;
                              </a>
                              <a
                                style={{
                                  textAlign: "left",
                                  fontFamily: "AvantGarde Bk BT",
                                  color: "black",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={launchLink4}
                              >
                                Tagalog
                              </a>
                              )
                            </p>
                          </li>
                          <li
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                              fontSize: "medium",
                            }}
                          >
                            <a
                              style={{
                                textAlign: "left",
                                fontFamily: "AvantGarde Bk BT",
                                color: "black",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={launchLink5}
                            >
                              Share Your Business Link.pdf
                            </a>
                          </li>
                          <li
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                              fontSize: "medium",
                            }}
                          >
                            <a
                              style={{
                                textAlign: "left",
                                fontFamily: "AvantGarde Bk BT",
                                color: "black",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={launchLink6}
                            >
                              Let's Have Some Fun, Refer a Fellow Care Home
                              Provider and Get Rewarded! Give us a call today
                              for more information...
                            </a>
                          </li>
                          <li
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                              fontSize: "medium",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "left",
                                fontFamily: "AvantGarde Bk BT",
                                color: "black",
                              }}
                            >
                              A seniorHUBsolutions Member Service is for One
                              Provider Account Profile per care home (the
                              Provider Business Address is the physical care
                              home location). An address change will require
                              that the Provider Account be placed on hold for a
                              review and pending approval. For additional Care
                              Home Business Profiles contact seniorHUBsolutions.
                            </p>
                          </li>
                        </ul>
                        {/* <li
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                              fontSize: "medium",
                            }}
                          > */}
                        <a
                          style={{
                            textAlign: "left",
                            fontFamily: "AvantGarde Bk BT",
                            color: "#008080",
                            display: "block",
                            fontWeight: "bold",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          IMPORTANT!
                        </a>
                        <a
                          style={{
                            textAlign: "justify",
                            fontFamily: "AvantGarde Bk BT",
                            fontSize: "medium",
                            color: "#008080",
                            display: "block",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            width: "100%",
                          }}
                        >
                          Please remember to keep your Care Home Provider
                          account current with up-to-date Room and Bed
                          Availability. You are responsible for managing your
                          account. All updates for bed availability and room
                          features are done in the seniorhubsolutions.com Web
                          Portal. Should you have any questions or concerns,
                          contact a seniorHUBsolutions specialist for
                          assistance.
                        </a>
                        <div style={{ height: "6px" }}></div>
                        <a
                          style={{
                            textAlign: "left",
                            fontFamily: "AvantGarde Bk BT",
                            color: "#008080",
                            display: "block",
                            fontWeight: "bold",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          Friendly Reminder, Make Sure Your E-mail Won&#39;t
                          Filter Us…
                        </a>
                        <a
                          style={{
                            textAlign: "justify",
                            fontFamily: "AvantGarde Bk BT",
                            fontSize: "medium",
                            color: "#008080",
                            display: "block",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            width: "100%",
                          }}
                        >
                          Please check your &quot;Spam&quot;/ &quot;Bulk
                          Mail&quot; folder. If you find our email there, select
                          the confirmation message and click &quot;Not
                          Spam.&quot; This will help future messages to get
                          through.
                        </a>
                        <div style={{ height: "6px" }}></div>
                        {/* </li> */}
                      </div>
                    )}
                    {showModeratorBoard && (
                      <div style={{ height: "6px" }}></div>
                    )}
                    {showModeratorBoard && (
                      <div
                        className="form-floating"
                        style={{
                          textAlign: "center",
                          marginLeft: "12px",
                          marginRight: "12px",
                        }}
                      >
                        <input
                          disabled={disabledbasicinfo}
                          ref={(ref) => (inputRefBasicInfo.current = ref)}
                          type="text"
                          className="form-control"
                          /* id="floatingInput" */
                          /* placeholder="ABC Company, Inc." */
                          value={providerbusinessname}
                          onChange={(e) =>
                            setProviderbusinessname(e.target.value!)
                          }
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                          }}
                        />
                        <label
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                            textAlign: "left",
                          }}
                        >
                          * Provider Business Name:
                        </label>
                      </div>
                    )}
                    {showModeratorBoard && (
                      <div style={{ height: "6px" }}></div>
                    )}

                    {showModeratorBoard && (
                      <div
                        className="form-floating"
                        style={{
                          textAlign: "center",
                          marginLeft: "12px",
                          marginRight: "12px",
                        }}
                      >
                        <input
                          disabled={disabledbasicinfo}
                          type="text"
                          className="form-control"
                          /* id="floatingInput" */
                          /* placeholder="Address 1" */
                          value={providerbusinessaddress}
                          onChange={(e) =>
                            setProviderbusinessaddress(e.target.value!)
                          }
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                          }}
                        />
                        <label
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                            textAlign: "left",
                          }}
                        >
                          * Provider Business Address:
                        </label>
                      </div>
                    )}
                    {showModeratorBoard && (
                      <div style={{ height: "6px" }}></div>
                    )}

                    {showModeratorBoard && (
                      <div
                        className="form-floating"
                        style={{
                          textAlign: "left",
                          marginLeft: "12px",
                          marginRight: "12px",
                        }}
                      >
                        <div
                          className="form-control"
                          style={
                            disabledbasicinfo
                              ? {
                                  pointerEvents: "none",
                                  backgroundColor: "#e9ecef",
                                }
                              : {
                                  pointerEvents: "auto",
                                }
                          }
                        >
                          <a
                            ref={(ref) => (inputRefCity.current = ref)}
                            className="nav-link " //dropdown-toggle
                            href="#"
                            id="dropdown01"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              fontFamily: "Abadi MT Std",
                              display: "inline",
                              textAlign: "left",
                              padding: "0px",
                              margin: "0px",
                              fontSize: "1rem",
                              color: "#212529",
                            }}
                          >
                            {city}
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdown01"
                          >
                            {listcity.map((data: any, index: any) => {
                              return (
                                <li key={index}>
                                  <a
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setCity(listcity[index]);
                                    }}
                                  >
                                    {data}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>

                          <a
                            ref={(ref) => (inputRefState.current = ref)}
                            className="nav-link " //dropdown-toggle
                            href="#"
                            id="dropdown02"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              fontFamily: "Abadi MT Std",
                              display: "inline",
                              textAlign: "left",
                              padding: "0px",
                              margin: "0px",
                              fontSize: "1rem",
                              color: "#212529",
                            }}
                          >
                            ,&nbsp;{state}
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdown02"
                          >
                            {liststate.map((data: any, index: any) => {
                              return (
                                <li key={index}>
                                  <a
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setState(liststate[index]);
                                    }}
                                  >
                                    {data}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>

                          <a
                            ref={(ref) => (inputRefZipCode.current = ref)}
                            className="nav-link " //dropdown-toggle
                            href="#"
                            id="dropdown03"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              fontFamily: "Abadi MT Std",
                              display: "inline",
                              textAlign: "left",
                              padding: "0px",
                              margin: "0px",
                              fontSize: "1rem",
                              color: "#212529",
                            }}
                          >
                            ,&nbsp;{zipcode}
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdown03"
                          >
                            {listzipcode.map((data: any, index: any) => {
                              return (
                                <li key={index}>
                                  <a
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setZipcode(listzipcode[index]);
                                    }}
                                  >
                                    {data}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <label
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                            textAlign: "left",
                          }}
                        >
                          * City, State, Zip Code:
                        </label>
                      </div>
                    )}

                    {showModeratorBoard && (
                      <div style={{ height: "6px" }}></div>
                    )}
                    {showModeratorBoard && (
                      <div style={{ height: "6px" }}></div>
                    )}
                    {showModeratorBoard && (
                      <div
                        className="border-top"
                        style={{ height: "6px" }}
                      ></div>
                    )}
                    {showModeratorBoard && (
                      <div style={{ height: "6px" }}></div>
                    )}

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled={disabledbasicinfo}
                        type="text"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="John" */
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value!)}
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        * First Name:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled={disabledbasicinfo}
                        type="text"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="Doe" */
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value!)}
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        * Last Name:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled={disabledbasicinfo}
                        type="tel"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="+1 (000) 000-0000" */
                        value={contactno1}
                        onChange={(e) => setContactno1(e.target.value!)}
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        * Contact Number:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled={disabledbasicinfo}
                        type="email"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="name@example.com" */
                        value={email}
                        onChange={(e) => setEmail(e.target.value!)}
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        * Email / Username:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "left",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <div
                        className="form-control"
                        style={
                          disabledbasicinfo
                            ? {
                                pointerEvents: "none",
                                backgroundColor: "#e9ecef",
                              }
                            : {
                                pointerEvents: "auto",
                              }
                        }
                      >
                        <a
                          ref={(ref) => (inputRefMonths.current = ref)}
                          className="nav-link " //dropdown-toggle
                          href="#"
                          id="dropdown01"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            fontFamily: "Abadi MT Std",
                            display: "inline",
                            textAlign: "left",
                            padding: "0px",
                            margin: "0px",
                            fontSize: "1rem",
                            color: "#212529",
                          }}
                        >
                          {birthmonthequiv}
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdown01"
                        >
                          {listmonths.map((data: any, index: any) => {
                            return (
                              <li key={index}>
                                <a
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setBirthmonthequiv(listmonths[index]);
                                  }}
                                >
                                  {data}
                                </a>
                              </li>
                            );
                          })}
                        </ul>

                        <a
                          ref={(ref) => (inputRefDays.current = ref)}
                          className="nav-link " //dropdown-toggle
                          href="#"
                          id="dropdown02"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            fontFamily: "Abadi MT Std",
                            display: "inline",
                            textAlign: "left",
                            padding: "0px",
                            margin: "0px",
                            fontSize: "1rem",
                            color: "#212529",
                          }}
                        >
                          ,&nbsp;{birthdayequiv}
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdown02"
                        >
                          {listdays.map((data: any, index: any) => {
                            return (
                              <li key={index}>
                                <a
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setBirthdayequiv(listdays[index]);
                                  }}
                                >
                                  {data}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        Birth Month, Day (Mmm, dd):
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    {showModeratorBoard && (
                      <div
                        className="form-floating"
                        style={{
                          textAlign: "left",
                          marginLeft: "12px",
                          marginRight: "12px",
                        }}
                      >
                        <div
                          className="form-control"
                          style={
                            disabledbasicinfo
                              ? {
                                  pointerEvents: "none",
                                  backgroundColor: "#e9ecef",
                                }
                              : {
                                  pointerEvents: "auto",
                                }
                          }
                        >
                          <a
                            ref={(ref) => (inputRefLicense.current = ref)}
                            className="nav-link " //dropdown-toggle
                            href="#"
                            id="dropdown01"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              fontFamily: "Abadi MT Std",
                              display: "inline",
                              textAlign: "left",
                              padding: "0px",
                              margin: "0px",
                              fontSize: "1rem",
                              color: "#212529",
                            }}
                          >
                            {licensedtooperate ? "Yes" : "No"}
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdown01"
                          >
                            {listlicense.map((data: any, index: any) => {
                              return (
                                <li key={index}>
                                  <a
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setLicensedtooperate(
                                        listlicense[index] === "Yes"
                                          ? true
                                          : false
                                      );
                                    }}
                                  >
                                    {data}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <label
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                            textAlign: "left",
                          }}
                        >
                          Licensed to operate in Hawaii:
                        </label>
                      </div>
                    )}
                    <div style={{ height: "6px" }}></div>

                    {showModeratorBoard && (
                      <div
                        className="form-floating"
                        style={{
                          textAlign: "center",
                          marginLeft: "12px",
                          marginRight: "12px",
                        }}
                      >
                        <input
                          disabled={true}
                          type="text"
                          className="form-control"
                          /* id="floatingInput" */
                          /* placeholder="name@example.com" */
                          /* value={servicetype1 === true ? "ARCH" : servicetype2 === true ? "E-ARCH" : servicetype3 === true ? "AFCH" : "Not yet selected in Services Tab"} */
                          value={
                            servicetype1
                              ? "ARCH"
                              : servicetype2
                              ? "E-ARCH"
                              : servicetype3
                              ? "AFCH"
                              : "Not yet selected in Services Tab"
                          } //bobby
                          /* onChange={(e) => setEmail(e.target.value!)} */
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                          }}
                        />
                        <label
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                            textAlign: "left",
                          }}
                        >
                          * Business Category:
                        </label>
                      </div>
                    )}
                    <div style={{ height: "6px" }}></div>
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "6px" }}></div>
                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <IonButton
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      if (button1namebasicinfo === "Edit") {
                        setDisabledbasicinfo(false);
                        setButton1namebasicinfo("Cancel");
                        if (showModeratorBoard) {
                          inputRefCity.current.classList.toggle(
                            "dropdown-toggle"
                          );
                          inputRefState.current.classList.toggle(
                            "dropdown-toggle"
                          );
                          inputRefZipCode.current.classList.toggle(
                            "dropdown-toggle"
                          );
                          inputRefLicense.current.classList.toggle(
                            "dropdown-toggle"
                          );
                        }
                        inputRefMonths.current.classList.toggle(
                          "dropdown-toggle"
                        );
                        inputRefDays.current.classList.toggle(
                          "dropdown-toggle"
                        );
                      } else {
                        setDisabledbasicinfo(true);
                        setButton1namebasicinfo("Edit");
                        if (showModeratorBoard) {
                          inputRefCity.current.classList.toggle(
                            "dropdown-toggle"
                          );
                          inputRefState.current.classList.toggle(
                            "dropdown-toggle"
                          );
                          inputRefZipCode.current.classList.toggle(
                            "dropdown-toggle"
                          );
                          inputRefLicense.current.classList.toggle(
                            "dropdown-toggle"
                          );
                        }
                        inputRefMonths.current.classList.toggle(
                          "dropdown-toggle"
                        );
                        inputRefDays.current.classList.toggle(
                          "dropdown-toggle"
                        );
                      }
                    }}
                  >
                    {button1namebasicinfo}
                  </IonButton>
                  <IonButton
                    disabled={disabledbasicinfo}
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      /* if (showModeratorBoard) {
                        inputRefCity.current.classList.toggle(
                          "dropdown-toggle"
                        );
                        inputRefState.current.classList.toggle(
                          "dropdown-toggle"
                        );
                        inputRefZipCode.current.classList.toggle(
                          "dropdown-toggle"
                        );
                        inputRefLicense.current.classList.toggle(
                          "dropdown-toggle"
                        );
                      }
                      inputRefMonths.current.classList.toggle(
                        "dropdown-toggle"
                      );
                      inputRefDays.current.classList.toggle("dropdown-toggle"); */

                      if (
                        (currentUser.providerbusinessaddress !==
                          providerbusinessaddress ||
                          currentUser.city !== city ||
                          currentUser.state !== state ||
                          currentUser.zipcode !== zipcode) &&
                        currentUser.approvedprovider
                      ) {
                        setMessagelocal(
                          `An address change will place your account "On Hold" and will be reviewed for approval. Do you want to continue?`
                        );
                        setIswarning(true);
                      } else {
                        handleEditBasicInfo("1");
                      }
                      //handleEditBasicInfo("1");
                    }}
                  >
                    Save
                  </IonButton>
                </div>
                <div style={{ height: "12px" }}></div>
              </form>
            )}
            {showprofile && showModeratorBoard && !showadminlistview && (
              <form>
                <div className="border-bottom" style={{ height: "0px" }}></div>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Care Home Provider Account Status:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled
                        /* ref={(ref) => (inputRef.current = ref)} */
                        type="text"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="Yes" */
                        value={`${active ? "Yes" : "No"}`}
                        /* onChange={(e) =>
                          setProviderbusinessname(e.target.value!)
                        } */
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        Activated Account:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="Yes" */
                        value={`${subscribedprovider ? `Yes` : "No"}`} //{`${subscribedprovider ? `Yes (${paidtypeannually ? "Paid Annual Subscription" : "Paid Monthly Subscription"})` : "No"}`}
                        /* onChange={(e) =>
                          setProviderbusinessaddress(e.target.value!)
                        } */
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        Paid Account:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="Yes" */
                        value={`${approvedprovider ? "Yes" : "No"}`}
                        /* onChange={(e) =>
                          setProviderbusinessaddress(e.target.value!)
                        } */
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        Approved Account:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="Yes" */
                        value={`${pausedprovider ? "Yes" : "No"}`}
                        /* onChange={(e) =>
                          setProviderbusinessaddress(e.target.value!)
                        } */
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        On Hold Account:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "12px" }}></div>
              </form>
            )}

            {showchangepassword && !showadminlistview && (
              <form>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Change Password:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled={disabledchangepassword}
                        ref={(ref) => (inputRefChangePassword.current = ref)}
                        type={showopassword ? "text" : "password"}
                        className="form-control"
                        /* id="floatingPassword" */
                        /* placeholder="Old Password" */
                        value={opassword}
                        onChange={(e) => setOpassword(e.target.value!)}
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      {!disabledchangepassword && (
                        <i
                          onClick={toggleOPasswordVisiblity}
                          style={{ opacity: "1", color: "black" }}
                        >
                          {showopassword ? eyehidden : eyeshown}
                        </i>
                      )}
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        * Old Password:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled={disabledchangepassword}
                        type={showpassword ? "text" : "password"}
                        className="form-control"
                        /* id="floatingPassword" */
                        /* placeholder="New Password" */
                        value={password}
                        onChange={(e) => setPassword(e.target.value!)}
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      {!disabledchangepassword && (
                        <i
                          onClick={togglePasswordVisiblity}
                          style={{ opacity: "1", color: "black" }}
                        >
                          {showpassword ? eyehidden : eyeshown}
                        </i>
                      )}
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        * New Password:
                      </label>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        disabled={disabledchangepassword}
                        type={showcpassword ? "text" : "password"}
                        className="form-control"
                        /* id="floatingPassword" */
                        /* placeholder="Confirm New Password" */
                        value={cpassword}
                        onChange={(e) => setCpassword(e.target.value!)}
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                        }}
                      />
                      {!disabledchangepassword && (
                        <i
                          onClick={toggleCPasswordVisiblity}
                          style={{ opacity: "1", color: "black" }}
                        >
                          {showcpassword ? eyehidden : eyeshown}
                        </i>
                      )}
                      <label
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        * Confirm New Password
                      </label>
                    </div>

                    <div style={{ height: "6px" }}></div>
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "6px" }}></div>
                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <IonButton
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      if (button1namechangepassword === "Edit") {
                        setDisabledchangepassword(false);
                        setButton1namechangepassword("Cancel");
                      } else {
                        setDisabledchangepassword(true);
                        setButton1namechangepassword("Edit");
                      }
                    }}
                  >
                    {button1namechangepassword}
                  </IonButton>
                  <IonButton
                    disabled={disabledchangepassword}
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      handleEditChangePassword();
                    }}
                  >
                    Save
                  </IonButton>
                </div>
                <div style={{ height: "12px" }}></div>
              </form>
            )}

            {showservices && showModeratorBoard && !showadminlistview && (
              <form>
                <div className="border-bottom" style={{ height: "0px" }}></div>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Care Home Provider Room Availability:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div
                      className="form-floating text-center"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        marginLeft: "12px",
                        marginRight: "12px",
                        /* width: "100%", */
                        border: "1px solid black",
                        borderRadius: "6px",
                      }}
                    >
                      <p
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          textAlign: "center",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          fontSize: "large",
                        }}
                      >
                        Select Your Business Category. One(1) Care Home
                        (Physical Location) Per Account Profile, for Additional
                        Care Home Business Profiles Contact seniorHUBsolutions.
                      </p>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <p
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          textAlign: "left",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          fontSize: "large",
                        }}
                      >
                        <input
                          /* disabled={disabledservicetype} */
                          id="checkboxitem"
                          type="checkbox"
                          checked={servicetype1}
                          onChange={() => {
                            if (!servicetype1) {
                              setServicetype2(false);
                              setServicetype3(false);
                              setServicetype2availability(false);
                              setServicetype3availability(false);
                            }
                            setServicetype1(!servicetype1);
                          }}
                          style={
                            disabledservicetype
                              ? {
                                  pointerEvents: "none",
                                  display: "inline",
                                  backgroundColor: "white",
                                }
                              : {
                                  pointerEvents: "auto",
                                  display: "inline",
                                  backgroundColor: "white",
                                }
                          }
                        />
                        &nbsp;Adult Residential Care Homes (ARCH)
                      </p>
                      <div className="container" style={{ textAlign: "left" }}>
                        <div
                          className="form-check form-check-inline"
                          style={{
                            color: "black",
                            fontFamily: "AvantGarde Bk BT",
                          }}
                        >
                          <input
                            disabled={disabledservicetype}
                            checked={servicetype1availability ? true : false}
                            className="form-check-input"
                            type="Radio"
                            name="flexRadioDefault1"
                            id="flexRadioDefault1"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              backgroundColor: "black",
                              borderColor: "black",
                            }}
                            onChange={() => {
                              if (!servicetype1) {
                                setServicetype1availability(false);
                              } else {
                                setServicetype1availability(true);
                              }
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              fontSize: "medium",
                            }}
                          >
                            With Available Room
                          </label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          style={{
                            color: "black",
                            fontFamily: "AvantGarde Bk BT",
                          }}
                        >
                          <input
                            disabled={disabledservicetype}
                            checked={servicetype1availability ? false : true}
                            className="form-check-input"
                            type="Radio"
                            name="flexRadioDefault1"
                            id="flexRadioDefault2"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              backgroundColor: "black",
                              borderColor: "black",
                            }}
                            onChange={() => {
                              setServicetype1availability(false);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              fontSize: "medium",
                            }}
                          >
                            Without Available Room
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <p
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          textAlign: "left",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          fontSize: "large",
                        }}
                      >
                        <input
                          /* disabled={disabledservicetype} */
                          id="checkboxitem"
                          type="checkbox"
                          value="remember-me"
                          checked={servicetype2}
                          onChange={() => {
                            if (!servicetype2) {
                              setServicetype1(false);
                              setServicetype3(false);
                              setServicetype1availability(false);
                              setServicetype3availability(false);
                            }
                            setServicetype2(!servicetype2);
                          }}
                          style={
                            disabledservicetype
                              ? {
                                  pointerEvents: "none",
                                  display: "inline",
                                  backgroundColor: "white",
                                }
                              : {
                                  pointerEvents: "auto",
                                  display: "inline",
                                  backgroundColor: "white",
                                }
                          }
                        />
                        &nbsp;Adult Residential Care Homes (E-ARCH)
                      </p>
                      <div className="container" style={{ textAlign: "left" }}>
                        <div
                          className="form-check form-check-inline"
                          style={{
                            color: "black",
                            fontFamily: "AvantGarde Bk BT",
                          }}
                        >
                          <input
                            disabled={disabledservicetype}
                            checked={servicetype2availability ? true : false}
                            className="form-check-input"
                            type="Radio"
                            name="flexRadioDefault2"
                            id="flexRadioDefault1"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              backgroundColor: "black",
                              borderColor: "black",
                            }}
                            onChange={() => {
                              if (!servicetype2) {
                                setServicetype2availability(false);
                              } else {
                                setServicetype2availability(true);
                              }
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              fontSize: "medium",
                            }}
                          >
                            With Available Room
                          </label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          style={{
                            color: "black",
                            fontFamily: "AvantGarde Bk BT",
                          }}
                        >
                          <input
                            disabled={disabledservicetype}
                            checked={servicetype2availability ? false : true}
                            className="form-check-input"
                            type="Radio"
                            name="flexRadioDefault2"
                            id="flexRadioDefault2"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              backgroundColor: "black",
                              borderColor: "black",
                            }}
                            onChange={() => {
                              setServicetype2availability(false);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              fontSize: "medium",
                            }}
                          >
                            Without Available Room
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <p
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          textAlign: "left",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          fontSize: "large",
                        }}
                      >
                        <input
                          /* disabled={disabledservicetype} */
                          id="checkboxitem"
                          type="checkbox"
                          value="remember-me"
                          checked={servicetype3}
                          onChange={() => {
                            if (!servicetype3) {
                              setServicetype1(false);
                              setServicetype2(false);
                              setServicetype1availability(false);
                              setServicetype2availability(false);
                            }
                            setServicetype3(!servicetype3);
                          }}
                          style={
                            disabledservicetype
                              ? {
                                  pointerEvents: "none",
                                  display: "inline",
                                  backgroundColor: "white",
                                }
                              : {
                                  pointerEvents: "auto",
                                  display: "inline",
                                  backgroundColor: "white",
                                }
                          }
                        />
                        &nbsp;Adult Foster Care Homes (AFCH)
                      </p>
                      <div className="container" style={{ textAlign: "left" }}>
                        <div
                          className="form-check form-check-inline"
                          style={{
                            color: "black",
                            fontFamily: "AvantGarde Bk BT",
                          }}
                        >
                          <input
                            disabled={disabledservicetype}
                            checked={servicetype3availability ? true : false}
                            className="form-check-input"
                            type="Radio"
                            name="flexRadioDefault3"
                            id="flexRadioDefault1"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              backgroundColor: "black",
                              borderColor: "black",
                            }}
                            onChange={() => {
                              if (!servicetype3) {
                                setServicetype3availability(false);
                              } else {
                                setServicetype3availability(true);
                              }
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              fontSize: "medium",
                            }}
                          >
                            With Available Room
                          </label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          style={{
                            color: "black",
                            fontFamily: "AvantGarde Bk BT",
                          }}
                        >
                          <input
                            disabled={disabledservicetype}
                            checked={servicetype3availability ? false : true}
                            className="form-check-input"
                            type="Radio"
                            name="flexRadioDefault3"
                            id="flexRadioDefault2"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              backgroundColor: "black",
                              borderColor: "black",
                            }}
                            onChange={() => {
                              setServicetype3availability(false);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                            style={{
                              color: "black",
                              cursor: "pointer",
                              fontSize: "medium",
                            }}
                          >
                            Without Available Room
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: "12px" }}></div>
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "6px" }}></div>
                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <IonButton
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      if (button1nameservicetype === "Edit") {
                        setDisabledservicetype(false);
                        setButton1nameservicetype("Cancel");
                      } else {
                        setDisabledservicetype(true);
                        setButton1nameservicetype("Edit");
                      }
                    }}
                  >
                    {button1nameservicetype}
                  </IonButton>
                  <IonButton
                    disabled={disabledservicetype}
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      handleEditBasicInfo("2");
                    }}
                  >
                    Save
                  </IonButton>
                </div>
                {/* <div style={{ height: "12px" }}></div>
                
                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <div
                    style={{
                      padding: "0px",
                      margin: "0px",
                      textAlign: "center",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                  >
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      Service Type Guidelines:
                    </p>
                    <li
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      Please select one service type only
                    </li>
                    <li
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      Please remember to keep your Care Home Provider account current with up-to-date Room and Bed Availability
                    </li>
                  </div>
                </div> */}
                <div style={{ height: "12px" }}></div>
              </form>
            )}

            {showlisting && showModeratorBoard && !showadminlistview && (
              <form>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Care Home Provider Details Listing:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div className="container text-center">
                      <div
                        className="form-check form-check-inline container"
                        style={{
                          color: "black",
                          padding: "0px",
                          fontFamily: "AvantGarde Bk BT",
                          margin: "0px",
                        }}
                      >
                        <a
                          className="nav-link dropdown-toggle" //dropdown-toggle
                          href="#"
                          id="dropdown01"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            whiteSpace: "normal",
                            textAlign: "left",
                            padding: "0px",
                            color: "black",
                            /* width: "100px", */
                          }}
                        >
                          Provider Details Listing Category:&nbsp;
                          {selectedlistingcategory}:
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdown01"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={(e) => {
                                var inputraw: any = [];
                                if (disableddetailslisting) {
                                  currentUser.providerdetailslistingsection1.map(
                                    (data: any) => {
                                      inputraw.push(
                                        data.providerdetailslistingtypedescription
                                      );
                                    }
                                  );
                                  //setListingcategory(inputraw);
                                  setListingcategory1(inputraw);
                                } /* else if (!disableddetailslisting) {
                                  listingcategory1.map((data: any) => {
                                    inputraw.push(data);
                                  });
                                  setListingcategory(inputraw);
                                } */
                                /* console.log(listingcategory1);
                                console.log(listingcategory); */
                                setSelectedlistingcategory("Room Details");
                              }}
                            >
                              Room Details
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                var inputraw: any = [];
                                if (disableddetailslisting) {
                                  currentUser.providerdetailslistingsection2.map(
                                    (data: any) => {
                                      inputraw.push(
                                        data.providerdetailslistingtypedescription
                                      );
                                    }
                                  );
                                  //setListingcategory(inputraw);
                                  setListingcategory2(inputraw);
                                } /* else if (!disableddetailslisting) {
                                  listingcategory2.map((data: any) => {
                                    inputraw.push(data);
                                  });
                                  setListingcategory(inputraw);
                                } */
                                /* console.log(listingcategory2);
                                console.log(listingcategory); */
                                setSelectedlistingcategory("Room Features");
                              }}
                            >
                              Room Features
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                var inputraw: any = [];
                                if (disableddetailslisting) {
                                  currentUser.providerdetailslistingsection3.map(
                                    (data: any) => {
                                      inputraw.push(
                                        data.providerdetailslistingtypedescription
                                      );
                                    }
                                  );
                                  //setListingcategory(inputraw);
                                  setListingcategory3(inputraw);
                                } /* else if (!disableddetailslisting) {
                                  listingcategory3.map((data: any) => {
                                    inputraw.push(data);
                                  });
                                  setListingcategory(inputraw);
                                } */
                                /* console.log(listingcategory3);
                                console.log(listingcategory); */
                                setSelectedlistingcategory("Services Provided");
                              }}
                            >
                              Services Provided
                            </a>
                          </li>
                        </ul>

                        <ul>
                          {(!listingcategory1 ||
                            listingcategory1.length === 0) && (
                            <li>
                              <div
                                className="form-floating"
                                style={{
                                  textAlign: "center",
                                  marginLeft: "12px",
                                  marginRight: "12px",
                                  paddingBottom: "6px",
                                }}
                              >
                                <input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  /* id="floatingInput" */
                                  /* placeholder="Doe" */
                                  value="..No data.."
                                  /* onChange={(e) => setFirstname(e.target.value!)} */
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    paddingTop: "10px",
                                  }}
                                />
                              </div>
                            </li>
                          )}
                          {selectedlistingcategory === "Room Details" &&
                            listingcategory1.map((data: any, index: any) => {
                              return (
                                <li key={index}>
                                  <div
                                    className="form-floating"
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    <div
                                      className="text-justify col-lg-11" //text-center
                                      style={{
                                        margin: "0px",
                                        padding: "0px",
                                        marginRight: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      <input
                                        disabled={disableddetailslisting}
                                        type="text"
                                        className="form-control"
                                        /* id="floatingInput" */
                                        /* placeholder="Doe" */
                                        /* value={listingcategory1[index]} */
                                        defaultValue={listingcategory1[index]}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          listingcategory1[
                                            index
                                          ] = e.currentTarget.value!;
                                        }}
                                        style={{
                                          display: "inline",
                                          fontFamily: "Abadi MT Std",
                                          color: "black",
                                          paddingTop: "10px",
                                          cursor: "pointer",
                                          width: "calc(100% - 42px)",
                                        }}
                                      />
                                      {/* <label
                                      style={{
                                        fontFamily: "Abadi MT Std",
                                        color: "black",
                                        textAlign: "left",
                                      }}
                                    >
                                      {selectedlistingcategory}
                                    </label> */}
                                      <IonButton
                                        disabled={disableddetailslisting}
                                        color="warning"
                                        id="backgroundbuttonremove"
                                        className="col-lg-1"
                                        style={{
                                          /* backgroundColor: "#8a1f61", */
                                          float: "right",
                                          display: "inline",
                                          /* color: "white", */
                                          height: "40px",
                                          /* width: "120px", */
                                          /* fontSize: "medium", */
                                          padding: "0px",
                                          margin: "0px",
                                          width: "40px",
                                          fontWeight: "bold",
                                          fontSize: "large",
                                        }}
                                        onClick={() => {
                                          setIndexmodifyline(index);
                                          setModemodifyline(false);
                                          setShowloadingmodifyline(true);
                                          /* let templistingcategory: any = listingcategory1;
                                          let newarr: any = [];
                                          templistingcategory.map((item: any, indexhere: any) => {
                                            if (item !== listingcategory1[index]) {
                                              newarr.push(item) 
                                            };
                                          });
                                          setListingcategory1(newarr);
                                          setSelectedlistingcategory("");
                                          setSelectedlistingcategory("Room Details"); */
                                        }}
                                      >
                                        ×
                                      </IonButton>
                                    </div>
                                  </div>
                                  <div style={{ height: "6px" }}></div>
                                </li>
                              );
                            })}
                          {(!listingcategory2 ||
                            listingcategory2.length === 0) && (
                            <li>
                              <div
                                className="form-floating"
                                style={{
                                  textAlign: "center",
                                  marginLeft: "12px",
                                  marginRight: "12px",
                                  paddingBottom: "6px",
                                }}
                              >
                                <input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  /* id="floatingInput" */
                                  /* placeholder="Doe" */
                                  value="..No data.."
                                  /* onChange={(e) => setFirstname(e.target.value!)} */
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    paddingTop: "10px",
                                  }}
                                />
                              </div>
                            </li>
                          )}
                          {selectedlistingcategory === "Room Features" &&
                            listingcategory2.map((data: any, index: any) => {
                              return (
                                <li key={index}>
                                  <div
                                    className="form-floating"
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    <div
                                      className="text-justify col-lg-11" //text-center
                                      style={{
                                        margin: "0px",
                                        padding: "0px",
                                        marginRight: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      <input
                                        disabled={disableddetailslisting}
                                        type="text"
                                        className="form-control"
                                        /* id="floatingInput" */
                                        /* placeholder="Doe" */
                                        /* value={listingcategory2[index]} */
                                        defaultValue={listingcategory2[index]}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          listingcategory2[
                                            index
                                          ] = e.currentTarget.value!;
                                        }}
                                        style={{
                                          display: "inline",
                                          fontFamily: "Abadi MT Std",
                                          color: "black",
                                          paddingTop: "10px",
                                          cursor: "pointer",
                                          width: "calc(100% - 42px)",
                                        }}
                                      />
                                      {/* <label
                                        style={{
                                          fontFamily: "Abadi MT Std",
                                          color: "black",
                                          textAlign: "left",
                                        }}
                                      >
                                        {selectedlistingcategory}
                                      </label> */}
                                      <IonButton
                                        disabled={disableddetailslisting}
                                        color="warning"
                                        id="backgroundbuttonremove"
                                        className="col-lg-1"
                                        style={{
                                          /* backgroundColor: "#8a1f61", */
                                          float: "right",
                                          display: "inline",
                                          /* color: "white", */
                                          height: "40px",
                                          /* width: "120px", */
                                          /* fontSize: "medium", */
                                          padding: "0px",
                                          margin: "0px",
                                          width: "40px",
                                          fontWeight: "bold",
                                          fontSize: "large",
                                        }}
                                        onClick={() => {
                                          setIndexmodifyline(index);
                                          setModemodifyline(false);
                                          setShowloadingmodifyline(true);
                                          /* let templistingcategory: any = listingcategory2;
                                          let newarr: any = [];
                                          templistingcategory.map((item: any, indexhere: any) => {
                                            if (item !== listingcategory2[index]) {
                                              newarr.push(item) 
                                            };
                                          });
                                          setListingcategory2(newarr);
                                          setSelectedlistingcategory("");
                                          setSelectedlistingcategory("Room Features"); */
                                        }}
                                      >
                                        ×
                                      </IonButton>
                                    </div>
                                  </div>
                                  <div style={{ height: "6px" }}></div>
                                </li>
                              );
                            })}
                          {(!listingcategory3 ||
                            listingcategory3.length === 0) && (
                            <li>
                              <div
                                className="form-floating"
                                style={{
                                  textAlign: "center",
                                  marginLeft: "12px",
                                  marginRight: "12px",
                                  paddingBottom: "6px",
                                }}
                              >
                                <input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  /* id="floatingInput" */
                                  /* placeholder="Doe" */
                                  value="..No data.."
                                  /* onChange={(e) => setFirstname(e.target.value!)} */
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    paddingTop: "10px",
                                  }}
                                />
                              </div>
                            </li>
                          )}
                          {selectedlistingcategory === "Services Provided" &&
                            listingcategory3.map((data: any, index: any) => {
                              return (
                                <li key={index}>
                                  <div
                                    className="form-floating"
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    <div
                                      className="text-justify col-lg-11" //text-center
                                      style={{
                                        margin: "0px",
                                        padding: "0px",
                                        marginRight: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      <input
                                        disabled={disableddetailslisting}
                                        type="text"
                                        className="form-control"
                                        /* id="floatingInput" */
                                        /* placeholder="Doe" */
                                        /* value={listingcategory3[index]} */
                                        defaultValue={listingcategory3[index]}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          listingcategory3[
                                            index
                                          ] = e.currentTarget.value!;
                                        }}
                                        style={{
                                          display: "inline",
                                          fontFamily: "Abadi MT Std",
                                          color: "black",
                                          paddingTop: "10px",
                                          cursor: "pointer",
                                          width: "calc(100% - 42px)",
                                        }}
                                      />
                                      {/* <label
                                      style={{
                                        fontFamily: "Abadi MT Std",
                                        color: "black",
                                        textAlign: "left",
                                      }}
                                    >
                                      {selectedlistingcategory}
                                    </label> */}
                                      <IonButton
                                        disabled={disableddetailslisting}
                                        color="warning"
                                        id="backgroundbuttonremove"
                                        className="col-lg-1"
                                        style={{
                                          /* backgroundColor: "#8a1f61", */
                                          float: "right",
                                          display: "inline",
                                          /* color: "white", */
                                          height: "40px",
                                          /* width: "120px", */
                                          /* fontSize: "medium", */
                                          padding: "0px",
                                          margin: "0px",
                                          width: "40px",
                                          fontWeight: "bold",
                                          fontSize: "large",
                                        }}
                                        onClick={() => {
                                          setIndexmodifyline(index);
                                          setModemodifyline(false);
                                          setShowloadingmodifyline(true);
                                          /* let templistingcategory: any = listingcategory3;
                                          let newarr: any = [];
                                          templistingcategory.map((item: any, indexhere: any) => {
                                            if (item !== listingcategory3[index]) {
                                              newarr.push(item) 
                                            };
                                          });
                                          setListingcategory3(newarr);
                                          setSelectedlistingcategory("");
                                          setSelectedlistingcategory("Services Provided"); */
                                        }}
                                      >
                                        ×
                                      </IonButton>
                                    </div>
                                  </div>
                                  <div style={{ height: "6px" }}></div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                {!disableddetailslisting && (
                  <div
                    className="text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      marginRight: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    <IonButton
                      style={{
                        /* disabled={disableddetailslisting} */
                        color: "#8f6b00",
                        height: "40px",
                        width: "120px",
                        fontSize: "medium",
                      }}
                      onClick={() => {
                        if (selectedlistingcategory === "Room Details") {
                          setIndexmodifyline(listingcategory1.length - 1);
                        } else if (
                          selectedlistingcategory === "Room Features"
                        ) {
                          setIndexmodifyline(listingcategory2.length - 1);
                        } else if (
                          selectedlistingcategory === "Services Provided"
                        ) {
                          setIndexmodifyline(listingcategory3.length - 1);
                        }
                        setModemodifyline(true);
                        setShowloadingmodifyline(true);
                      }}
                    >
                      Add Row
                    </IonButton>
                    <IonButton
                      /* disabled={disableddetailslisting} */
                      style={{
                        color: "#8f6b00",
                        height: "40px",
                        width: "180px",
                        fontSize: "medium",
                      }}
                      onClick={() => {
                        setIswarning2(true);
                        setMessagelocal(
                          `Any changes you made in ${selectedlistingcategory} will be overwritten. Do you want to continue?`
                        );
                      }}
                    >
                      Use Default Values
                    </IonButton>
                  </div>
                )}
                <div style={{ height: "6px" }}></div>
                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <IonButton
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      if (button1namedetailslisting === "Edit") {
                        setDisableddetailslisting(false);
                        setButton1namedetailslisting("Cancel");
                      } else {
                        setDisableddetailslisting(true);
                        setButton1namedetailslisting("Edit");
                      }
                    }}
                  >
                    {button1namedetailslisting}
                  </IonButton>
                  <IonButton
                    disabled={disableddetailslisting}
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      /* setMessagelocal(
                        "Any changes you made here will make your Account status subject for approval again. Do you want to continue?"
                      );
                      setIswarning(true); */
                      handleEditBasicInfo("3");
                    }}
                  >
                    Save
                  </IonButton>
                </div>
                <div style={{ height: "12px" }}></div>
              </form>
            )}

            {showmap && showModeratorBoard && !showadminlistview && (
              <form>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Care Home Provider Location Map:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                  >
                    {/* <div
                      className="form-floating text-center"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        marginLeft: "12px",
                        marginRight: "12px",
                        //width: "100%",
                        border: "1px solid black",
                        borderRadius: "6px",
                      }}
                    >
                      <p
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          textAlign: "center",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          fontSize: "large",
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Change Pin Location in the Map
                      </p> */}
                    {/* <a
                        style={{
                          textAlign: "left",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          display: "block",
                          fontWeight: "bold",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          textDecoration: "underline",
                        }}
                      >
                        Sample
                      </a> */}
                    {/* <ul>
                        <li
                          style={{
                            textAlign: "left",
                            fontFamily: "AvantGarde Bk BT",
                            color: "black",
                            fontSize: "medium",
                          }}
                        >
                          <a
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                            }}
                          >
                            Change the pin by clicking anywhere in the map to reflect your exact address.
                          </a>
                        </li> */}
                    {/* <li
                          style={{
                            textAlign: "left",
                            fontFamily: "AvantGarde Bk BT",
                            color: "black",
                            fontSize: "medium",
                          }}
                        >
                          <a
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                            }}
                          >
                            Upon confirmation of payment your Care Home Provider Account will be
                            approved and active. Your business information will be live and published.
                          </a>
                        </li> */}
                    {/* </ul>
                      <div style={{ height: "6px" }}></div> */}
                    {/* <a
                        style={{
                          textAlign: "left",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          display: "block",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          textDecoration: "underline",
                        }}
                      >
                        Payment/Refund Policy
                      </a>
                      <a
                        style={{
                          textAlign: "justify",
                          fontFamily: "AvantGarde Bk BT",
                          fontSize: "medium",
                          color: "black",
                          display: "block",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          width: "100%",
                        }}
                      >
                        Due to the non-returnable nature of digital products and the annual member service,
                        Senior HUB Solutions LLC does not issue refunds.
                      </a> 
                      <a
                        style={{
                          textAlign: "justify",
                          fontFamily: "AvantGarde Bk BT",
                          fontSize: "medium",
                          color: "black",
                          display: "block",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          width: "100%",
                        }}
                      >
                        No reimbursement for the unfulfilled payment or cancellation of the annual member
                        service payment plan.
                      </a>  */}
                    {/* <div style={{ height: "6px" }}></div> */}
                    {/* </div> */}
                    <div style={{ height: "12px" }}></div>

                    {/* <MapsUser onedit={disabledmap}/> */}
                    <div
                      className="container"
                      style={{
                        height: "100%",
                        width: "100%",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        <iframe
                          width="100%"
                          height="500px"
                          id="gmap_canvas"
                          src={`https://maps.google.com/maps?q=${providerbusinessaddress.replace(
                            " ",
                            "%20"
                          )},${city},${state}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                          frameBorder="0"
                          scrolling="no"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                {/* <div style={{ height: "6px" }}></div>
                <div
                    className="text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      marginRight: "0px",
                      paddingRight: "0px",
                    }}
                >
                  <IonButton
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "180px",
                      fontSize: "medium",
                    }}
                    onClick={launchLink7}
                  >
                    View in Google Maps
                  </IonButton>
                </div>
                <div style={{ height: "6px" }}></div>
                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <IonButton
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={()=>{
                      if (button1namemap === "Edit") {
                        setDisabledmap(false);
                        setButton1namemap("Cancel");
                      } else {
                        setDisabledmap(true);
                        setButton1namemap("Edit");
                        }
                    }}
                  >
                    {button1namemap}
                  </IonButton>
                  <IonButton
                    disabled={disabledmap}
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={()=>{
                      handleEditMap();
                    }}
                  >
                    Save
                  </IonButton>
                </div> */}
                <div style={{ height: "12px" }}></div>
              </form>
            )}

            {showimages && showModeratorBoard && !showadminlistview && (
              <form>
                {/* <form onSubmit={(e: any) => handleFileUpload(e)}> */}
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Care Home Provider Image:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    {/* {preview && (
                      <img
                        src={preview}
                        alt="Preview of the file to be uploaded"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          height: "relative",
                          display: "block",
                          margin: "0px",
                          padding: "0px",
                          color: "black",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                      />
                    )} */}
                    {/* <div style={{ height: "12px" }}></div> */}
                    <div
                      style={{
                        padding: "0px",
                        margin: "0px",
                        textAlign: "center",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                      }}
                    >
                      {/* <input
                        style={{
                          whiteSpace: "normal",
                          display: "flex",
                          margin: "0px",
                          padding: "0px",
                          color: "black",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          width: "100%",
                        }}
                        type="file"
                        onChange={onFileSelected}
                      /> */}
                      <div style={{ height: "6px" }}></div>
                      <p
                        className="featurette-heading"
                        style={{
                          padding: "0px",
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginLeft: "0px",
                          marginRight: "0px",
                          textAlign: "center",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          fontSize: "large",
                          fontWeight: "bold",
                        }}
                      >
                        Provider Preview of Current Image:
                      </p>
                      <div style={{ height: "6px" }}></div>
                      <img
                        src={imagelink + imagetoken}
                        alt="Current preview"
                        style={{
                          textAlign: "center",
                          /* width: "100%",
                          height: "relative", */
                          width: "100%",
                          height: "auto",
                          /* minWidth: "560px",
                          minHeight: "300px", */
                          display: "block",
                          margin: "0px",
                          padding: "0px",
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "6px",
                        }}
                      />
                      <div style={{ height: "12px" }}></div>
                      <p
                        className="featurette-heading"
                        style={{
                          padding: "0px",
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginLeft: "0px",
                          marginRight: "0px",
                          textAlign: "center",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          fontSize: "large",
                          fontWeight: "bold",
                        }}
                      >
                        Upload New Provider Photo:
                      </p>
                      <div style={{ height: "6px" }}></div>
                      <label
                        className="uploader"
                        style={{
                          margin: "0px",
                          padding: "0px",
                          width: "100%",
                          height: "50vh",
                        }}
                      >
                        <div
                          className="upload-space"
                          style={{
                            margin: "0px",
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          {isLoading ? (
                            <>
                              <div style={{ height: "20vh" }}></div>
                              <Spinner />
                            </>
                          ) : (
                            <>
                              {isError || isSuccess ? (
                                <i
                                  className={`icon-${
                                    isSuccess ? "success" : "error"
                                  }`}
                                ></i>
                              ) : (
                                <>
                                  {preview ? (
                                    <div className="preview">
                                      <img
                                        src={preview}
                                        alt="Preview of the file to be uploaded"
                                      />
                                    </div>
                                  ) : (
                                    <i className="icon-upload"> </i>
                                  )}
                                  <input
                                    type="file"
                                    onChange={onFileSelected}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div style={{ height: "12px" }}></div>
                        {isError || isSuccess ? (
                          <p className={isSuccess ? "success" : "error"}>
                            {isSuccess
                              ? currentUser.approvedprovider
                                ? `Your Provider Account Image has been updated. An image change will place your Account "On Hold" and will be reviewed for approval.`
                                : "Your Provider Account Image has been updated."
                              : "Something went wrong ..."}
                          </p>
                        ) : (
                          <p className="filename">
                            {fileName ? fileName : "No file selected yet"}
                          </p>
                        )}
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "12px" }}></div>
                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <IonButton
                    /* type="submit" */
                    disabled={isDisabled}
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "268px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      if (
                        currentUser.providerimageslinks[0]
                          .providerimagetoken ===
                          "272be1a5-abab-4d60-8470-5600a5b40c40" ||
                        !currentUser.approvedprovider
                      ) {
                        handleFileUpload();
                      } else {
                        setMessagelocal(
                          `An image change will place your account "On Hold" and will be reviewed for approval. Do you want to continue?`
                        );
                        setIswarning(true);
                      }
                    }}
                  >
                    {buttonText}
                  </IonButton>
                </div>
                <div style={{ height: "12px" }}></div>

                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <div
                    style={{
                      padding: "0px",
                      margin: "0px",
                      textAlign: "center",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                  >
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      Photo Upload Recommendation:
                    </p>
                    <li
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      Outside View of your Home or Facility
                    </li>
                    <li
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      Interior View of the Living Area
                    </li>
                    <li
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      No Personal Photos of Clients
                    </li>
                    <li
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      File size must be 3mb or less
                    </li>
                    <li
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      File type must be .png or .jpg/.jpeg only
                    </li>
                    <li
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontFamily: "Abadi MT Std",
                        fontSize: "small",
                        margin: "0px",
                      }}
                    >
                      Photo must be in Landscape orientation
                    </li>
                  </div>
                </div>
                <div style={{ height: "12px" }}></div>
              </form>
            )}
            {showforpayment && showAdminBoard && !showadminlistview && (
              <form>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Accounts Pending Payment and Accounts On Hold:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div
                      className="text-center"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        marginRight: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <IonButton
                        color={`${
                          disabledforpaymentbutton1 ? "warning" : "primary"
                        }`}
                        id="backgroundbuttonforpayment"
                        style={{
                          //color: "#8f6b00",
                          height: "40px",
                          width: "160px",
                          fontSize: "medium",
                        }}
                        onClick={() => {
                          setDisabledforpaymentbutton1(false);
                          setDisabledforpaymentbutton2(true);
                          setDisabledforpaymentbutton3(true);

                          setShowloadingfilterforpayment(true);
                        }}
                      >
                        All Accounts
                      </IonButton>
                      <IonButton
                        color={`${
                          disabledforpaymentbutton2 ? "warning" : "primary"
                        }`}
                        id="backgroundbuttonforpayment"
                        style={{
                          //color: "#8f6b00",
                          height: "40px",
                          width: "240px",
                          fontSize: "medium",
                        }}
                        onClick={() => {
                          setDisabledforpaymentbutton1(true);
                          setDisabledforpaymentbutton2(false);
                          setDisabledforpaymentbutton3(true);

                          setShowloadingfilterforpayment(true);
                        }}
                      >
                        New Acct (Pending Payment)
                      </IonButton>
                      <IonButton
                        color={`${
                          disabledforpaymentbutton3 ? "warning" : "primary"
                        }`}
                        id="backgroundbuttonforpayment"
                        style={{
                          //color: "#8f6b00",
                          height: "40px",
                          width: "160px",
                          fontSize: "medium",
                        }}
                        onClick={() => {
                          setDisabledforpaymentbutton1(true);
                          setDisabledforpaymentbutton2(true);
                          setDisabledforpaymentbutton3(false);

                          setShowloadingfilterforpayment(true);
                        }}
                      >
                        On Hold
                      </IonButton>
                    </div>
                    <div style={{ height: "6px" }}></div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        //ref={(ref) => (inputRefwidth.current = ref)}
                        disabled
                        type="text"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="Provider ID, Provider Business Name, Email, Contact No" */
                        value="Provider ID, Provider Business Name, Email, Contact No"
                        /* onChange={(e) => setFirstname(e.target.value!)} */
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          paddingTop: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#f4ecd5",
                        }}
                      />
                    </div>
                    <div style={{ height: "6px" }}></div>

                    {/* //================================= */}
                    {(!datavalue1 || datavalue1.length === 0) && (
                      <div
                        className="form-floating"
                        style={{
                          textAlign: "center",
                          marginLeft: "12px",
                          marginRight: "12px",
                          paddingBottom: "6px",
                        }}
                      >
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          /* id="floatingInput" */
                          /* placeholder="Provider ID, Provider Business Name, Email, Contact No" */
                          value="..No retrieved data.."
                          /* onChange={(e) => setFirstname(e.target.value!)} */
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                            paddingTop: "10px",
                          }}
                        />
                      </div>
                    )}

                    {datavalue1 &&
                      datavalue1.map((data: any, index: any) => {
                        return (
                          <div
                            key={index}
                            className="form-floating"
                            style={{
                              textAlign: "justify",
                              marginLeft: "12px",
                              marginRight: "12px",
                              paddingBottom: "6px",
                            }}
                          >
                            <div
                              className="text-justify" //text-center
                              style={{
                                margin: "0px",
                                padding: "0px",
                                marginRight: "0px",
                                paddingRight: "0px",
                              }}
                            >
                              <div
                                className="text-justify col-lg-11" //text-center
                                style={{
                                  display: "inline",
                                  margin: "0px",
                                  padding: "0px",
                                  marginRight: "0px",
                                  paddingRight: "0px",
                                }}
                                onClick={() => {
                                  /* const datavalue: any = {
                                    galleryuseruniquenumber:
                                      data.galleryuseruniquenumber,
                                    galleryimage:
                                      data.galleryimage.galleryimagelink +
                                      "" +
                                      data.galleryimage.galleryimagetoken,
                                    galleryviewname: data.galleryviewname,
                                    gallerydescription: data.gallerydescription,
                                    galleryaddress: data.galleryaddress,
                                    //gallerycityandstateorlocation:
                                    //data.gallerycityandstateorlocation,
                                    galleryzipcode: data.galleryzipcode,
                                    gallerycontactnumber1:
                                      data.gallerycontactnumber1,
                                    gallerycontactnumber2:
                                      data.gallerycontactnumber2,
                                    galleryemail: data.galleryemail,
                                    gallerylat: data.gallerylat,
                                    gallerylng: data.gallerylng,
                                    gallerycity: data.gallerycity,
                                    gallerystate: data.gallerystate,
                                    gallerydetailslistingsection1:
                                      data.gallerydetailslistingdesc
                                        .gallerydetailslistingsection1,
                                    gallerydetailslistingsection2:
                                      data.gallerydetailslistingdesc
                                        .gallerydetailslistingsection2,
                                    gallerydetailslistingsection3:
                                      data.gallerydetailslistingdesc
                                        .gallerydetailslistingsection3,
                                  }; */
                                  /* localStorage.setItem(
                                    "adminlist",
                                    JSON.stringify(datavalue)
                                  ); */
                                  setGalleryuseruniquenumber(
                                    data.useruniquenumber
                                  );
                                  setGalleryimage(
                                    data.galleryimage.galleryimagelink +
                                      "" +
                                      data.galleryimage.galleryimagetoken
                                  );
                                  setGalleryimagetoken(
                                    data.galleryimage.galleryimagetoken
                                  );
                                  setGalleryviewname(data.galleryviewname);
                                  setGallerydescription(
                                    data.gallerydescription
                                  );
                                  setGalleryaddress(data.galleryaddress);
                                  setGalleryzipcode(data.galleryzipcode);
                                  setGallerycontactnumber1(
                                    data.gallerycontactnumber1
                                  );
                                  setGallerycontactnumber2(
                                    data.gallerycontactnumber2
                                  );
                                  setGalleryemail(data.galleryemail);
                                  setGallerycity(data.gallerycity);
                                  setGallerystate(data.gallerystate);
                                  setGallerysection1(
                                    data.gallerydetailslistingdesc
                                      .gallerydetailslistingsection1
                                  );
                                  setGallerysection2(
                                    data.gallerydetailslistingdesc
                                      .gallerydetailslistingsection2
                                  );
                                  setGallerysection3(
                                    data.gallerydetailslistingdesc
                                      .gallerydetailslistingsection3
                                  );

                                  setShowadminlistview(true);
                                }}
                              >
                                <input
                                  disabled
                                  type="text"
                                  className="form-control" //
                                  /* id="floatingInput" */
                                  /* placeholder="Provider ID, Provider Business Name, Email, Contact No" */
                                  value={`${data.useruniquenumber}, ${data.galleryviewname}, ${data.galleryemail}, ${data.gallerycontactnumber1}`}
                                  /* onChange={(e) => setFirstname(e.target.value!)} */
                                  /* style={
                                    width > 0
                                      ? {
                                          display: "inline",
                                          fontFamily: "Abadi MT Std",
                                          color: "black",
                                          paddingTop: "10px",
                                          cursor: "pointer",
                                          width: `calc(${width}px - 42px)`, //`calc(${inputRefwidth.current.clientWidth}px - 40px)` //`calc(${width}px - 42px)`
                                        }
                                      : {
                                          display: "inline",
                                          fontFamily: "Abadi MT Std",
                                          color: "black",
                                          paddingTop: "10px",
                                          cursor: "pointer",
                                          width: `calc(${inputRefwidth.current.clientWidth}px - 40px)`, //`calc(${inputRefwidth.current.clientWidth}px - 40px)` //
                                        }
                                  } */
                                  style={{
                                    display: "inline",
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    paddingTop: "10px",
                                    cursor: "pointer",
                                    width: "calc(100% - 42px - 42px)",
                                  }}
                                />
                              </div>
                              <IonButton
                                className="col-lg-1"
                                style={{
                                  float: "right",
                                  display: "inline",
                                  color: "#8f6b00",
                                  height: "40px",
                                  /* width: "120px", */
                                  /* fontSize: "medium", */
                                  padding: "0px",
                                  margin: "0px",
                                  width: "40px",
                                  fontWeight: "bold",
                                  fontSize: "large",
                                  paddingLeft: "2px",
                                }}
                                onClick={() => {
                                  setSelectedoption1(true);
                                  setSelectedoption2(false);
                                  setSelecteduseruniquenumber(
                                    datavalue1[index].useruniquenumber
                                  );
                                  setGalleryimagetoken(
                                    datavalue1[index].galleryimage
                                      .galleryimagetoken
                                  );
                                  setGalleryaddress(
                                    datavalue1[index].galleryaddress
                                  );
                                  setGallerycity(datavalue1[index].gallerycity);
                                  setGallerystate(
                                    datavalue1[index].gallerystate
                                  );
                                  setGalleryzipcode(
                                    datavalue1[index].galleryzipcode
                                  );
                                  setMessagelocal(
                                    "Are you sure you want change this account into Paid and Approved status?"
                                  );
                                  setIswarning3(true);
                                }}
                              >
                                $✓
                              </IonButton>
                              <IonButton
                                className="col-lg-1"
                                style={{
                                  float: "right",
                                  display: "inline",
                                  color: "#8f6b00",
                                  height: "40px",
                                  /* width: "120px", */
                                  /* fontSize: "medium", */
                                  padding: "0px",
                                  margin: "0px",
                                  width: "40px",
                                  fontWeight: "bold",
                                  fontSize: "large",
                                }}
                                onClick={() => {
                                  setSelectedoption1(false);
                                  setSelectedoption2(false);
                                  setSelecteduseruniquenumber(
                                    datavalue1[index].useruniquenumber
                                  );
                                  setMessagelocal(
                                    "Are you sure you want change this account into Paid status?"
                                  );
                                  setIswarning3(true);
                                }}
                              >
                                $
                              </IonButton>
                            </div>
                          </div>
                        );
                      })}

                    {/* <div style={{ height: "6px" }}></div> */}
                    {/* //================================= bob*/}
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "12px" }}></div>
              </form>
            )}
            {showforapproval && showAdminBoard && !showadminlistview && (
              <form>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Pending Payment and Accounts on Hold for Review:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        //ref={(ref) => (inputRefwidth.current = ref)}
                        disabled
                        type="text"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="Provider ID, Provider Business Name, Email, Contact No" */
                        value="Provider ID, Provider Business Name, Email, Contact No"
                        /* onChange={(e) => setFirstname(e.target.value!)} */
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          paddingTop: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#f4ecd5",
                        }}
                      />
                    </div>
                    <div style={{ height: "6px" }}></div>

                    {/* //================================= */}
                    {(!datavalue2 || datavalue2.length === 0) && (
                      <div
                        className="form-floating"
                        style={{
                          textAlign: "center",
                          marginLeft: "12px",
                          marginRight: "12px",
                          paddingBottom: "6px",
                        }}
                      >
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          /* id="floatingInput" */
                          /* placeholder="Provider ID, Provider Business Name, Email, Contact No" */
                          value="..No retrieved data.."
                          /* onChange={(e) => setFirstname(e.target.value!)} */
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                            paddingTop: "10px",
                          }}
                        />
                      </div>
                    )}

                    {datavalue2 &&
                      datavalue2.map((data: any, index: any) => {
                        return (
                          <div
                            key={index}
                            className="form-floating"
                            style={{
                              textAlign: "justify",
                              marginLeft: "12px",
                              marginRight: "12px",
                              paddingBottom: "6px",
                            }}
                          >
                            <div
                              className="text-justify" //text-center
                              style={{
                                margin: "0px",
                                padding: "0px",
                                marginRight: "0px",
                                paddingRight: "0px",
                              }}
                            >
                              <div
                                className="text-justify col-lg-11" //text-center
                                style={{
                                  display: "inline",
                                  margin: "0px",
                                  padding: "0px",
                                  marginRight: "0px",
                                  paddingRight: "0px",
                                }}
                                onClick={() => {
                                  setGalleryuseruniquenumber(
                                    data.useruniquenumber
                                  );
                                  setGalleryimage(
                                    data.galleryimage.galleryimagelink +
                                      "" +
                                      data.galleryimage.galleryimagetoken
                                  );
                                  setGalleryimagetoken(
                                    data.galleryimage.galleryimagetoken
                                  );
                                  setGalleryviewname(data.galleryviewname);
                                  setGallerydescription(
                                    data.gallerydescription
                                  );
                                  setGalleryaddress(data.galleryaddress);
                                  setGalleryzipcode(data.galleryzipcode);
                                  setGallerycontactnumber1(
                                    data.gallerycontactnumber1
                                  );
                                  setGallerycontactnumber2(
                                    data.gallerycontactnumber2
                                  );
                                  setGalleryemail(data.galleryemail);
                                  setGallerycity(data.gallerycity);
                                  setGallerystate(data.gallerystate);
                                  setGallerysection1(
                                    data.gallerydetailslistingdesc
                                      .gallerydetailslistingsection1
                                  );
                                  setGallerysection2(
                                    data.gallerydetailslistingdesc
                                      .gallerydetailslistingsection2
                                  );
                                  setGallerysection3(
                                    data.gallerydetailslistingdesc
                                      .gallerydetailslistingsection3
                                  );

                                  setShowadminlistview(true);
                                }}
                              >
                                <input
                                  disabled
                                  type="text"
                                  className="form-control" //
                                  /* id="floatingInput" */
                                  /* placeholder="Provider ID, Provider Business Name, Email, Contact No" */
                                  value={`${data.useruniquenumber}, ${data.galleryviewname}, ${data.galleryemail}, ${data.gallerycontactnumber1}`}
                                  /* onChange={(e) => setFirstname(e.target.value!)} */
                                  style={{
                                    display: "inline",
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    paddingTop: "10px",
                                    cursor: "pointer",
                                    width: "calc(100% - 42px)",
                                  }}
                                />
                              </div>
                              <IonButton
                                className="col-lg-1"
                                style={{
                                  float: "right",
                                  display: "inline",
                                  color: "#8f6b00",
                                  height: "40px",
                                  /* width: "120px", */
                                  /* fontSize: "medium", */
                                  padding: "0px",
                                  margin: "0px",
                                  width: "40px",
                                  fontWeight: "bold",
                                  fontSize: "large",
                                }}
                                onClick={() => {
                                  setSelectedoption1(false);
                                  setSelectedoption2(false);
                                  setSelecteduseruniquenumber(
                                    datavalue2[index].useruniquenumber
                                  );
                                  setMessagelocal(
                                    "Are you sure you want change this account into Approved status?"
                                  );
                                  setIswarning3(true);
                                }}
                              >
                                ✓
                              </IonButton>
                            </div>
                          </div>
                        );
                      })}

                    {/* <div style={{ height: "6px" }}></div> */}
                    {/* //================================= bob*/}
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "12px" }}></div>
              </form>
            )}
            {showapprovedproviderlist && showAdminBoard && !showadminlistview && (
              <form>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Paid and Approved:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <input
                        //ref={(ref) => (inputRefwidth.current = ref)}
                        disabled
                        type="text"
                        className="form-control"
                        /* id="floatingInput" */
                        /* placeholder="Provider ID, Provider Business Name, Email, Contact No" */
                        value="Provider ID, Provider Business Name, Email, Contact No"
                        /* onChange={(e) => setFirstname(e.target.value!)} */
                        style={{
                          fontFamily: "Abadi MT Std",
                          color: "black",
                          paddingTop: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#f4ecd5",
                        }}
                      />
                    </div>
                    <div style={{ height: "6px" }}></div>

                    {/* //================================= */}
                    {(!datavalue3 || datavalue3.length === 0) && (
                      <div
                        className="form-floating"
                        style={{
                          textAlign: "center",
                          marginLeft: "12px",
                          marginRight: "12px",
                          paddingBottom: "6px",
                        }}
                      >
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          /* id="floatingInput" */
                          /* placeholder="Provider ID, Provider Business Name, Email, Contact No" */
                          value="..No retrieved data.."
                          /* onChange={(e) => setFirstname(e.target.value!)} */
                          style={{
                            fontFamily: "Abadi MT Std",
                            color: "black",
                            paddingTop: "10px",
                          }}
                        />
                      </div>
                    )}

                    {datavalue3 &&
                      datavalue3.map((data: any, index: any) => {
                        return (
                          <div
                            key={index}
                            className="form-floating"
                            style={{
                              textAlign: "justify",
                              marginLeft: "12px",
                              marginRight: "12px",
                              paddingBottom: "6px",
                            }}
                          >
                            <div
                              className="text-justify" //text-center
                              style={{
                                margin: "0px",
                                padding: "0px",
                                marginRight: "0px",
                                paddingRight: "0px",
                              }}
                            >
                              <div
                                className="text-justify col-lg-11" //text-center
                                style={{
                                  display: "inline",
                                  margin: "0px",
                                  padding: "0px",
                                  marginRight: "0px",
                                  paddingRight: "0px",
                                }}
                                onClick={() => {
                                  setGalleryuseruniquenumber(
                                    data.useruniquenumber
                                  );
                                  setGalleryimage(
                                    data.galleryimage.galleryimagelink +
                                      "" +
                                      data.galleryimage.galleryimagetoken
                                  );
                                  setGalleryimagetoken(
                                    data.galleryimage.galleryimagetoken
                                  );
                                  setGalleryviewname(data.galleryviewname);
                                  setGallerydescription(
                                    data.gallerydescription
                                  );
                                  setGalleryaddress(data.galleryaddress);
                                  setGalleryzipcode(data.galleryzipcode);
                                  setGallerycontactnumber1(
                                    data.gallerycontactnumber1
                                  );
                                  setGallerycontactnumber2(
                                    data.gallerycontactnumber2
                                  );
                                  setGalleryemail(data.galleryemail);
                                  setGallerycity(data.gallerycity);
                                  setGallerystate(data.gallerystate);
                                  setGallerysection1(
                                    data.gallerydetailslistingdesc
                                      .gallerydetailslistingsection1
                                  );
                                  setGallerysection2(
                                    data.gallerydetailslistingdesc
                                      .gallerydetailslistingsection2
                                  );
                                  setGallerysection3(
                                    data.gallerydetailslistingdesc
                                      .gallerydetailslistingsection3
                                  );

                                  setShowadminlistview(true);
                                }}
                              >
                                <input
                                  disabled
                                  type="text"
                                  className="form-control" //
                                  /* id="floatingInput" */
                                  /* placeholder="Provider ID, Provider Business Name, Email, Contact No" */
                                  value={`${data.useruniquenumber}, ${data.galleryviewname}, ${data.galleryemail}, ${data.gallerycontactnumber1}`}
                                  /* onChange={(e) => setFirstname(e.target.value!)} */
                                  style={{
                                    display: "inline",
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    paddingTop: "10px",
                                    cursor: "pointer",
                                    width: "calc(100% - 42px - 42px)",
                                  }}
                                />
                              </div>
                              <IonButton
                                className="col-lg-1"
                                style={{
                                  float: "right",
                                  display: "inline",
                                  color: "#8f6b00",
                                  height: "40px",
                                  /* width: "120px", */
                                  /* fontSize: "medium", */
                                  padding: "0px",
                                  margin: "0px",
                                  width: "40px",
                                  fontWeight: "bold",
                                  fontSize: "large",
                                  paddingLeft: "2px",
                                }}
                                onClick={() => {
                                  setSelectedoption1(false);
                                  setSelectedoption2(true);
                                  setSelecteduseruniquenumber(
                                    datavalue3[index].useruniquenumber
                                  );
                                  setMessagelocal(
                                    "Are you sure you want change this account into On Hold (Others) status?"
                                  );
                                  setIswarning3(true);
                                }}
                              >
                                ⦰
                              </IonButton>
                              <IonButton
                                className="col-lg-1"
                                style={{
                                  float: "right",
                                  display: "inline",
                                  color: "#8f6b00",
                                  height: "40px",
                                  /* width: "120px", */
                                  /* fontSize: "medium", */
                                  padding: "0px",
                                  margin: "0px",
                                  width: "40px",
                                  fontWeight: "bold",
                                  fontSize: "large",
                                }}
                                onClick={() => {
                                  setSelectedoption1(false);
                                  setSelectedoption2(false);
                                  setSelecteduseruniquenumber(
                                    datavalue3[index].useruniquenumber
                                  );
                                  setMessagelocal(
                                    "Are you sure you want change this account into On Hold status?"
                                  );
                                  setIswarning3(true);
                                }}
                              >
                                ⦰$
                              </IonButton>
                            </div>
                          </div>
                        );
                      })}

                    {/* <div style={{ height: "6px" }}></div> */}
                    {/* //================================= bob*/}
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "12px" }}></div>
              </form>
            )}

            {showvcita && showModeratorBoard && !showadminlistview && (
              <form>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Payments
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div
                      className="form-floating text-center"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        marginLeft: "12px",
                        marginRight: "12px",
                        /* width: "100%", */
                        border: "1px solid black",
                        borderRadius: "6px",
                      }}
                    >
                      <p
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          textAlign: "center",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          fontSize: "large",
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Member Service Payment Policy
                      </p>
                      <a
                        style={{
                          textAlign: "left",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          display: "block",
                          fontWeight: "bold",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          textDecoration: "underline",
                        }}
                      >
                        Annual Member Service, Invoice Payment Terms (Per
                        Calendar Year):
                      </a>
                      <ul>
                        <li
                          style={{
                            textAlign: "left",
                            fontFamily: "AvantGarde Bk BT",
                            color: "black",
                            fontSize: "medium",
                          }}
                        >
                          <a
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                            }}
                          >
                            The $500 Introductory Member Service is a limited
                            time offer and will be locked in for the current
                            calendar year. The introductory rate is payable to
                            Senior HUB Solutions upon acceptance and/or signing
                            your care home contract agreement with the
                            seniorHUBsoultions client, the Introductory rate is
                            due within 5 calendar days of invoicing.
                          </a>
                        </li>
                        <li
                          style={{
                            textAlign: "left",
                            fontFamily: "AvantGarde Bk BT",
                            color: "black",
                            fontSize: "medium",
                          }}
                        >
                          <a
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                            }}
                          >
                            The Standard Member Services is $1,500, subject to
                            change.
                          </a>
                        </li>
                        <li
                          style={{
                            textAlign: "left",
                            fontFamily: "AvantGarde Bk BT",
                            color: "black",
                            fontSize: "medium",
                          }}
                        >
                          <a
                            style={{
                              textAlign: "left",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                            }}
                          >
                            Annual Registration Fee $100. (Waived for 2022
                            calendar year)
                          </a>
                        </li>
                      </ul>
                      <div style={{ height: "6px" }}></div>
                      <a
                        style={{
                          textAlign: "left",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          display: "block",
                          fontWeight: "bold",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          textDecoration: "underline",
                        }}
                      >
                        Payment/Refund Policy
                      </a>
                      <a
                        style={{
                          textAlign: "justify",
                          fontFamily: "AvantGarde Bk BT",
                          fontSize: "medium",
                          color: "black",
                          display: "block",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          width: "100%",
                        }}
                      >
                        Due to the non-returnable nature of digital products,
                        the Annual Member Service and Registration Fees
                        (introductory and standard) are non-refundable.
                      </a>
                      <a
                        style={{
                          textAlign: "justify",
                          fontFamily: "AvantGarde Bk BT",
                          fontSize: "medium",
                          color: "black",
                          display: "block",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          width: "100%",
                        }}
                      >
                        No reimbursement for cancellation of Annual Member
                        Services.
                      </a>
                      <div style={{ height: "12px" }}></div>
                      <p
                        style={{
                          fontSize: "medium",
                          padding: "0px",
                          margin: "0px",
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "AvantGarde Bk BT",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                      >
                        I have read and agree to the seniorHUBsolutions Member
                        Service&nbsp;
                        <a
                          style={{
                            fontSize: "medium",
                            textDecorationLine: "underline",
                            cursor: "pointer",
                            fontFamily: "AvantGarde Bk BT",
                          }}
                          onClick={() => setShowmodal(true)}
                        >
                          Terms & Conditions
                        </a>
                      </p>
                      <div style={{ height: "6px" }}></div>
                    </div>

                    <div
                      className="form-floating"
                      style={{
                        textAlign: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      {/* <iframe
                          src="https://live.vcita.com/site/seniorhubsolutions/make-payment?title=PROMO!%20Annual%20Member%20Service%202022-2023&amount=1000"
                          scrolling="no"
                          data-id="livesite-widget"
                          className="livesite-pay"
                          data-pay_for="PROMO! Annual Member Service 2022-2023"
                          data-amount="1000"
                          style={{
                            width: "100%",
                            height: "90vh",
                          }}
                        > */}
                      {/* <a
                          href="https://live.vcita.com/site/seniorhubsolutions/make-payment?title=PROMO!%20Annual%20Member%20Service%202022-2023&amount=1000"
                          target="blank"
                          data-id="livesite-widget"
                          className="livesite-pay"
                          data-pay_for="PROMO! Annual Member Service 2022-2023"
                          data-amount="1000"
                        >
                          Pay Here
                        </a> */}
                      {/* <a href="https://live.vcita.com/site/seniorhubsolutions/make-payment?title=%22PROMO%20PRICE%22%20Limited%20Time%20Offer!&amount=1000" target="blank" data-id="livesite-widget">Pay via VCITA</a> */}
                      {/* </iframe> */}
                    </div>
                    <div style={{ height: "6px" }}></div>
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "6px" }}></div>
                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  {/* <IonButton
                    data-id="livesite-widget"
                    //className="livesite-pay"
                    //data-pay_for="PROMO! Annual Member Service 2022-2023"
                    //data-amount="1000"
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "240px",
                      fontSize: "medium",
                    }}
                    onClick={launchLinkVCITA1}
                  >
                    Monthly Payment
                  </IonButton> */}
                  <IonButton
                    data-id="livesite-widget"
                    /* className="livesite-pay" */
                    /* data-pay_for="PROMO! Annual Member Service 2022-2023" */
                    /* data-amount="1000" */
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "240px",
                      fontSize: "medium",
                    }}
                    onClick={launchLinkVCITA3}
                  >
                    Registration Fee
                  </IonButton>
                  {/* <IonButton
                    data-id="livesite-widget"
                    //className="livesite-pay"
                    //data-pay_for="PROMO! Annual Member Service 2022-2023"
                    //data-amount="1000"
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "240px",
                      fontSize: "medium",
                    }}
                    onClick={launchLinkVCITA2}
                  >
                    Annual Payment
                  </IonButton> */}
                </div>
                <div style={{ height: "12px" }}></div>
              </form>
            )}

            {showadminlistview && showAdminBoard && (
              <form>
                <p
                  className="featurette-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    color: "black",
                    fontSize: "xx-large",
                  }}
                >
                  Care Home Provider Information:
                </p>
                <div className="border-top" style={{ height: "20px" }}></div>

                <div
                  className="row align-items-start"
                  style={{ margin: "0px", padding: "0px", height: "100%" }}
                >
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                  <div
                    className="col-lg-8 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                  >
                    {/* //================================================================================= */}
                    {/* <h1
                      className="featurette-heading"
                      style={{
                        marginTop: "10px",
                        textAlign: "center",
                        fontFamily: "AvantGarde Bk BT",
                        color: "#8A1F61",
                      }}
                    >
                      {galleryviewname}
                    </h1> */}

                    {/* <div style={{ height: "12px" }}></div>
                  <div
                    style={{
                      borderBottom: "4px solid #ffcc00",
                    }}
                  /> */}

                    {/* <div style={{ height: "24px", fontFamily: "Abadi MT Std" }}></div> */}

                    <div
                      className="container"
                      style={{
                        textAlign: "left",
                        padding: "0px",
                        width: "100%",
                      }}
                    >
                      <div
                        className="row align-items-center"
                        style={{
                          border: "1px solid gray",
                          backgroundColor: "#f4ecd5",
                          width: "100%",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        <div
                          className="container col-lg-7"
                          /*  align-top */ style={{
                            /* width: "100%", */ textAlign: "center",
                            padding: "0px",
                            margin:
                              "0px" /* alignContent: "center", flexWrap: "wrap", */ /* verticalAlign: "top", */ /* paddingRight: `${() => { if (window.innerWidth > 976) { return ("12px"); } else { return ("0px"); }; }}`, */,
                          }}
                        >
                          <img
                            src={galleryimage}
                            style={{
                              /* border: "2px solid #8A1F61", */
                              height: "100%",
                              width: "100%",
                              /* borderRadius: "6px", */
                            }}
                          />
                          {/* <div style={{ height: "12px" }}></div> */}
                        </div>

                        <div
                          className="container col-lg-5"
                          style={{
                            padding:
                              "0px" /* alignContent: "center", flexWrap: "wrap", */ /* width: "100%",  */ /* verticalAlign: "top", */,
                          }}
                        >
                          <div style={{ height: "12px" }}></div>
                          <div
                            className="container"
                            style={{
                              /* border: "1px solid gray", */ width: "100%",
                              backgroundColor: "#f4ecd5",
                              textAlign: "center",
                              margin: "0px",
                            }}
                          >
                            <ul
                              style={{
                                color: "black",
                                margin: "0px",
                                marginTop: "16px",
                                marginBottom: "16px",
                                padding: "0px",
                              }}
                            >
                              <h1
                                className="featurette-heading"
                                style={{
                                  marginTop: "10px",
                                  textAlign: "center",
                                  fontFamily: "AvantGarde Bk BT",
                                  color: "#8A1F61",
                                }}
                              >
                                {galleryviewname
                                  ? galleryviewname
                                  : `seniorHUBsolutions (${galleryuseruniquenumber})`}
                              </h1>
                              <div
                                className="container"
                                style={{
                                  fontFamily: "Abadi MT Std",
                                  padding: "0px",
                                  paddingBottom: "6px",
                                  color: "#008080",
                                  fontSize: "large",
                                }}
                              >
                                <b>Address: </b>
                                <a
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    fontSize: "large",
                                  }}
                                >{`${
                                  galleryaddress ? galleryaddress : "N/A"
                                } `}</a>
                                {/* <a style={{ fontFamily: "Abadi MT Std", textDecoration: "underline", cursor: "pointer", color: "#8A1F61", fontSize: "large", }} onClick={() => setShowmodal(true)}><b>View Map >></b></a> */}
                              </div>
                              <div
                                className="container"
                                style={{
                                  fontFamily: "Abadi MT Std",
                                  padding: "0px",
                                  paddingBottom: "6px",
                                  color: "#008080",
                                  fontSize: "large",
                                }}
                              >
                                <b>City: </b>
                                <a
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    fontSize: "large",
                                  }}
                                >{`${gallerycity ? gallerycity : "N/A"},`}</a>
                                <b> State: </b>
                                <a
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    fontSize: "large",
                                  }}
                                >{`${gallerystate ? gallerystate : "N/A"},`}</a>
                                <b> Zip Code: </b>
                                <a
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    fontSize: "large",
                                  }}
                                >
                                  {galleryzipcode ? galleryzipcode : "N/A"}
                                </a>
                              </div>
                              <div
                                className="container"
                                style={{
                                  fontFamily: "Abadi MT Std",
                                  padding: "0px",
                                  paddingBottom: "6px",
                                  color: "#008080",
                                  fontSize: "large",
                                }}
                              >
                                <b>Contact Number: </b>
                                <a
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    fontSize: "large",
                                  }}
                                >
                                  {gallerycontactnumber1
                                    ? gallerycontactnumber1
                                    : "N/A"}
                                </a>
                              </div>
                              <div
                                className="container"
                                style={{
                                  fontFamily: "Abadi MT Std",
                                  padding: "0px",
                                  paddingBottom: "6px",
                                  color: "#008080",
                                  fontSize: "large",
                                }}
                              >
                                <b>Email: </b>
                                <a
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    fontSize: "large",
                                  }}
                                >
                                  {isEmail(galleryemail) ? galleryemail : "N/A"}
                                </a>
                              </div>
                            </ul>
                          </div>
                          {/* <div style={{ height: "12px" }}></div> */}
                          {/* <div className="container" style={{ width: "100%", padding: "0px", margin: "0px", }}>
                          <div className="align-items-center" style={{ width: "100%", backgroundColor: "#f4ecd5", margin: "0px", }}>
                            <div className="container text-left" style={{ backgroundColor: "#f4ecd5", color: "black", height: "156px", padding: "0px" }}>
                              <p
                                style={{
                                  textAlign: "center",
                                  padding: "0px",
                                  margin: "0px",
                                }}
                              >
                                <a
                                  className="btn btn-lg btn-secondary col-md-12 order-md-1"
                                  style={
                                    hover1
                                    ? {
                                        textAlign: "center",
                                        cursor: "pointer",
                                        color: "#8f6b00",
                                        width: "100%",
                                        margin: "0px",
                                        maxHeight: "56px",
                                        maxWidth: "196px",
                                        minWidth: "196px",
                                        backgroundColor: "#ffe685",
                                      }
                                    : {
                                        textAlign: "center",
                                        cursor: "pointer",
                                        color: "#8f6b00",
                                        width: "100%",
                                        margin: "0px",
                                        maxHeight: "56px",
                                        maxWidth: "196px",
                                        minWidth: "196px",
                                        backgroundColor: "#ffcc00",
                                      }
                                  }
                                  onMouseEnter={togglehover1}
                                  onMouseLeave={togglehover1}
                                  onClick={toggleactive1}
                                >
                                  Call Provider
                                </a>
                              </p>
                              <div style={{
                                height: "8px" }}></div>
                              <p
                                style={{
                                  textAlign: "center",
                                  padding: "0px",
                                  margin: "0px",
                                }}
                              >
                                <a
                                  className="btn btn-lg btn-secondary col-md-12 order-md-1"
                                  style={
                                    hover2
                                    ? {
                                        textAlign: "center",
                                        cursor: "pointer",
                                        color: "#8f6b00",
                                        width: "100%",
                                        margin: "0px",
                                        maxHeight: "56px",
                                        maxWidth: "196px",
                                        minWidth: "196px",
                                        backgroundColor: "#ffe685",
                                      }
                                    : {
                                        textAlign: "center",
                                        cursor: "pointer",
                                        color: "#8f6b00",
                                        width: "100%",
                                        margin: "0px",
                                        maxHeight: "56px",
                                        maxWidth: "196px",
                                        minWidth: "196px",
                                        backgroundColor: "#ffcc00",
                                      }
                                  }
                                  onMouseEnter={togglehover2}
                                  onMouseLeave={togglehover2}
                                  onClick={toggleactive2}
                                >
                                  Email Provider
                                </a>
                              </p>
                            </div>
                          </div>
                        </div> */}
                          {/* <div style={{ height: "12px" }}></div> */}
                        </div>
                      </div>
                      <div style={{ height: "12px" }}></div>
                      <div
                        className="container"
                        style={{
                          border: "1px solid gray",
                          width: "100%",
                          padding: "0px",
                          textAlign: "left",
                          color: "black",
                        }}
                      >
                        <div
                          className="row align-items-start"
                          style={{
                            width: "100%",
                            backgroundColor: "#f4ecd5",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <div
                            className="container text-left col-lg-6 align-top"
                            style={{
                              backgroundColor: "#f4ecd5",
                              color: "black",
                              margin: "0px",
                              verticalAlign: "top",
                            }}
                          >
                            {gallerysection1 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                            {gallerysection1 && (
                              <div
                                style={{
                                  textAlign:
                                    "left" /* textDecoration: "underline", */,
                                }}
                              >
                                <h1
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "#008080",
                                    fontSize: "large",
                                  }}
                                >
                                  <b>ROOM DETAILS:</b>
                                </h1>
                              </div>
                            )}
                            {gallerysection1 &&
                              gallerysection1.map((data: any) => {
                                return (
                                  <div
                                    key={data}
                                    style={{
                                      fontFamily: "Abadi MT Std",
                                      textAlign: "left",
                                    }}
                                  >
                                    {data}
                                  </div>
                                );
                              })}
                            {gallerysection1 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                            {gallerysection2 && (
                              <div
                                style={{
                                  textAlign:
                                    "left" /* textDecoration: "underline", */,
                                }}
                              >
                                <h1
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "#008080",
                                    fontSize: "large",
                                  }}
                                >
                                  <b>ROOM FEATURES:</b>
                                </h1>
                              </div>
                            )}
                            {gallerysection2 &&
                              gallerysection2.map((data: any) => {
                                return (
                                  <div
                                    key={data}
                                    style={{
                                      fontFamily: "Abadi MT Std",
                                      textAlign: "left",
                                    }}
                                  >
                                    {data}
                                  </div>
                                );
                              })}
                            {/* {gallerysection2 && (
                              <div
                                style={{
                                  fontFamily: "Abadi MT Std",
                                  textAlign: "left",
                                  fontStyle: "italic",
                                }}
                              >
                                *Consult with your Valued Care Provider for
                                further information
                              </div>
                            )} */}
                            {gallerysection2 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                          </div>
                          <div
                            className="container text-left col-lg-6 align-top"
                            style={{
                              backgroundColor: "#f4ecd5",
                              color: "black",
                              margin: "0px",
                              verticalAlign: "top",
                            }}
                          >
                            {gallerysection3 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                            {gallerysection3 && (
                              <div
                                style={{
                                  textAlign:
                                    "left" /* textDecoration: "underline", */,
                                }}
                              >
                                <h1
                                  style={{
                                    fontFamily: "Abadi MT Std",
                                    color: "#008080",
                                    fontSize: "large",
                                  }}
                                >
                                  <b>SERVICES PROVIDED:</b>
                                </h1>
                              </div>
                            )}
                            {gallerysection3 &&
                              gallerysection3.map((data: any) => {
                                return (
                                  <div
                                    key={data}
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "24px",
                                    }}
                                  >
                                    <li style={{ fontFamily: "Abadi MT Std" }}>
                                      {data}
                                    </li>
                                  </div>
                                );
                              })}
                            {/* {gallerysection3 && (
                              <div
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "24px",
                                  fontStyle: "italic",
                                }}
                              >
                                <li style={{ fontFamily: "Abadi MT Std" }}>
                                  Consult with your Valued Care Provider for
                                  further information
                                </li>
                              </div>
                            )} */}
                            {gallerysection3 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* //================================================================================= */}
                  </div>
                  <div
                    className="col-lg-2 text-center"
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                  ></div>
                </div>

                <div style={{ height: "6px" }}></div>
                <div
                  className="text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginRight: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <IonButton
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      setShowadminlistview(false);
                    }}
                  >
                    Back
                  </IonButton>
                  <IonButton
                    style={{
                      color: "#8f6b00",
                      height: "40px",
                      width: "120px",
                      fontSize: "medium",
                    }}
                    onClick={() => {
                      setSelectedoption1(false);
                      setSelectedoption2(false);
                      setSelecteduseruniquenumber(galleryuseruniquenumber);
                      if (showforpayment) {
                        setMessagelocal(
                          "Are you sure you want change this account into Paid status?"
                        );
                      } else if (showforapproval) {
                        setMessagelocal(
                          "Are you sure you want change this account into Approved status?"
                        );
                      } else if (showapprovedproviderlist) {
                        setMessagelocal(
                          "Are you sure you want change this account into On Hold status?"
                        );
                      }
                      setIswarning3(true);
                    }}
                  >
                    {`${
                      showapprovedproviderlist
                        ? "On Hold"
                        : `${showforapproval ? "Approve" : "Partially Paid"}`
                    }`}
                  </IonButton>
                  {showforpayment &&
                    !showforapproval &&
                    !showapprovedproviderlist && (
                      <IonButton
                        style={{
                          color: "#8f6b00",
                          height: "40px",
                          width: "160px",
                          fontSize: "medium",
                        }}
                        onClick={() => {
                          setSelectedoption1(true);
                          setSelectedoption2(false);
                          setSelecteduseruniquenumber(galleryuseruniquenumber);
                          setMessagelocal(
                            "Are you sure you want change this account into Paid and Approved status?"
                          );
                          setIswarning3(true);
                        }}
                      >
                        Paid & Approve
                      </IonButton>
                    )}
                  {!showforpayment &&
                    !showforapproval &&
                    showapprovedproviderlist && (
                      <IonButton
                        style={{
                          color: "#8f6b00",
                          height: "40px",
                          width: "160px",
                          fontSize: "medium",
                        }}
                        onClick={() => {
                          setSelectedoption1(false);
                          setSelectedoption2(true);
                          setSelecteduseruniquenumber(galleryuseruniquenumber);
                          setMessagelocal(
                            "Are you sure you want change this account into On Hold (Others) status?"
                          );
                          setIswarning3(true);
                        }}
                      >
                        On Hold (Others)
                      </IonButton>
                    )}
                </div>
                <div style={{ height: "12px" }}></div>
              </form>
            )}
          </main>
        </div>
      </div>
      <div style={{ height: "1vh" }}></div>
    </IonContent>
  );
};

export default Profile;
