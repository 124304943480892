/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */ import axios from "axios";
import AuthHeader from "./auth-header";
import UrlService from "./url.service";

const API_URL = UrlService.API_URL + "user/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: AuthHeader.authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: AuthHeader.authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: AuthHeader.authHeader() });
};

const getLogout = () => {
  return axios.get(API_URL + "logout", { headers: AuthHeader.authHeader() });
};

const getJWT = () => {
  return axios.get(API_URL + "jwt", { headers: AuthHeader.authHeader() });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getLogout,
  getJWT,
};
