/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-control-regex */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

/* Components */
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./ResetPassword.css";
/* Icons */
import { personCircle } from "ionicons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
/* Actions */
//import { loginSuccess, loginFailed } from "../actions/auth";
import { setMessage } from "../actions/message";
/* Services */
import AuthService from "../services/auth.service";
/* Others */
import { history } from "../helpers/history";

const eyeshown = <FontAwesomeIcon icon={faEye} />;
const eyehidden = <FontAwesomeIcon icon={faEyeSlash} />;

function validatePassword(password: string) {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*^()_-`~{}|]).{8,}/;
  return re.test(password);
}
const ResetPassword: React.FC = () => {
  //const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  /* const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const { resetpasswordcode } = useParams<{ resetpasswordcode: string }>();

  const [password, setPassword] = useState<string>("");
  const [confirmpassword, setConfirmpassword] = useState<string>("");
  const [showpassword, setShowpassword] = useState<boolean>(false);
  const [showcpassword, setCshowpassword] = useState<boolean>(false);
  const [iserror, setIserror] = useState<boolean>(false);
  const [showloading, setShowloading] = useState<boolean>(false);
  const [isreset, setIsreset] = useState<boolean>(false);
  const [messagelocal, setMessagelocal] = useState<string>("");
  const [ischangetitle, setIschangetitle] = useState<boolean>(false);
  const [useruniquenumberresponse, setUseruniquenumberresponse] = useState<
    string
  >("");
  const [emailresponse, setEmailresponse] = useState<string>("");
  //const inputRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setMessagelocal(
        "A User is currently logged on. Please logout first then try again."
      );
      setIserror(true);
    } else {
      setShowloading(true);
    }
  }, []);

  useEffect(() => {
    /* var timerId: NodeJS.Timeout;
    history.listen((location: any) => {
      if (location.pathname === "/resetpassword") {
        if (inputRef.current) {
          timerId = setTimeout(() => inputRef.current.setFocus(), 100);
        }
      }
      if (location.pathname !== "/resetpassword") {
        return () => clearTimeout(timerId);
      }
    }); */
  }, []);

  useEffect(() => {
    /* var timeout: NodeJS.Timeout;
    timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setFocus();
      }
    }, 1000);
    return () => clearTimeout(timeout); */
  }, [ischangetitle]);

  let pagename = "Reset Password";

  const togglePasswordVisiblity = () => {
    setShowpassword(showpassword ? false : true);
  };
  const toggleCPasswordVisiblity = () => {
    setCshowpassword(showcpassword ? false : true);
  };
  const handleResetPassword = () => {
    if (!password) {
      setMessagelocal("Please enter your password");
      setIserror(true);
      return;
    }
    if (password.length < 8 || password.length > 12) {
      setMessagelocal(
        "Password should not be less than 8 and not more than 12 characters"
      );
      setIserror(true);
      return;
    }
    if (validatePassword(password) === false) {
      setMessagelocal(
        "Password must contain at least one number, special character and one uppercase and lowercase letter, and at least 8 up to 12 characters"
      );
      setIserror(true);
      return;
    }
    if (!confirmpassword) {
      setMessagelocal("Please enter again your password");
      setIserror(true);
      return;
    }
    if (confirmpassword.length < 8 || confirmpassword.length > 12) {
      setMessagelocal(
        "Confirm Password should not be less than 8 and not more than 12 characters"
      );
      setIserror(true);
      return;
    }
    if (validatePassword(confirmpassword) === false) {
      setMessagelocal(
        "Confirm Password must contain at least one number, special character and one uppercase and lowercase letter, and at least 8 up to 12 characters"
      );
      setIserror(true);
      return;
    }
    if (password !== confirmpassword) {
      setMessagelocal("Passwords do not matched. Please try again.");
      setIserror(true);
      return;
    }

    setShowloading(true);

    AuthService.resetpassword(
      password,
      confirmpassword,
      resetpasswordcode
    ).then(
      (response: any) => {
        //dispatch(setMessage(response.data.message));
        setMessagelocal(response.data.message);
        setShowloading(false);
        setIsreset(true);
        //return Promise.resolve();
      },
      (error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setMessage(message));
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        //return Promise.reject();
      }
    );
  };

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <IonHeader className="fixed-top">
        <IonToolbar
          color="primary"
          id="backgroundwhitebrand"
          style={{
            height: "0px",
          }}
        ></IonToolbar>
        <nav
          className="navbar navbar-expand-md"
          aria-label="Main navigation"
          style={{
            height: "66px",
            backgroundImage: "url(" + require("../assets/Background.jpg") + ")",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        ></nav>
        <IonToolbar
          id="backgroundwhitebrand"
          /* color="primary" */
          style={{
            backgroundColor: "white",
          }}
        >
          <IonButtons
            slot="start"
            style={{
              width: "100vw",
            }}
          >
            <IonButton
              onClick={() => {
                history.push("/profile");
              }}
              style={{
                color: "black",
              }}
            >
              Profile
            </IonButton>
            <IonTitle
              style={{
                textAlign: "center",
              }}
            >
              <IonLabel
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "Gill Sans Nova",
                  color: "black",
                  fontSize: "20px",
                  width: "100%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  fontWeight: "normal",
                }}
              >
                &nbsp;senior
                <a
                  style={{
                    fontFamily: "Gill Sans Nova",
                    color: "black",
                    fontSize: "1.1em",
                    fontWeight: "bold",
                  }}
                >
                  HUB
                </a>
                solutions
              </IonLabel>
            </IonTitle>
            {/* {platformusedglobal !== "ios" && ( */}
            <a
              style={{
                color: "white", //#ffcc00
                width: "57px",
                marginLeft: "2px",
                marginRight: "2px",
                textAlign: "center",
                fontSize: "medium",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {/* )} */}
          </IonButtons>
        </IonToolbar>
        <div className="border-top border-bottom" />
      </IonHeader>

      <div style={{ height: "20vh" }}></div>
      <div
        className="container text-center"
        style={{ color: "black", maxWidth: "520px" }}
      >
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => {
            setIserror(false);
            if (!ischangetitle || currentUser) {
              history.push("/home");
            }
          }}
          cssClass="ionbackgroundclass"
          header={"Error!"}
          message={messagelocal}
          buttons={["Dismiss"]}
        />
        <IonAlert
          isOpen={isreset}
          /* onDidDismiss={() => {
            setIsreset(false);
            history.push("/login");
          }} */
          cssClass="ionbackgroundclass"
          header={"Reset Password"}
          message={messagelocal}
          buttons={[
            {
              text: "OK",
              handler: () => {
                setIsreset(false);
                history.push("/login");
              },
            },
          ]}
        />
        <IonLoading
          cssClass="ionbackgroundclass"
          isOpen={showloading}
          onDidDismiss={() => {
            setShowloading(false);

            if (!isreset) {
              AuthService.checkresetpasswordcode(resetpasswordcode)
                .then((data: any) => {
                  setUseruniquenumberresponse(data.useruniquenumber);
                  setEmailresponse(data.email);
                  setIschangetitle(true);
                })
                .catch((error: any) => {
                  const message =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                  dispatch(setMessage(message));
                  setMessagelocal(message);
                  setIserror(true);
                  //return Promise.reject();
                });
            }
          }}
          message={"Please wait..."}
          duration={6500}
        />
        <main className="form-signin">
          <form>
            <IonIcon
              className="mb-4"
              color="primary"
              style={{ fontSize: "70px" }}
              icon={personCircle}
            />
            {ischangetitle ? (
              <div
                className="container"
                style={{ margin: "0px", padding: "0px" }}
              >
                <h1
                  className="h3 mb-3 fw-normal"
                  style={{ fontFamily: "Abadi MT Std" }}
                >
                  Set your New Password for User:
                </h1>
                <h1
                  className="h3 mb-3 fw-normal"
                  style={{ fontFamily: "Abadi MT Std" }}
                >
                  ID: {useruniquenumberresponse}
                </h1>
                <h1
                  className="h3 mb-3 fw-normal"
                  style={{ fontFamily: "Abadi MT Std" }}
                >
                  Email / Username: {emailresponse}
                </h1>
                <div style={{ height: "6px" }}></div>
                <div className="form-floating">
                  <input
                    /* ref={(ref) => (inputRef.current = ref)} */
                    type={showpassword ? "text" : "password"}
                    className="form-control"
                    /* id="floatingPassword" */
                    /* placeholder="Password" */
                    value={password}
                    onChange={(e) => setPassword(e.target.value!)}
                    style={{ fontFamily: "Abadi MT Std" }}
                  ></input>
                  <i
                    onClick={togglePasswordVisiblity}
                    /* style={{ position: "absolute", top: "-6px", height: "16px" }} */
                  >
                    {showpassword ? eyehidden : eyeshown}
                  </i>
                  <label
                    style={{
                      fontFamily: "Abadi MT Std",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    * Password
                  </label>
                </div>
                <div style={{ height: "6px" }}></div>
                <div className="form-floating">
                  <input
                    type={showcpassword ? "text" : "password"}
                    className="form-control"
                    /* id="floatingPassword" */
                    /* placeholder="Confirm Password" */
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value!)}
                    style={{ fontFamily: "Abadi MT Std" }}
                  ></input>
                  <i
                    onClick={toggleCPasswordVisiblity}
                    /* style={{ position: "absolute", top: "-6px", height: "16px" }} */
                  >
                    {showcpassword ? eyehidden : eyeshown}
                  </i>
                  <label
                    style={{
                      fontFamily: "Abadi MT Std",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    * Confirm Password
                  </label>
                </div>
                <div style={{ height: "6px" }}></div>
                <IonButton
                  expand="block"
                  onClick={handleResetPassword}
                  style={{
                    color: "#8f6b00",
                    height: "56px",
                    fontSize: "large",
                  }}
                >
                  Reset Password
                </IonButton>
              </div>
            ) : (
              <h1
                className="h3 mb-3 fw-normal"
                style={{ fontFamily: "Abadi MT Std" }}
              >
                Getting Account details in progress...
              </h1>
            )}
          </form>
        </main>
      </div>

      <div style={{ height: "24px" }}></div>

      <div>
        <FooterMain />
        <Footer />
        {localStorage.getItem("isnative") &&
          localStorage.getItem("isnative") === "true" && <FooterMobile />}
      </div>
    </IonContent>
  );
};

export default ResetPassword;
