/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-control-regex */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

/* Components */
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./ForgotPassword.css";
/* Icons */
import { personCircle } from "ionicons/icons";
/* Actions */
import { setMessage } from "../actions/message";
/* Services */
import AuthService from "../services/auth.service";
/* Others */
import { history } from "../helpers/history";
import { isEmail } from "validator";

const ForgotPassword: React.FC = () => {
  //const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  /* const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [email, setEmail] = useState<string>(""); //bobbyvictacpacisa@gmail.com
  const [iserror, setIserror] = useState<boolean>(false);
  const [showloading, setShowloading] = useState<boolean>(false);
  const [isreset, setIsreset] = useState<boolean>(false);
  const [isadmin, setIsadmin] = useState<boolean>(false);
  const [messagelocal, setMessagelocal] = useState<string>("");
  //const inputRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setMessagelocal(
        "A User is currently logged on. Please logout first then try again."
      );
      setIserror(true);
    }
  }, []);

  useEffect(() => {
    /* var timerId: NodeJS.Timeout;
    history.listen((location: any) => {
      if (location.pathname === "/forgotpassword") {
        if (inputRef.current) {
          timerId = setTimeout(() => inputRef.current.setFocus(), 100);
        }
      }
      if (location.pathname !== "/forgotpassword") {
        return () => clearTimeout(timerId);
      }
    }); */
  }, []);

  useEffect(() => {
    /* var timeout: NodeJS.Timeout;
    timeout = setTimeout(() => {
      inputRef.current.setFocus();
    }, 1000);
    return () => clearTimeout(timeout); */
  }, []);

  let pagename = "Forgot Password";

  const handleForgotPassword = () => {
    if (!email) {
      setMessagelocal("Please enter a valid email");
      setIserror(true);
      return;
    }
    /* if (isEmail(email) === false) {
      setMessagelocal("Your email is invalid");
      setIserror(true);
      return;
    } */

    setShowloading(true);

    const specialmode = isadmin; //bbbb ? true : false;
    AuthService.forgotpassword(email, specialmode).then(
      (response: any) => {
        //dispatch(setMessage(response.data.message));
        setMessagelocal(response.data.message);
        setShowloading(false);
        setIsreset(true);
        //return Promise.resolve();
      },
      (error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setMessage(message));
        setMessagelocal(message);
        setShowloading(false);
        setIserror(true);
        //return Promise.reject();
      }
    );
  };

  const toggleAdmin = () => {
    setIsadmin(isadmin ? false : true);
  };

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <IonHeader className="fixed-top">
        <IonToolbar
          color="primary"
          id="backgroundwhitebrand"
          style={{
            height: "0px",
          }}
        ></IonToolbar>
        <nav
          className="navbar navbar-expand-md"
          aria-label="Main navigation"
          style={{
            height: "66px",
            backgroundImage: "url(" + require("../assets/Background.jpg") + ")",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        ></nav>
        <IonToolbar
          id="backgroundwhitebrand"
          /* color="primary" */
          style={{
            backgroundColor: "white",
          }}
        >
          <IonButtons
            slot="start"
            style={{
              width: "100vw",
            }}
          >
            <IonButton
              onClick={() => {
                history.push("/login");
              }}
              style={{
                color: "black",
              }}
            >
              Back
            </IonButton>
            <IonTitle
              style={{
                textAlign: "center",
              }}
            >
              <IonLabel
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "Gill Sans Nova",
                  color: "black",
                  fontSize: "20px",
                  width: "100%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  fontWeight: "normal",
                }}
              >
                &nbsp;senior
                <a
                  style={{
                    fontFamily: "Gill Sans Nova",
                    color: "black",
                    fontSize: "1.1em",
                    fontWeight: "bold",
                  }}
                >
                  HUB
                </a>
                solutions
              </IonLabel>
            </IonTitle>
            {/* {platformusedglobal !== "ios" && ( */}
            <a
              style={{
                color: "white", //#ffcc00
                width: "57px",
                marginLeft: "2px",
                marginRight: "2px",
                textAlign: "center",
                fontSize: "medium",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {/* )} */}
          </IonButtons>
        </IonToolbar>
        <div className="border-top border-bottom" />
      </IonHeader>

      <div style={{ height: "20vh" }}></div>
      {/* <IonContent fullscreen color="light" style={{ height: "100vh" }}> */}
      <div
        className="container text-center"
        style={{ color: "black", maxWidth: "520px" }}
      >
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="ionbackgroundclass"
          header={"Error!"}
          message={messagelocal}
          buttons={["Dismiss"]}
        />
        <IonAlert
          isOpen={isreset}
          /* onDidDismiss={() => setIsreset(false)} */
          cssClass="ionbackgroundclass"
          header={"Forgot Password"}
          message={messagelocal}
          buttons={[
            {
              text: "OK",
              handler: () => {
                setIsreset(false);
                history.push("/login");
              },
            },
          ]}
        />
        <IonLoading
          cssClass="ionbackgroundclass"
          isOpen={showloading}
          onDidDismiss={() => setShowloading(false)}
          message={"Please wait..."}
          duration={60000}
        />
        <main className="form-signin">
          <form>
            <IonIcon
              className="mb-4"
              color="primary"
              style={{ fontSize: "70px" }}
              icon={personCircle}
            />
            <p
              className="h3 mb-3 fw-normal"
              style={{ fontFamily: "Abadi MT Std", fontSize: "large" }}
            >
              Forgot your Password? Not to worry! Enter your Email / Username
              below and we'll email / sms you instructions for setting up a new
              password.
            </p>
            <div className="form-floating">
              <input
                /* ref={(ref) => (inputRef.current = ref)} */
                type="email"
                className="form-control"
                /* id="floatingInput" */
                /* placeholder="name@example.com" */
                value={email}
                onChange={(e) => setEmail(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              />
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * Email / Username
              </label>
            </div>
            <div style={{ height: "6px" }}></div>

            <IonButton
              expand="block"
              onClick={handleForgotPassword}
              style={{ color: "#8f6b00", height: "56px", fontSize: "large" }}
            >
              Reset Password
            </IonButton>
          </form>
        </main>
      </div>

      <div className="container text-center">
        <div style={{ height: "12px" }}></div>
        <p
          style={{
            fontSize: "medium",
            color: "black",
            fontFamily: "Abadi MT Std",
          }}
        >
          Back to&nbsp;
          <a
            style={{
              textDecorationLine: "underline",
              cursor: "pointer",
              color: "black",
              fontFamily: "Abadi MT Std",
            }}
            onClick={() => history.push("/login")}
          >
            Log In
          </a>
        </p>
      </div>

      <div
        className="ion-text-center"
        style={{ fontSize: "medium", margin: "0px" }}
      >
        <div className="checkbox" style={{ margin: "0px", color: "black" }}>
          <label
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              fontFamily: "Abadi MT Std",
              fontSize: "small",
            }}
          >
            <input
              id="checkboxitem"
              type="checkbox"
              value="remember-me"
              checked={isadmin}
              onChange={toggleAdmin}
            />
            <label
              style={{
                cursor: "pointer",
                textDecoration: "none",
                fontFamily: "Abadi MT Std",
                fontSize: "small",
              }}
            >
              &nbsp;
            </label>
            Admin Reset Password
          </label>
        </div>
      </div>

      <div style={{ height: "24px" }}></div>

      <div>
        <FooterMain />
        <Footer />
        {localStorage.getItem("isnative") &&
          localStorage.getItem("isnative") === "true" && <FooterMobile />}
      </div>
    </IonContent>
  );
};

export default ForgotPassword;
