/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */

const user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user") || "{}")
  : null;

function authHeader() {
  if (user && user.accessToken) {
    // For Spring Boot back-end
    // return { Authorization: "Bearer " + user.accessToken };

    // for Node.js Express back-end
    return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
}

function authHeaderwithFile() {
  if (user && user.accessToken) {
    // For Spring Boot back-end
    // return { Authorization: "Bearer " + user.accessToken };

    // for Node.js Express back-end
    return {
      "x-access-token": user.accessToken,
      "Content-Type": "multipart/form-data",
    };
  } else {
    return {};
  }
}

export default { authHeader, authHeaderwithFile };
