/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

/* Components */
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./FAQs.css";
/* Others */
import { history } from "../helpers/history";

const FAQs: React.FC = () => {
  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  useEffect(() => {
    var platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);
  }, []);

  let pagename = "seniorHUBsolutions";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <IonHeader className="fixed-top">
        <IonToolbar
          color="primary"
          id="backgroundwhitebrand"
          style={{
            height: "0px",
          }}
        ></IonToolbar>
        <nav
          className="navbar navbar-expand-md"
          aria-label="Main navigation"
          style={{
            height: "66px",
            backgroundImage: "url(" + require("../assets/Background.jpg") + ")",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        ></nav>
        <IonToolbar
          id="backgroundwhitebrand"
          /* color="primary" */
          style={{
            backgroundColor: "white",
          }}
        >
          <IonButtons
            slot="start"
            style={{
              width: "100vw",
            }}
          >
            {/* {platformusedglobal !== "ios" && ( */}
            <IonButton
              onClick={() => {
                history.push("/home");
              }}
              style={{
                color: "black",
              }}
            >
              Back
            </IonButton>
            {/* )} */}
            <IonTitle
              style={{
                textAlign: "center",
              }}
            >
              <IonLabel
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "Gill Sans Nova",
                  color: "black",
                  fontSize: "20px",
                  width: "100%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  fontWeight: "normal",
                }}
              >
                &nbsp;senior
                <a
                  style={{
                    fontFamily: "Gill Sans Nova",
                    color: "black",
                    fontSize: "1.1em",
                    fontWeight: "bold",
                  }}
                >
                  HUB
                </a>
                solutions
              </IonLabel>
            </IonTitle>
            {/* {platformusedglobal !== "ios" && ( */}
            <a
              style={{
                color: "white", //#ffcc00
                width: "57px",
                marginLeft: "2px",
                marginRight: "2px",
                textAlign: "center",
                fontSize: "medium",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {/* )} */}
          </IonButtons>
        </IonToolbar>
        <div className="border-top border-bottom" />
      </IonHeader>

      <div style={{ height: "20vh" }}></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        <div className="container">
          <div className="container-fluid py-5">
            <h1
              className="featurette-heading"
              style={{
                marginTop: "10px",
                textAlign: "center",
                fontFamily: "AvantGarde Bk BT",
                color: "#8A1F61",
                /* textShadow: "-1px 1px 2px #000", */
              }}
            >
              Frequently Asked Questions:
            </h1>
            <div
              /* className="border-top border-bottom" */ style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
            <div style={{ height: "24px" }}></div>
            <p
              className="lead"
              style={{ color: "black", fontFamily: "Abadi MT Std" }}
            >
              Question: Aaaaa bbbbb ccccc ddddd
            </p>
            <p
              className="lead"
              style={{ color: "black", fontFamily: "Abadi MT Std" }}
            >
              Answer: Aaaaa bbbbb ccccc ddddd
            </p>
            <div className="border-top border-bottom" />
            <div style={{ height: "24px" }}></div>
            <p
              className="lead"
              style={{ color: "black", fontFamily: "Abadi MT Std" }}
            >
              Question: Aaaaa bbbbb ccccc ddddd
            </p>
            <p
              className="lead"
              style={{ color: "black", fontFamily: "Abadi MT Std" }}
            >
              Answer: Aaaaa bbbbb ccccc ddddd
            </p>
            <div className="border-top border-bottom" />
            <div style={{ height: "24px" }}></div>
            <p
              className="lead"
              style={{ color: "black", fontFamily: "Abadi MT Std" }}
            >
              Question: Aaaaa bbbbb ccccc ddddd
            </p>
            <p
              className="lead"
              style={{ color: "black", fontFamily: "Abadi MT Std" }}
            >
              Answer: Aaaaa bbbbb ccccc ddddd
            </p>
            <div className="border-top border-bottom" />
            <div style={{ height: "24px" }}></div>
            <p
              className="lead"
              style={{ color: "black", fontFamily: "Abadi MT Std" }}
            >
              Question: Aaaaa bbbbb ccccc ddddd
            </p>
            <p
              className="lead"
              style={{ color: "black", fontFamily: "Abadi MT Std" }}
            >
              Answer: Aaaaa bbbbb ccccc ddddd
            </p>
            <div style={{ height: "24px" }}></div>
            <div
              /* className="border-top border-bottom" */ style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
          </div>
        </div>
        <div style={{ height: "36px" }}></div>

        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default FAQs;
