/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-control-regex */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

/* Components */
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./ActivateAccount.css";
/* Icons */
import { personCircle } from "ionicons/icons";
/* Actions */
import { loginSuccess, loginFailed } from "../actions/auth";
import { setMessage } from "../actions/message";
/* Services */
import AuthService from "../services/auth.service";
/* Others */
import { history } from "../helpers/history";

const ActivateAccount: React.FC = () => {
  //const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  /* const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const { activationcode } = useParams<{ activationcode: string }>();

  const [iserror, setIserror] = useState<boolean>(false);
  const [showloading, setShowloading] = useState<boolean>(false);
  const [messagelocal, setMessagelocal] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setMessagelocal(
        "A User is currently logged on. Please logout first then try again."
      );
      setIserror(true);
    } else {
      setShowloading(true);
    }
  }, []);

  let pagename = "Activate Account";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <IonHeader className="fixed-top">
        <IonToolbar
          color="primary"
          id="backgroundwhitebrand"
          style={{
            height: "0px",
          }}
        ></IonToolbar>
        <nav
          className="navbar navbar-expand-md"
          aria-label="Main navigation"
          style={{
            height: "66px",
            backgroundImage: "url(" + require("../assets/Background.jpg") + ")",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        ></nav>
        <IonToolbar
          id="backgroundwhitebrand"
          /* color="primary" */
          style={{
            backgroundColor: "white",
          }}
        >
          <IonButtons
            slot="start"
            style={{
              width: "100vw",
            }}
          >
            <IonButton
              onClick={() => {
                history.push("/");
              }}
              style={{
                color: "black",
              }}
            >
              Home
            </IonButton>
            <IonTitle
              style={{
                textAlign: "center",
              }}
            >
              <IonLabel
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "Gill Sans Nova",
                  color: "black",
                  fontSize: "20px",
                  width: "100%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  fontWeight: "normal",
                }}
              >
                &nbsp;senior
                <a
                  style={{
                    fontFamily: "Gill Sans Nova",
                    color: "black",
                    fontSize: "1.1em",
                    fontWeight: "bold",
                  }}
                >
                  HUB
                </a>
                solutions
              </IonLabel>
            </IonTitle>
            {/* {platformusedglobal !== "ios" && ( */}
            <a
              style={{
                color: "white", //#ffcc00
                width: "57px",
                marginLeft: "2px",
                marginRight: "2px",
                textAlign: "center",
                fontSize: "medium",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {/* )} */}
          </IonButtons>
        </IonToolbar>
        <div className="border-top border-bottom" />
      </IonHeader>

      <div style={{ height: "20vh" }}></div>
      <div
        className="container text-center"
        style={{ color: "black", maxWidth: "520px" }}
      >
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => {
            setIserror(false);
            history.push("/home");
          }}
          cssClass="ionbackgroundclass"
          header={"Error!"}
          message={messagelocal}
          buttons={["Dismiss"]}
        />
        <IonLoading
          cssClass="ionbackgroundclass"
          isOpen={showloading}
          onDidDismiss={() => {
            if (!currentUser) {
              AuthService.activateaccount(activationcode)
                .then((data: any) => {
                  dispatch(loginSuccess(data));
                  history.push("/profile");
                  window.location.reload();
                  //history.push("/profile/true");
                  //return Promise.resolve();
                })
                .catch((error: any) => {
                  const message =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                  dispatch(loginFailed());
                  dispatch(setMessage(message));
                  setMessagelocal(message);
                  setIserror(true);
                  //return Promise.reject();
                });
              setShowloading(false);
            }
          }}
          message={"Please wait..."}
          duration={4500}
        />

        <main className="form-signin">
          <form>
            <IonIcon
              className="mb-4"
              color="primary"
              style={{ fontSize: "70px" }}
              icon={personCircle}
            />
            <h1
              className="h3 mb-3 fw-normal"
              style={{ fontFamily: "Abadi MT Std" }}
            >
              Activating Account in progress...
            </h1>
          </form>
        </main>
      </div>

      <div style={{ height: "24px" }}></div>

      <div>
        <FooterMain />
        <Footer />
        {localStorage.getItem("isnative") &&
          localStorage.getItem("isnative") === "true" && <FooterMobile />}
      </div>
    </IonContent>
  );
};

export default ActivateAccount;
