import { SET_PLAYER } from "../actions/types";

const initialState = {};

export default function (
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;

  switch (type) {
    case SET_PLAYER:
      return { player: payload };

    default:
      return state;
  }
}
